<template>
  <transition
    name="modal"
    v-on:before-enter="beforeEnter"
    v-on:enter="enter"
    v-on:leave="leave"
  >
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <font-awesome-icon
            icon="times"
            id="remove-pop-up"
            @click="closePopUp"
          />
          <div class="modal-header">
            <h2 class="modal-header-title modal-header-info">
              {{ tier.name }}
            </h2>
            <h3 class="modal-header-text modal-header-info">
              {{ "Tier Type: " + tier.type }}
            </h3>
          </div>

          <div class="modal-body" id="modal-scroll-container">
            <div class="modal-body-info modal-body-content">
              <h3 class="modal-body-title">Tier Info</h3>
              <div class="modal-body-info-container">
                <div class="basic-info">
                  <p>Price: ${{ tier.price.toFixed(2) }}</p>
                  <!-- <p>CC-Fee: ${{ tier.ccfee.toFixed(2) }}</p> -->
                  <p>T-Fee: ${{ tier.dfee.toFixed(2) }}</p>
                  <p>addFee: ${{ tier.addfee.toFixed(2) }}</p>
                  <p>Tix Start #: {{ tier.tixStartNum }}</p>
                  <p :ticketQuantity="ticketQuantity">
                    Total Qty:
                    {{
                      tier.type !== "General Tier"
                        ? tier.totalTicketQuantity
                        : ticketQuantity
                    }}
                  </p>
                </div>
                <div class="basic-description">
                  <h3>Tier Description</h3>
                  <p>{{ tier.description }}</p>
                </div>
                <div class="venue-info">
                  <h3>Venue Access Dates</h3>
                  <table class="venue-access-table table">
                    <tr class="venue-access-labels table-labels">
                      <th v-if="isAdvancedTier">Quantity</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </tr>
                      <tr
                          v-if="isAdvancedTier && tier.type === 'Date'"
                        v-for="(date, index) in venueAccessDates"
                        :key="index"
                        class="venue-access-info table-info"
                      >
                        <td>{{ date.quantity }}</td>
                        <td class="venue-info-dates">
                          {{ formatDate(date.start) }}
                        </td>
                        <td class="venue-info-dates">
                          {{ formatDate(date.end) }}
                        </td>
                      </tr>

                    <tr
                      v-if="!isAdvancedTier"
                      class="venue-access-info table-info"
                    >
                      <td v-if="tier.venueAccessStart">
                        {{ formatDate(tier.venueAccessStart) }}
                      </td>
                      <td v-if="tier.venueAccessEnd">
                        {{ formatDate(tier.venueAccessEnd) }}
                      </td>
                    </tr>
                    <div v-if="isAdvancedTier && tier.type === 'Date and Time'">
                      <div
                        v-for="(date, index) in venueAccessDates"
                        :key="index"
                      >
                        <tr
                          v-for="item in date.times"
                          class="venue-access-info table-info"
                          :key="item.start"
                        >
                          <td>{{ item.quantity }}</td>
                          <td>
                            {{ formatDate(item.start) }}
                          </td>
                          <td>
                            {{ formatDate(item.end) }}
                          </td>
                        </tr>
                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </div>
            <div class="modal-body-sale-dates modal-body-content">
              <h3>Tier Sale Dates</h3>
              <table class="sale-date-table table">
                <tr class="sale-date-labels table-labels">
                  <th v-if="!isAdvancedTier">Qty Issued</th>
                  <th>Start Date</th>
                  <th>Start Time</th>
                  <th>End Date</th>
                  <th>End Time</th>
                </tr>
                <tr
                  class="sale-date-info table-info"
                  v-for="saleDate in info.saleDates"
                  :key="saleDate.startingDate"
                >
                  <td v-if="!isAdvancedTier">{{ saleDate.qty }}</td>
                  <td v-if="!saleDate.sale_start">
                    {{ tierDate(saleDate.startDate) }}
                  </td>
                  <td v-if="!saleDate.sale_start">{{ saleDate.startTime }}</td>
                  <td v-if="!saleDate.sale_start">
                    {{ tierDate(saleDate.endDate) }}
                  </td>
                  <td v-if="!saleDate.sale_start">{{ saleDate.endTime }}</td>
                  <td v-if="saleDate.sale_start">
                    {{ unixTierDate(saleDate.sale_start) }}
                  </td>
                  <td v-if="saleDate.sale_start">
                    {{ unixTierTime(saleDate.sale_start) }}
                  </td>
                  <td v-if="saleDate.sale_end">
                    {{ unixTierDate(saleDate.sale_end) }}
                  </td>
                  <td v-if="saleDate.sale_end">
                    {{ unixTierTime(saleDate.sale_end) }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="modal-header bottom">
            <div class="modal-btn-ctn">
              <diyobo-button
                class="edit"
                :type="'secondary'"
                :txt="'Edit'"
                @click="editTierPopup"
              />
              <diyobo-button
                class="close"
                :type="'primary'"
                :txt="'OK'"
                @click="closePopUp"
              />
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="less" scoped>
::-webkit-scrollbar {
  width: 3px;
  color: #000000;
}
::-webkit-scrollbar-track {
  display: none;
}
.modal-mask {
  //position: absolute;
  position: fixed;
  /* margin: auto; */
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: 0.4s;

  .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #3333339d;

    .modal-container {
      margin: auto;
      padding: 2px;
      position: relative;
      width: 100%;
      //skinny
      //max-width: 530px;
      //fat
      max-width: 700px !important;

      background-color: #4a639e;
      background-image: linear-gradient(160deg, #4eb95e, #4a639e);
      box-shadow: 1px 1px 8px 2px #111111c2;
      border-radius: 8px;
      transition: top 0.5s ease;

      #remove-pop-up {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }

      .modal-header,
      .modal-body {
        min-height: 50px;
      }

      .modal-header {
        //display: flex;
        //align-items: center;
        //justify-content: space-between;
        display: block;
        text-align: center;
        padding: 2px 20px;
        border-radius: 7px 7px 0 0;
        background-color: var(--content-background);

        .modal-header-title {
          width: 100%;
          margin: 0;
          margin-top: 10px;
          height: fit-content;
        }
        .modal-header-text {
          width: 100%;
          margin: 0;
          margin-bottom: 15px;
          height: fit-content;
        }
      }
      // end modal-header
      .bottom {
        border-radius: 0px 0px 7px 7px;
      }
      .modal-body {
        padding: 0em 20px;
        padding-top: 1em;
        padding-bottom: 1em;
        border-top: 1px solid var(--input-border);
        border-bottom: 1px solid var(--input-border);

        //border-radius: 0 0 7px 7px;
        background-color: var(--content-background);
        // box-shadow: inset 0 0 3px 0px #11111191;
        max-height: 560px;
        overflow: auto;

        .modal-body-title {
          margin-top: 0px;
        }

        .modal-body-content {
          margin: 7.5px 0px;
        }

        h3,
        h4,
        h2 {
          margin: 15px 0px;
          text-align: center;
        }

        .modal-body-container .error-label {
          margin-bottom: 0.75em;
          color: #e60022;
          text-align: center;
          font-weight: 600;
        }

        .basic-info {
          display: flex;
          justify-content: space-evenly;
          p {
            font-size: 1em;
          }
        }

        .basic-description {
          p {
            text-align: center;
          }
        }

        .venue-info {
          text-align: center;
          .venue-date-text {
            width: 45%;
            display: inline-block;
          }
          
          .venue-date-info {
            display: flex;
            justify-content: space-evenly;
          }
          .venue-info-dates {
            text-align: start;
          }
        }

        .table {
          width: 100%;
          text-align: center;
          margin: 18px 0px;
        }
        // end button-wrapper
      }
      // end modal-body
      .modal-btn-ctn {
        display: flex;
        justify-content: space-evenly;
        padding: 15px 0px;
        width: 100%;
      }
      p {
        font-size: 1em !important;
      }
    }
    // end modal-dialog
  }
  // end modal-inner
}
</style>

<script>
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import DiyoboButton from "@/components/DiyoboButton.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
import dayjs from "dayjs";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

library.add(faTimes);

export default {
  components: {
    DiyoboButton,
    FontAwesomeIcon
  },
  props: {
    info: Object,
    index: Number,
    timezone: String
  },
  data() {
    return {
      tier: this.info,
      ticketQuantity: 0
    };
  },
  computed: {
    venueAccessDates() {
      if (!this.tier.venueAccessDates) {
        return [];
      }

      let dates = this.tier.venueAccessDates; //.filter(v => v.enabled);

      // if (this.tier.type === "Date and Time") {
      //   dates = dates.flatMap(d => d.times.filter(t => t.enabled));
      // }

      return dates;
    },
    isAdvancedTier() {
      return this.tier.type !== "General Tier";
    }
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      let targetElement = document.querySelector("#modal-scroll-container");
      disableBodyScroll(targetElement);
    },
    enter(el) {
      el.style.opacity = 1;
    },
    leave(el) {
      el.style.opacity = 0;
    },
    editTierPopup() {
      let targetElement = document.querySelector("#modal-scroll-container");
      clearAllBodyScrollLocks();
      this.$emit("edit-tier", this.tier, this.index);
    },
    closePopUp() {
      let targetElement = document.querySelector("#modal-scroll-container");
      clearAllBodyScrollLocks();
      this.$emit("close");
    },
    tierDate(val) {
      return dateFormat(Date.parse(val), "ddd, mmm dS, yyyy");
    },
    formatDate(date) {
      return dayjs(date)
        .tz(this.timezone)
        .format("ddd, MMM DD, YYYY hh:mm A z");
    },
    unixTierDate(val) {
      if (val.$date) {
        return dayjs
          .tz(dayjs(+val.$date.$numberLong).utc(), this.timezone)
          .format("ddd, MMM D, YYYY");
      } else {
        return dayjs
          .tz(dayjs(val).utc(), this.timezone)
          .format("ddd, MMM D, YYYY");
      }
    },
    unixTierTime(val) {
      if (val.$date) {
        return dayjs(+val.$date.$numberLong)
          .tz(this.timezone)
          .format("hh:mm A");
        // return dayjs
        //   .tz(dayjs(+val.$date.$numberLong).utc(), this.timezone)
        //   .format("hh:mm A");
      } else {
        return dayjs(val)
          .tz(this.timezone)
          .format("hh:mm A");
        // return dayjs.tz(dayjs(val).utc(), this.timezone).format("hh:mm A");
      }
    }
  },
  beforeMount() {
    if (this.tier.saleDates) {
      this.tier.saleDates.forEach(ele => {
        this.ticketQuantity += ele.qty;
      });
    }
    let targetElement = document.querySelector("#modal-scroll-container");
    disableBodyScroll(targetElement);
  },
  beforeDestroy() {
    let targetElement = document.querySelector("#modal-scroll-container");
    enableBodyScroll(targetElement);
  }
};
</script>
