<template>
  <FormulateForm
    class="form-container"
    ref="form"
    v-model="formValues"
    @submit="handleSubmit"
    name="tierForm"
    autocomplete="false"
  >
    <alert-modal ref="alert" />
    <h3>Ticket Tier Setup</h3>
    <div class="row">
      <!-- <div class="col quarter">
        <FormulateInput
          type="select"
          name="type"
          label="Tier Type"
          placeholder="Select Type"
          validation="required"
          :options="tierTypes"
          :disabled="true || isMultiDate"
        />
      </div> -->

      <div class="col half">
        <FormulateInput
          type="text"
          name="name"
          label="Ticket Tier Name"
          placeholder="Ticket Tier Name"
          validation="required"
          :disabled="isDisabled"
          help="Recommended: 20 Characters"
          autocomplete="false"
        />
      </div>
      <div class="col half">
        <FormulateInput
          type="select"
          name="ticketType"
          label="Ticket Type"
          placeholder="Select Type"
          validation="required"
          :options="ticketTypes"
          autocomplete="false"
          @input="setFee()"
        />
      </div>

      <div class="row">
        <div class="col half fee">
          <FormulateInput
            type="money"
            name="price"
            label="Price"
            placeholder="Price"
            validation="required"
            :disabled="isDisabled"
            autocomplete="false"
          />
        </div>
        <div class="col half fee">
          <FormulateInput
            type="money"
            name="addfee"
            label="Add. Fee"
            placeholder="Add. Fee"
            autocomplete="false"
          />
        </div>
        <div class="col half fee">
          <FormulateInput
            type="number"
            name="dfee"
            label="T-Fee"
            placeholder="T-Fee"
            disabled
            autocomplete="false"
          />
        </div>
        <!-- <div class="col half fee">
          <label for="idk">T-Fee Structure</label>
          <p>
            {{ feesStructure }}
          </p>
        </div> -->
        <div class="col half">
          <FormulateInput
            type="select"
            label="T-Fee Structure"
            placeholder="- See Your T-Fee Structure (Informational Only) -"
            :options="feesStructure"
            :disabledTier="true"
          />
        </div>
        <div class="col half fee">
          <FormulateInput
            type="number"
            name="taxes"
            label="Planner Taxes"
            placeholder="Taxes"
            disabled
            autocomplete="false"
          />
        </div>
        <div class="col half fee">
          <FormulateInput
            type="number"
            name="tt_taxes"
            label="TT Taxes"
            placeholder="TT Taxes"
            disabled
            autocomplete="false"
          />
        </div>
        <div class="col half fee">
          <FormulateInput
            type="number"
            name="VMCfee"
            label="Avg Visa/MC Fee"
            placeholder="1.7%"
            disabled
            autocomplete="false"
          />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
            />
          </svg>
        </div>

        <div class="col half fee">
          <FormulateInput
            type="number"
            name="AMEXfee"
            label="Avg AMEX Fee"
            placeholder="2.6%"
            disabled
            autocomplete="false"
          />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
            />
          </svg>
        </div>

        <div class="col half fee">
          <FormulateInput
            type="number"
            name="take"
            label="Planner Take"
            placeholder="Planner Take"
            disabled
            autocomplete="false"
          />
        </div>
        <div class="col half fee">
          <FormulateInput
            type="number"
            name="AMEXtake"
            label="Planner Take"
            placeholder="Planner Take"
            disabled
            autocomplete="false"
          />
        </div>
        <div class="col half fee">
          <FormulateInput
            type="number"
            name="totalMVC"
            label="Buyer Total Visa/MC Avg"
            placeholder="Buyer Total"
            disabled
            autocomplete="false"
          />
        </div>
        <div class="col half fee">
          <FormulateInput
            type="number"
            name="totalAMEX"
            label="Buyer Total AMEX Avg"
            placeholder="Buyer Total"
            disabled
            autocomplete="false"
          />
        </div>
      </div>
      <div class="row">
        <div class="col quarterx3">
          <FormulateInput
            type="text"
            name="description"
            label="Description"
            placeholder="Description"
            help="Please leave a tier description that says something about your tier. Max 200 characters."
            validation="max:200,length|required"
            :maxlength="200"
            autocomplete="false"
          />
        </div>
        <div v-if="formValues.ticketType !== 'DONATION'" class="col quarter">
          <FormulateInput
            type="number"
            name="tixStartNum"
            label="Tix Start #"
            placeholder="Tix Start #"
            validation="required"
            autocomplete="false"
          />
        </div>
        <!-- <div v-if="isAdvancedTier" class="col quarter">
       
          <FormulateInput
            type="number"
            name="totalTicketQuantity"
            label="Total Event Ticket Quantity"
            placeholder="Total Quantity"
            :validation="`total:${totalQuantity}|required`"
            :validation-rules="{
              total: validateTotalQuantity
            }"
            :validation-messages="{
              total: 'Total Event Ticket Quantity must be met.'
            }"
            autocomplete="false"
          />
        </div> -->
      </div>
      <div class="row">
        <div class="col half">
          <FormulateInput
            type="image"
            name="image"
            label="Ticket Image"
            autocomplete="false"
            help="Upload only if using this tier as an add-on."
          />
        </div>

        <div class="col half">
          <FormulateInput
            type="text"
            name="product_id"
            label="Keap Product Id"
            placeholder="Product Id"
            autocomplete="false"
          />
        </div>

        <div class="col half">
          <FormulateInput
            type="text"
            name="cf_product_id"
            :disabled="isDisabled"
            label="Product ID"
            placeholder="Product ID"
            autocomplete="false"
          />
        </div>

        <div class="col half">
          <FormulateInput
            type="text"
            name="tag_id"
            label="Keap Tag Id"
            placeholder="Tag Id"
            autocomplete
          />
        </div>
        <div class="col half">
          <FormulateInput
            type="selectSearch"
            v-model="formValues.attendee_tag_id"
            label="Keap Attendee Tag Id"
            placeholder="Attendee Tag Id"
            autocomplete="false"
            @searchChange="getKeapTags"
            :options="keapTags"
          />
        </div>
        <div class="col half">
          <FormulateInput
            type="selectSearch"
            v-model="formValues.attendee_removed_tag_id"
            label="Keap Attendee Removed Tag Id"
            placeholder="Attendee Removed Tag Id"
            autocomplete="false"
            @searchChange="getKeapTags"
            :options="keapTags"
          />
        </div>
        <div class="col half">
          <FormulateInput
            type="text"
            name="keap_quantity_custom_field_id"
            label="Keap Quantity Custom Field"
            placeholder="Custom Field Id"
            autocomplete="false"
          />
        </div>
        <!-- <div class="col half" @keydown.space="onAddTag">
          <FormulateInput
            type="number"
            name="tag_id"
            label="Keap Tag Ids"
            help="Press space after each tag."
            placeholder="Tag Id"
            autocomplete="false"
          />
        </div>
        <div class="col half tags">
          <div
            v-for="(tag, index) in formValues.tags"
            class="tag"
            :key="`tag-${index}`"
            @click="onRemoveTag(index)"
          >
            <span class="name">{{ tag }}</span>
            <span class="remove">
              <FontAwesomeIcon icon="times" />
            </span>
          </div>
        </div> -->

        <div class="col half" v-if="formValues.ticketType == 'VIRTUAL'">
          <FormulateInput
            type="text"
            name="tag"
            label="Obvio Tag"
            placeholder="Obvio Tag"
            autocomplete="false"
          />
        </div>
      </div>
    </div>

    <div v-if="formValues.ticketType !== 'DONATION'">
      <div class="divider"></div>
      <h3 class="venue-access-h3">{{ displayAccess }}</h3>
      <p
        class="venue-access"
        v-if="formValues.type !== 'General Tier'"
        style="color: var(--text)"
      >
        Please select each date that your event will take place and a quantity
        for the number of tickets for that date. Reference the Total Quantity
        below for more perspective.
      </p>
      <p class="venue-access" v-else style="color: var(--text)">
        Please select the dates and times as they pertain to when you start
        letting people into the event and when you will stop allowing entry.
      </p>
      <div v-if="formValues.type !== 'General Tier'" class="row">
        <div class="venue-date-selectors">
          <div
            v-for="(date, index) in venueAccessDates"
            class="date"
            :key="`date-${index}`"
          >
            <DiyoboButton
              :type="date.enabled ? 'primary' : 'disabled'"
              :txt="formatVenueAccessDate(date)"
              @click="date.enabled = !date.enabled"
            />
            <FormulateInput
              v-if="!hasTimeSelection"
              type="number"
              label="Quantity"
              ignored
              v-model="date.quantity"
              @input="calculateTotalQuantity"
            />
          </div>
        </div>
        <div v-if="hasTimeSelection" class="venue-time-selectors">
          <p
            v-if="venueAccessDates.filter(v => v.enabled).length > 0"
            style="color: var(--text)"
          >
            Now select your time slot intervals and the amount of tickets you
            want for sale in each time slot.
          </p>
          <div
            v-for="(date, index) in venueAccessDates.filter(v => v.enabled)"
            class="time-selectors"
            :key="`time-selector-${index}`"
          >
            <div class="interval">
              <div class="date">
                {{ formatVenueAccessDate(date) }}
              </div>
              <FormulateInput
                type="number"
                placeholder="Interval Amount"
                label="Interval Amount"
                validation="required"
                ignored
                min="1"
                max="60"
                @input="onIntervalChange(date)"
                v-model="date.intervalAmount"
                autocomplete="false"
              />
              <FormulateInput
                type="select"
                placeholder="Select Interval"
                label="Select Interval"
                validation="required"
                ignored
                :options="intervals"
                @input="onIntervalChange(date)"
                v-model="date.interval"
                autocomplete="false"
              />
            </div>

            <div class="times">
              <div
                v-for="(time, index) in date.times"
                class="time"
                :key="`time-${index}`"
              >
                <DiyoboButton
                  :txt="formatVenueAccessTime(time)"
                  type="primary"
                  :class="[!time.enabled && 'disabled']"
                  @click="onToggleTime(time)"
                />
                <FormulateInput
                  type="number"
                  label="Quantity"
                  ignored
                  @input="onTimeQuantityInput(time, $event)"
                  :value="time.quantity"
                  autocomplete="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="row">
        <div class="col full">
          <FormulateInput
            type="checkbox"
            label="Same as Event Dates and Times"
            v-model="useEventDates"
          />
        </div>
        <div class="col half">
          <FormulateInput
            type="dateTime"
            name="venueAccessStart"
            label="Start Date"
            placeholder="MM/DD/YYYY ##:## PM"
            validation="required"
            :timezone="info.config.timezone"
            :min-date="new Date()"
            :max-date="maxEventDate"
            autocomplete="false"
          />
        </div>
        <div class="col half">
          <FormulateInput
            type="dateTime"
            name="venueAccessEnd"
            label="End Date"
            placeholder="MM/DD/YYYY ##:## PM"
            validation="required"
            :timezone="info.config.timezone"
            :min-date="formValues.venueAccessStart"
            :max-date="maxEventDate"
            autocomplete="false"
          />
        </div>
      </div>

      <div v-if="isAdvancedTier" class="row">
        <div class="ticket-counter">
          <div class="label">Total Event Access Quantity</div>
          <div class="counter">
            <span>
              {{ totalQuantity }}
            </span>
          </div>
          <!-- <div v-if="totalQuantity > formValues.totalTicketQuantity">
            Please update your Total Event Ticket Quantity
            <a
              href="#"
              @click.prevent="formValues.totalTicketQuantity = totalQuantity"
            >
              (update)
            </a>
          </div> -->
        </div>
      </div>
    </div>

    <div class="divider"></div>
    <h3>Purchase Availability</h3>
    <FormulateInput
      v-if="!hideRepeatableGroups"
      class="some-random-class"
      type="group"
      name="saleDates"
      :repeatable="true"
      add-label="Add Dates"
      minimum="1"
      #default="{ index }"
      autocomplete="false"
    >
      <div class="row">
        <div v-if="!isAdvancedTier" class="col third">
          <FormulateInput
            type="number"
            name="qty"
            label="Quantity"
            placeholder="Quantity"
            validation="required"
            autocomplete="false"
          />
        </div>
        <div class="col" :class="isAdvancedTier ? 'half' : 'third'">
          <FormulateInput
            type="dateTime"
            name="startDate"
            label="Start Date"
            placeholder="MM/DD/YYYY ##:## PM"
            validation="required"
            :timezone="info.config.timezone"
            :min-date="new Date()"
            :max-date="maxEventDate"
            autocomplete="false"
          />
        </div>
        <div class="col" :class="isAdvancedTier ? 'half' : 'third'">
          <FormulateInput
            type="dateTime"
            name="endDate"
            label="End Date"
            placeholder="MM/DD/YYYY ##:## PM"
            validation="required"
            :timezone="info.config.timezone"
            :min-date="minEndDate(index)"
            :max-date="maxEventDate"
            autocomplete="false"
          />
        </div>

        <div class="row" v-if="showPurchaseLimitFields(index)">
          <div class="col half">
            <FormulateInput
              type="number"
              name="min_purchase"
              label="Minimum tickets to Purchase"
              :validation="validationMinTicketPurchase(index)"
              autocomplete="false"
            />
          </div>
          <div class="col half">
            <FormulateInput
              type="number"
              name="max_purchase"
              label="Maximum Tickets to Purchase"
              :validation="validationMaxTicketPurchase(index)"
              autocomplete="false"
            />
          </div>
        </div>
        <div class="purchase-availability-more-option-container">
          <FormulateInput
            type="checkbox"
            name="soldout"
            label="Mark Sold Out"
          />
          <FormulateInput
            type="checkbox"
            name="purchase_limit"
            label="Add Purchase Limit"
          />
          <FormulateInput
            type="checkbox"
            name="show_check_out_add_on"
            label="Make available as an add-on/upsell at checkout."
          />
          <FormulateInput
            type="checkbox"
            name="do_not_show_in_event"
            label="Do not display on event page."
          />
        </div>
      </div>
    </FormulateInput>

    <div>
      <FormulateInput
        type="checkbox"
        label="Would you like to add a password to this tier?"
        v-model="hasPassword"
      />
    </div>
    <div v-show="hasPassword" class="col half">
      <FormulateInput
        type="text"
        name="password"
        label="Password"
        placeholder="Password"
        autocomplete="false"
      />
    </div>

    <div>
      <FormulateInput
        type="checkbox"
        label="Would you like to restrict tier with certain emails?"
        name="restrictEmails"
      />
    </div>
    <div v-show="formValues.restrictEmails" class="col half">
      <CSVImporter
        :disabled="formValues.userEmails && formValues.userEmails.length > 0"
        name="tierEmails"
        v-model="formValues.restrictEmailsArray"
        :columns="tierEmailsColumn"
      />
    </div>

    <div class="divider"></div>

    <div class="save-button">
      <FormulateInput
        type="submit"
        :name="editingItem !== null ? 'Save Changes' : 'Save Ticket Tier'"
        icon="plus"
      />
    </div>
    <!-- <div>
      <h3>Additional Options</h3>
      <div class="col full">
        <FormulateInput
          type="checkbox"
          name="absorb"
          label="Planner Pays Fees"
        />
        <FormulateInput
          type="checkbox"
          label="Collect Attendee Info"
          @input="onInput"
          v-model="collectAttendeeInfo"
        />
      </div>
    </div>
    <div class="divider"></div> -->
  </FormulateForm>
</template>

<script>
import AlertModal from "@/components/modals/AlertModal.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import debounce from "debounce";

import dayjs from "dayjs";
import CSVImporter from "../comps/CSVImporter.vue";

export default {
  name: "TicketTierForm",
  components: {
    CSVImporter,
    AlertModal,
    DiyoboButton
  },
  props: {
    info: Object,
    editingItem: Object,
    isMultiDate: Boolean,
    showSeatsIoField: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      useEventDates: false,
      hasPassword: false,
      hideRepeatableGroups: false,
      isDisabled: false,
      tfees: [],
      VMCfee: 0.017,
      AMEXfee: 0.026,
      tierTypes: ["General Tier", "Date", "Date and Time"],
      ticketTypes: [],
      intervals: [
        { label: "Minutes", value: "MINUTE" },
        { label: "Hours", value: "HOUR" }
      ],
      venueAccessDates: this.generateVenueAccessDates(),
      totalQuantity: 0,
      feeObj: [],
      feesStructure: [],
      formValues: {
        name: "",
        price: "",
        ccfee: "",
        VMCfee: "",
        AMEXfee: "",
        dfee: "",
        addfee: "",
        absorb: false,
        qty: "",
        taxes: "0.00",
        tt_taxes: "0.00",
        totalMVC: "0.00",
        totalAMEX: "0.00",
        take: "0.00",
        AMEXtake: "0.00",
        type: this.getDefaultType(),
        ticketType: "",
        description: "",
        tixStartNum: 1,
        totalTicketQuantity: "",
        image: null,
        saleDates: [
          {
            startDate: null,
            purchase_limit: false,
            show_check_out_add_on: false,
            do_not_show_in_event: false
          }
        ],
        categoryKey: "",
        venueAccessStart: "",
        venueAccessEnd: "",
        password: "",
        userEmails: [],
        tags: [],
        attendee_tag_id: ""
      },
      keapTags: [],
      tierEmails: [],
      tierEmailsColumn: [
        {
          name: "email",
          property: "email",
          required: true,
          example: "example@example.com",
          validate: "email"
        }
      ]
    };
  },
  // mounted() {
  //   this.collectAttendeeInfo = this.info.tiers.collectAttendeeInfo;
  // },
  computed: {
    displayAccess() {
      return this.formValues.ticketType === "VIRTUAL"
        ? "Stream Access"
        : "Venue Access";
    },
    cost() {
      let totalMVC = this.totalMVC;
      let totalAMEX = this.totalAMEX;
      return Number(this.formValues.price) + Number(this.formValues.addfee);
    },
    VMCfees() {
      return Number(this.formValues.VMCfee) + Number(this.formValues.dfee);
    },
    AMEXfees() {
      return Number(this.formValues.AMEXfee) + Number(this.formValues.dfee);
    },
    taxes() {
      let taxable =
        Number(this.formValues.price) + Number(this.formValues.addfee) || 0;

      if (!this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
        taxable += Number(this.formValues.dfee);
      }
      if (
        this.formValues.ticketType === "IN_PERSON" ||
        this.formValues.ticketType === "IN_PERSON_RESERVED"
      ) {
        return (
          taxable *
          this.info.config.taxes
            .filter(t => t.applicableTo.includes("LIVE_TICKETS"))
            .reduce((a, b) => a + Number(b.percentage) / 100, 0)
        ).toFixed(2);
      } else if (this.formValues.ticketType === "VIRTUAL") {
        return (
          taxable *
          this.info.config.taxes
            .filter(t => t.applicableTo.includes("VIRTUAL_TICKETS"))
            .reduce((a, b) => a + Number(b.percentage) / 100, 0)
        ).toFixed(2);
      } else {
        return (taxable = 0).toFixed(2);
      }
    },

    VMCFee() {
      if (this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
        return Number(this.cost) * this.VMCfee;
      }
      return (
        (Number(this.cost) +
          Number(this.formValues.dfee) +
          Number(this.taxes)) *
        this.VMCfee
      );
    },
    AMEXFee() {
      if (this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
        return Number(this.cost) * this.AMEXfee;
      }
      return (
        (Number(this.cost) +
          Number(this.formValues.dfee) +
          Number(this.taxes)) *
        this.AMEXfee
      );
    },
    take() {
      let tt_taxes = this.tt_taxes;

      if (this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
        return (
          Number(this.cost) -
          Number(this.VMCfees) -
          Number(tt_taxes)
        ).toFixed(2);
      }

      return this.cost.toFixed(2);
    },
    AMEXtake() {
      let tt_taxes = this.tt_taxes;

      if (this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
        return (
          Number(this.cost) -
          Number(this.AMEXfees) -
          Number(tt_taxes)
        ).toFixed(2);
      }

      return this.cost.toFixed(2);
    },
    dfee() {
      if (this.formValues.ticketType !== "DONATION") {
        return;
      } else {
        return (0).toFixed(2);
      }
    },
    tt_taxes() {
      let taxable =
        Number(this.formValues.dfee) + Number(this.formValues.VMCfee);

      if (!this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
        taxable += Number(this.formValues.dfee);
      }
      if (this.formValues.ticketType === "IN_PERSON") {
        return (
          taxable *
          this.info.config.taxes
            .filter(t => t.applicableTo.includes("LIVE_TICKETS"))
            .reduce((a, b) => a + Number(b.percentage) / 100, 0)
        ).toFixed(2);
      } else if (this.formValues.ticketType === "VIRTUAL") {
        return (
          taxable *
          this.info.config.taxes
            .filter(t => t.applicableTo.includes("VIRTUAL_TICKETS"))
            .reduce((a, b) => a + Number(b.percentage) / 100, 0)
        ).toFixed(2);
      } else {
        return (taxable = 0).toFixed(2);
      }
      // return (

      //   Number(this.formValues.dfee) *
      //   this.info.config.taxes
      //     .filter((t) => t.applicableTo.includes("TICKET_TIERS"))
      //     .reduce((a, b) => a + Number(b.percentage) / 100, 0)
      // ).toFixed(2);
    },
    totalMVC() {
      const fees =
        this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees"
          ? 0
          : this.VMCfees;
      if (this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
        return (
          Number(this.formValues.price) + Number(this.formValues.addfee)
        ).toFixed(2);
      }

      return (
        Number(this.formValues.price) +
        Number(fees) +
        Number(this.formValues.addfee)
      ).toFixed(2);
    },
    totalAMEX() {
      const fees =
        this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees"
          ? 0
          : this.AMEXfees;

      if (this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
        return (
          Number(this.formValues.price) + Number(this.formValues.addfee)
        ).toFixed(2);
      }

      return (
        Number(this.formValues.price) +
        Number(fees) +
        Number(this.formValues.addfee)
      ).toFixed(2);
    },
    hasTimeSelection() {
      return this.formValues.type === "Date and Time";
    },
    isAdvancedTier() {
      return this.formValues.type !== "General Tier";
    },
    maxEventDate() {
      if (this.info) {
        return this.info.dates.dates.reduce(
          (a, b) => (dayjs(a).isBefore(b.endDate) ? b.endDate : a),
          this.info.dates.dates[0].endDate
        );
      }
    },
    user() {
      return this.$store.state.user;
    }
  },
  watch: {
    // we can't just use the computed property since all the inputs are managed by the form itself,
    // so we have to update the form data "manually"
    total(value) {
      this.formValues.total = value;
    },
    take(value) {
      this.formValues.take = value;
    },
    AMEXtake(value) {
      this.formValues.AMEXtake = value;
    },
    dfee(value) {
      this.formValues.dfee = value;
    },
    taxes(value) {
      this.formValues.taxes = value;
    },
    tt_taxes(value) {
      this.formValues.tt_taxes = value;
    },
    totalMVC(value) {
      this.formValues.totalMVC = value;
    },
    totalAMEX(value) {
      this.formValues.totalAMEX = value;
    },
    // I made this one separate since changing the fees would change the total
    cost() {
      this.calculateTierFees(this.formValues.price, this.formValues.addfee);
    },
    useEventDates(value) {
      if (value) {
        this.formValues.venueAccessStart = this.info.dates.dates[0].startDate;
        this.formValues.venueAccessEnd = this.info.dates.dates[0].endDate;
      }
    },

    editingItem(value, oldValue) {
      if (value !== oldValue) {
        if (value !== null) {
          this.formValues = value;

          if (value.venueAccessDates) {
            // doesnt update unless next render, whatever
            this.$nextTick(() => {
              this.venueAccessDates = value.venueAccessDates;
              this.calculateTotalQuantity();
            });
          }

          if (this.hasSoldTickets(value)) {
            this.$refs.alert.open({
              title: "Confirm",
              msg:
                "<div> You have sold tickets to this tier.</div>" +
                "<div> You can't change the name and price as a result.</div>",
              closable: false,
              confirm: "Continue"
            });
            this.isDisabled = true;
          }

          if (value.saleDates.length > 0) {
            for (let i = 0; i < value.saleDates.length; i++) {
              if (value.saleDates[i].purchase_limit) {
                this.formValues.saleDates[i].purchase_limit =
                  value.saleDates[i].purchase_limit;
                this.formValues.saleDates[i].min_purchase =
                  value.saleDates[i].min_purchase;
                this.formValues.saleDates[i].max_purchase =
                  value.saleDates[i].max_purchase;
              }
              this.formValues.saleDates[i].show_check_out_add_on =
                value.saleDates[i].show_check_out_add_on;

              this.formValues.saleDates[i].do_not_show_in_event =
                value.saleDates[i].do_not_show_in_event;
            }
          }
          this.venueAccessDates = this.generateVenueAccessDates();

          this.formValues.price = value.price.toFixed(2);
          this.formValues.addfee = value.addfee.toFixed(2);

          this.calculateTierFees(value.price, value.addfee);
          this.formValues.total = this.total;
          this.formValues.take = this.take;
          // odd behavior: it gets set to "" if it isn't done in the next render
          this.$nextTick(() => {
            this.hasPassword = !!value.password ? true : false;
            this.formValues.password = !!value.password
              ? value.password
              : false;
          });
          this.formValues.restrictEmails =
            value.userEmails && value.userEmails.length > 0 ? true : false;
          this.formValues.restrictEmailsArray = value.userEmails
            ? value.userEmails
            : [];
        } else {
          this.clearForm();
          window.scroll({
            top: 1800,
            behavior: "smooth"
          });
        }
      }
    },
    // "formValues.config.buyerOrPlannerPaysFees"(value, oldValue) {
    //   if (value === "plannerPaysFees") {
    //     this.absorb = true;
    //   }
    // },
    "formValues.type"() {
      if (!this.editingItem && this.isAdvancedTier) {
        this.venueAccessDates = this.generateVenueAccessDates();
      }
    }
  },
  methods: {
    onAddTag() {
      const tag = parseInt(this.formValues.tag_id.trim());
      if (!this.formValues.tags.includes(tag)) {
        this.formValues.tags.push(tag);
      }

      this.formValues.tag_id = "";
    },
    onRemoveTag(index) {
      this.formValues.tags.splice(index, 1);
    },
    getKeapTags: debounce(function(val) {
      // if (
      //   this.$store.state.activeBrand &&
      //   this.$store.state.activeBrand.subdomain !== "nmp"
      // ) {
      //   return;
      // }

      if (!val) {
        // this.keapTags = [];
        return;
      }
      this.$axios
        .post("/planners/get-keap-tags", {
          searchString: val
        })
        .then(response => {
          let tags = [];
          response.data.forEach(tag => {
            tags.push({ value: tag._id, label: tag.name });
          });
          this.keapTags = tags;
        })
        .catch(error => {});
    }, 300),

    validationMinTicketPurchase(index) {
      let minTicketPurchase = 1;
      let maxTicketPurchase = this.formValues.saleDates[index].qty
        ? parseInt(this.formValues.saleDates[index].qty)
        : 100000000;
      return [
        ["required"],
        ["min", minTicketPurchase],
        ["max", maxTicketPurchase]
      ];
    },
    validationMaxTicketPurchase(index) {
      let minTicketPurchase = this.formValues.saleDates[index].min_purchase
        ? parseInt(this.formValues.saleDates[index].min_purchase)
        : 1;
      let maxTicketPurchase = this.formValues.saleDates[index].qty
        ? parseInt(this.formValues.saleDates[index].qty)
        : 100000000;
      return [
        ["required"],
        ["min", minTicketPurchase],
        ["max", maxTicketPurchase]
      ];
    },
    updateTicketTypes() {
      if (this.info.config.eventType === "IN_PERSON") {
        this.ticketTypes = [{ label: "Live/In Person", value: "IN_PERSON" }];
      }

      if (this.info.config.eventType === "VIRTUAL") {
        this.ticketTypes = [{ label: "Virtual Attendance", value: "VIRTUAL" }];
      }

      if (this.info.config.eventType === "HYBRID") {
        this.ticketTypes = [
          { label: "Live/In Person", value: "IN_PERSON" },
          { label: "Virtual Attendance", value: "VIRTUAL" }
        ];
      }
      if (this.showSeatsIoField) {
        this.ticketTypes = [
          { label: "Live/In Person", value: "IN_PERSON" },
          { label: "Live/In Person Reserved", value: "IN_PERSON_RESERVED" }
        ];
      } else {
        this.ticketTypes = [
          ...this.ticketTypes,
          { label: "Donation", value: "DONATION" }
        ];
      }

      // comment for now
      // this.formValues.ticketType =
      //   this.info.config.eventType === "HYBRID"
      //     ? ""
      //     : this.info.config.eventType;
    },

    showPurchaseLimitFields(index) {
      if (
        this.formValues.saleDates === undefined ||
        this.formValues.saleDates[index] === undefined
      ) {
        return false;
      } else {
        return this.formValues.saleDates[index].purchase_limit;
      }
    },
    // onInput() {
    //   this.$emit("collectingAttendeeInfo");
    // },
    // Calc end date based on index of group item
    minEndDate(index) {
      if (index) {
        if (this.formValues.saleDates.length >= 0) {
          let formStartDate =
            this.formValues.saleDates[index].startDate || null;
          // return new Date();

          let startDate = formStartDate ? formStartDate : new Date();
          return startDate;
        } else {
          return new Date();
        }
      } else {
        if (this.formValues.saleDates) {
          let formStartDate = this.formValues.saleDates[0].startDate || null;

          let startDate = formStartDate ? formStartDate : new Date();
          return startDate;
        } else {
          return new Date();
        }
      }
    },
    isTicketSold(index) {
      if (
        this.formValues.saleDates !== undefined &&
        this.formValues.saleDates.length > 0 &&
        this.formValues.saleDates[index] !== undefined
      ) {
        return this.formValues.saleDates[index].start > 1;
      } else {
        return false;
      }
    },
    hasSoldTickets(tier) {
      if (tier.type === "General Tier") {
        return tier.saleDates.some(s => s.start > 1);
      } else if (tier.type === "Date") {
        return tier.venueAccessDates.some(d => d.sold > 0);
      } else if (tier.type === "Date and Time") {
        return tier.venueAccessDates.some(d => d.times.some(t => t.sold > 0));
      }

      return true;
    },
    calculateTierFees(price, aFee) {
      const cost = Number(price) + Number(aFee);
      let tfee = 0;
      if (this.formValues.ticketType !== "DONATION") {
        for (let i = 0; i < this.tfees.length; i++) {
          let ele = this.tfees[i];
          if (cost >= ele.min && cost <= ele.max) {
            this.formValues.dfee = ele.value.toFixed(2);
            tfee = ele.value;
            i = this.tfees.length;
          } else if (cost === 0) {
            return (this.formValues.dfee = 0).toFixed(2);
          }
        }
      }
      let VMCFee;
      let AMEXFee;

      if (this.info.config.buyerOrPlannerPaysFees === "plannerPaysFees") {
        VMCFee = cost * this.VMCfee;
        AMEXFee = cost * this.AMEXfee;
      } else {
        VMCFee = (cost + tfee + Number(this.taxes)) * this.VMCfee;
        AMEXFee = (cost + tfee + Number(this.taxes)) * this.AMEXfee;
      }
      if (this.formValues.ticketType === "DONATION") {
        return (this.formValues.dfee = 0).toFixed(2);
      }
      this.formValues.VMCfee = VMCFee.toFixed(2);
      this.formValues.AMEXfee = AMEXFee.toFixed(2);
    },
    calculateTotalQuantity() {
      if (this.formValues.type === "Date and Time") {
        this.totalQuantity = this.venueAccessDates
          .filter(d => d.times)
          .reduce(
            (a, b) => a + b.times.reduce((c, d) => c + Number(d.quantity), 0),
            0
          );
      } else {
        this.totalQuantity = this.venueAccessDates.reduce(
          (a, b) => a + Number(b.quantity),
          0
        );
      }
    },
    clearForm() {
      this.formValues = {
        name: "",
        price: "",
        ccfee: "",
        dfee: "",
        addfee: "",
        total: "0.00",
        type: this.getDefaultType(),
        ticketType: "",
        description: "",
        image: null,
        tixStartNum: 1,
        totalTicketQuantity: "",
        saleDates: [
          {
            startDate: null,
            purchase_limit: false,
            show_check_out_add_on: false,
            do_not_show_in_event: false
          }
        ],
        venueAccessStart: "",
        venueAccessEnd: "",
        password: ""
      };

      this.venueAccessDates = this.generateVenueAccessDates();
      this.calculateTotalQuantity();

      this.hasPassword = false;

      this.$refs.form.hideErrors();
      this.isDisabled = false;

      // workaround: this makes it so the repeatable group doesn't show errors after submission
      this.hideRepeatableGroups = true;

      this.$nextTick(() => {
        this.hideRepeatableGroups = false;
      });
    },
    formatVenueAccessDate(date) {
      return dayjs(date.start).format("ddd, MMM DD, YYYY");
    },
    formatVenueAccessTime(date) {
      return dayjs(date.start).format("h:mm A");
    },
    generateVenueAccessDates() {
      if (this.isMultiDate) {
        return this.info.dates.dates.map(d => ({
          start: d.startDate,
          end: d.endDate,
          enabled: false,
          quantity: ""
        }));
      } else if (this.isAdvancedTier) {
        // if we're editing then we should use the current dates
        if (this.editingItem) {
          return this.editingItem.venueAccessDates;
        }

        let eventStartDate = dayjs(this.info.dates.dates[0].startDate);
        let eventEndDate = dayjs(this.info.dates.dates[0].endDate);

        const days = eventEndDate.diff(eventStartDate, "days");
        const dates = [];

        let startDate = eventStartDate;
        let endDate = eventEndDate;

        for (let i = 0; i < days; i++) {
          startDate = startDate.add(1, "day");
          endDate = startDate.add(1, "days");

          dates.push({
            start: startDate.toISOString(),
            end: endDate.toISOString(),
            enabled: false,
            quantity: 0,
            interval: "",
            intervalAmount: "",
            times: []
          });
        }

        return dates;
      }

      return [];
    },
    getDefaultType() {
      return this.isMultiDate ? "Date" : "General Tier";
    },
    onIntervalChange(date) {
      if (this.editingItem && date.times && date.times.length > 0) {
        return;
      }

      if (date.interval && date.intervalAmount) {
        const dates = [];
        // date.times =
        let startDate = dayjs(date.start).startOf("hour");
        let endDate = dayjs(date.end);

        if (endDate.diff(startDate, "hours") > 24) {
          endDate = startDate.add(1, "day");
        }

        while (
          (startDate = startDate.add(
            parseInt(date.intervalAmount),
            date.interval.toLowerCase()
          )).isBefore(endDate)
        ) {
          dates.push({
            start: startDate.toISOString(),
            end: startDate
              .add(date.intervalAmount, date.interval.toLowerCase())
              .toISOString(),
            enabled: false,
            quantity: ""
          });
        }

        date.times = dates;
        this.$set(date, "times", dates);
      }
    },
    onToggleTime(time) {
      this.$set(time, "enabled", !time.enabled);
      this.$forceUpdate();
    },
    onTimeQuantityInput(time, quantity) {
      this.$set(time, "quantity", quantity);
      if (quantity > 0) {
        this.$set(time, "enabled", true);
      }

      this.calculateTotalQuantity();
      // this.$forceUpdate();
    },
    validateTotalQuantity({ value }) {
      return this.totalQuantity === Number(value);
    },
    async handleSubmit() {
      // this.formValues.collectAttendeeInfo = Boolean(
      //   this.formValues.collectAttendeeInfo
      // );
      this.formValues.price = Number(this.formValues.price);
      this.formValues.VMCfee = Number(this.formValues.VMCfee);
      this.formValues.AMEXfee = Number(this.formValues.AMEXfee);
      this.formValues.dfee = Number(this.formValues.dfee);
      this.formValues.addfee = Number(this.formValues.addfee);
      if (this.formValues.tag_id) {
        this.formValues.tag_id = Number(this.formValues.tag_id);
      }
      if (this.formValues.attendee_tag_id) {
        this.formValues.attendee_tag_id = Number(
          this.formValues.attendee_tag_id
        );
      }
      if (this.formValues.attendee_removed_tag_id) {
        this.formValues.attendee_removed_tag_id = Number(
          this.formValues.attendee_removed_tag_id
        );
      }
      if (this.formValues.keap_quantity_custom_field_id) {
        this.formValues.keap_quantity_custom_field_id = Number(
          this.formValues.keap_quantity_custom_field_id
        );
      }
      if (this.formValues.product_id) {
        this.formValues.product_id = Number(this.formValues.product_id);
      }
      if (this.formValues.cf_product_id) {
        this.formValues.cf_product_id = Number(this.formValues.cf_product_id);
      }

      // this.formValues.totalTicketQuantity = Number(
      //   this.formValues.totalTicketQuantity
      // );

      if (this.formValues.ticketType !== "DONATION") {
        for (const venueAccessDate of this.venueAccessDates) {
          venueAccessDate.quantity = venueAccessDate.quantity
            ? Number(venueAccessDate.quantity)
            : null;

          if (venueAccessDate.times) {
            for (const time of venueAccessDate.times) {
              time.quantity = Number(time.quantity);
            }
          }
        }
      }

      for (const saleDate of this.formValues.saleDates) {
        saleDate.qty = saleDate.qty ? Number(saleDate.qty) : null;
        saleDate.sale_start = saleDate.startDate;
        saleDate.sale_end = saleDate.endDate;
      }

      this.formValues.password = this.hasPassword
        ? this.formValues.password
        : "";

      this.$emit("save-tier", {
        ...this.formValues,
        venueAccessDates: this.venueAccessDates
      });

      this.clearForm();

      if (this.editingItem) {
        this.$toast("Ticket Tier updated!", { type: "success" });
      } else {
        this.$toast("Ticket Tier added!", { type: "success" });
      }
    },
    setFee() {
      let feesStructure = [];
      let type = "InPerson";
      switch (this.formValues.ticketType) {
        case "VIRTUAL":
          type = "Virtual";
          break;
        case "DONATION":
          type = "Donations";
          break;
        default:
          type = "InPerson";
          break;
      }
      this.feeObj.ticketData.forEach(e => {
        feesStructure.push(
          `$${e.startAmount.$numberDecimal} - $${e.endAmount.$numberDecimal} : $${e[type].$numberDecimal} `
        );
      });
      this.feesStructure = feesStructure;

      // START NEW FEES
      let VMCFeeToUse = this.feeObj.feeData.VMCFee
        ? parseFloat(this.feeObj.feeData.VMCFee.$numberDecimal) / 100
        : 0.017;

      let AMEXFeeToUse = this.feeObj.feeData.AMEXFee
        ? parseFloat(this.feeObj.feeData.AMEXFee.$numberDecimal) / 100
        : 0.026;

      // END NEW FEES
      let feeArray = [];
      this.feeObj.ticketData.forEach(ticketFee => {
        let objToInsert = {
          min: parseFloat(ticketFee.startAmount),
          max: parseFloat(ticketFee.endAmount),
          value: parseFloat(ticketFee[type] ? ticketFee[type] : ticketFee.price)
        };
        feeArray.push(objToInsert);
      });
      this.tfees = feeArray;
      this.VMCfee = VMCFeeToUse;
      this.AMEXfee = AMEXFeeToUse;
      if (this.formValues.ticketType == "IN_PERSON_RESERVED") {
        if (!this.formValues.categoryKey) {
          this.formValues.categoryKey = Date.now().toString();
        }
      }
    }
  },
  created() {
    this.$axios
      .post(`/planners/retrieve-tier-fees`, {
        event: this.$route.params.url
      })
      .then(response => {
        if (response.status === 200) {
          this.feeObj = response.data.fee_Obj;
          this.setFee();
        }
      });
  },
  mounted() {
    this.updateTicketTypes();
  }
};
</script>

<style lang="less" scoped>
.form-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .venue-access-h3 {
    margin: 0;
  }
  .venue-access {
    margin: 16px 0;
  }
  .row {
    .col {
      svg {
        font-size: 12px;
        width: 20px;
        fill: var(--primary-green);
        margin-bottom: 8px;
      }
      &.name {
        width: calc(40% - 0.5em);
      }

      &.fee {
        width: calc(20% - 16px);
      }

      @media screen and (max-width: 1049px) {
        &.fee {
          width: calc(33.33% - 16px);

          &:nth-child(4),
          &:nth-child(5) {
            width: calc(50% - 16px);
          }
        }
      }

      @media screen and (max-width: 859px) {
        &.name,
        &.fee {
          width: calc(50% - 0.5em);
        }
      }

      @media screen and (max-width: 750px) {
        &.quarter {
          width: 100% !important;
        }
      }

      @media screen and (max-width: 750px) {
        &.quarter {
          width: calc(50% - 16px) !important;
        }
      }

      @media screen and (max-width: 600px) {
        &.name,
        &.fee {
          width: 100%;
        }
      }
    }
    .purchase-availability-more-option-container {
      display: grid;
      column-gap: 50px;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: 600px) {
        display: block;
      }
    }
  }

  .save-button,
  .date-button {
    &::v-deep button {
      padding: 10px 0;
      width: 100%;
    }
  }

  .ticket-counter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    margin-bottom: 32px;
    background-color: var(--secondary-blue);
    border: 2px solid var(--primary-blue);
    border-radius: 8px;
    box-shadow: 1px 0 10px 1px #0f0f0f8f, inset 0 1px 1px #0f0f0f8f;

    .label {
      font-size: 18px;
      font-weight: bold;
    }

    .counter {
      font-size: 52px;
      font-weight: bold;

      .over {
        color: var(--error-red);
      }
    }
  }

  .venue-date-selectors {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 32px;

    .date {
      display: flex;
      align-items: center;

      &::v-deep button {
        width: 196px;
        font-size: 18px;
        margin: 0;
        height: 50px;
        align-self: flex-end;
      }

      &::v-deep .formulate-input {
        margin: 0;
        width: auto;

        input {
          width: 80px;
        }

        label {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  .venue-time-selectors {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 32px;
    width: 100%;

    .time-selectors {
      display: flex;
      flex-direction: column;
      width: 100%;

      .interval {
        display: flex;
        align-items: center;
        gap: 16px;

        .date {
          min-width: 160px;
        }
      }

      .times {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        .time {
          display: flex;
          align-items: center;

          &::v-deep button {
            width: 196px;
            margin: 0;
            height: 50px;
            align-self: flex-end;
          }

          &::v-deep .formulate-input {
            margin: 0;
            width: auto;

            input {
              width: 80px;
            }

            label {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    gap: 8px;
    margin-top: 32px;

    .tag {
      display: inline-flex;
      height: fit-content;
      gap: 8px;
      align-items: center;
      color: var(--primary-green);
      padding: 0 8px;
      background: var(--input-background);
      font-size: 13px;
      border: 1px solid var(--input-border);
      border-radius: 4px;
      cursor: pointer;

      .remove {
        padding: 0 4px;
        line-height: 1.5;
        font-size: 8px;
        border: 1px solid var(--input-border);
        border-radius: 4px;
      }
    }
  }
}
</style>
