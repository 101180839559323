<template>
  <FormulateForm
    ref="form"
    name="config"
    v-model="formValues"
    @input="onInput"
    autocomplete="false"
  >
    <h2>Event Locale</h2>
    <div class="row">
      <div class="col half">
        <FormulateInput
          type="selectSearch"
          label="Select Country"
          name="country"
          placeholder="Select Country"
          :options="countries"
          validation="required"
          :validation-messages="{
            required: 'Please select a country'
          }"
          autocomplete="false"
        />
      </div>
      <div class="col half">
        <FormulateInput
          type="selectSearch"
          label="Select Timezone"
          name="timezone"
          placeholder="Select Timezone"
          validation="required"
          :options="timezones"
          autocomplete="false"
          help=" This will be the timezone all of the dates and times in the
              following steps will use."
        >
          <!-- <template #help>
            <p>
             
              <strong>
                Note: dates and times are displayed in the user's local timezone
                across the site.
              </strong>
            </p>
          </template> -->
        </FormulateInput>
      </div>
    </div>
    <div class="row">
      <div class="col half">
        <!-- working input -->
        <FormulateInput
          v-model="formValues.eventType"
          type="select"
          name="eventType"
          label="Event Type"
          placeholder="Select Event Type"
          :options="eventTypes"
          validation="required"
          :validation-messages="{
            required: 'Please select an event type'
          }"
          @input="selectEventType"
          autocomplete="false"
        />
        <!-- ----------------- -->
      </div>
      <div class="col half">
        <FormulateInput
          type="select"
          name="currency"
          label="Currency"
          placeholder="Select currency"
          :options="currency"
          validation="required"
          :validation-messages="{
            required: 'Please select currency'
          }"
          autocomplete="false"
        />
      </div>
    </div>
    <div class="row">
      <div class="col half">
        <FormulateInput
          type="multiSelectSearch"
          name="languages"
          label="Event Languages"
          placeholder="Select Languages"
          autocomplete="false"
          :options="availableLanguages"
          use-count-label
          help="Select the languages you want to support, this will convert emails
        and waivers to those languages."
        />
      </div>
    </div>

    <div class="row">
      <div class="divider"></div>
      <h2>Funnel/Checkout Setup</h2>
      <FormulateInput
        type="text"
        name="cf_event_url"
        label="Event URL ( Event URL from Funnel/Checkout Platform )"
        placeholder="Event URL"
        autocomplete="false"
        help="E.g., https://goprozoom.com/"
      />
      <FormulateInput
        type="text"
        name="keap_language_custom_field_id"
        label="Keap Language Custom Field"
        placeholder="Custom Field Id"
        autocomplete="false"
        help="language custom field id from keap"
      />
      <FormulateInput
        type="text"
        name="keap_company_custom_field_id"
        label="Keap Company Custom Field"
        placeholder="Custom Field Id"
        autocomplete="false"
        help="company custom field id from keap"
      />
      <FormulateInput
        type="selectSearch"
        name="keap_holder_tag_id"
        label="Keap Holder Tag Id"
        placeholder="Keap Holder Tag Id"
        autocomplete="false"
        help="keap universal holder tag id from keap"
        @searchChange="getKeapTags"
        :options="keapTags"
      />

      <FormulateInput
        type="group"
        name="funnel_id"
        repeatable
        label="Funnel/Checkout ID's ( Add all ID's by clicking add button below )"
        add-label="Add Funnel ID"
        :removable="isEditEvent ? copyFunnelID : true"
        #default="{ index }"
      >
        <div class="row">
          <div class="col half">
            <FormulateInput
              type="text"
              name="id"
              placeholder="Funnel ID"
              autocomplete="false"
              :disabled="disableFunnelId(index)"
            />
          </div>
        </div>
      </FormulateInput>
    </div>

    <!--    <div class="row">-->
    <!--      <div class="col half" v-if="showSeatsIoField">-->
    <!--        <FormulateInput-->
    <!--          type="text"-->
    <!--          name="seatsEventKey"-->
    <!--          label="Event Key"-->
    <!--          placeholder="Provided by INCREDEVENT"-->
    <!--          validation="required"-->
    <!--          autocomplete="false"-->
    <!--        >-->
    <!--          <template #help>-->
    <!--            <p>-->
    <!--              <strong>-->
    <!--                If you have not yet received your Event & Category keys from-->
    <!--                INCREDEVENT you will not be able to continue with reserved-->
    <!--                seating.-->
    <!--              </strong>-->
    <!--              Please contact us through live chat or your ticketing rep to-->
    <!--              discuss options for Reserved Seating.-->
    <!--            </p>-->
    <!--          </template>-->
    <!--        </FormulateInput>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="row">-->
    <!--      <div class="col half" v-if="showSeatsIoField">-->
    <!--        <FormulateInput-->
    <!--          type="text"-->
    <!--          name="seatsEventKey"-->
    <!--          label="Event Key"-->
    <!--          placeholder="Provided by INCREDEVENT"-->
    <!--          validation="required"-->
    <!--          autocomplete="false"-->
    <!--        >-->
    <!--          <template #help>-->
    <!--            <p>-->
    <!--              <strong>-->
    <!--                If you have not yet received your Event & Category keys from-->
    <!--                INCREDEVENT you will not be able to continue with reserved-->
    <!--                seating.-->
    <!--              </strong>-->
    <!--              Please contact us through live chat or your ticketing rep to-->
    <!--              discuss options for Reserved Seating.-->
    <!--            </p>-->
    <!--          </template>-->
    <!--        </FormulateInput>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="divider"></div>
    <h2>Event Basics</h2>
    <div>
      <FormulateInput
        type="text"
        name="name"
        label="Event Name"
        placeholder="Event Name"
        validation="required"
        help="Recommended: 39 Characters"
        autocomplete="false"
      />
      <FormulateInput
        type="text"
        name="tagline"
        label="Tagline / Subtitle"
        placeholder="Tagline / Subtitle"
        help="This should describe your event in a few words. Should not contain venue, name or date. No caps."
        autocomplete="false"
      />
      <FormulateInput
        type="text"
        name="pixelID"
        label="Pixel ID"
        placeholder="Facebook Pixel ID"
        autocomplete="false"
      >
        <template #help>
          <div class="formulate-input-help">
            Just your Pixel ID. Our site will handle the rest of the code. Need
            help finding your Pixel ID? Click
            <a
              href="https://www.facebook.com/business/help/952192354843755?id=1205376682832142"
              target="_blank"
              class="social-text"
            >
              Here
            </a>
          </div>
        </template>
      </FormulateInput>
      <FormulateInput
        type="text"
        name="obv_link"
        label="Obv Event Url"
        placeholder="Obv Event Url"
        help="Put here the same event url what you set up in app.obv.io"
        autocomplete="false"
      />
      <FormulateInput
        type="text"
        name="sw_link"
        label="Smart Waiver Template ID"
        placeholder="Smart Waiver Template ID"
        autocomplete="false"
        v-model.trim="formValues.sw_link"
      >
        <template #help>
          <div class="formulate-input-help">
            Smart Waiver Template ID from
            <a href="https://app.smartwaiver.com/add/links">
              here.
            </a>
            e.g. https://waiver.smartwaiver.com/w/
            <b style="background:#575963">2zwxdftgzzfyjumxfwcobp</b>
            /web/
          </div>
        </template>
      </FormulateInput>
    </div>
    <!-- <div class="divider"></div>
    <h2>Tickets & Guest List</h2>
    <div>
      <p>
        Choose your attendance options below.
        <br/>
        <br/>
        <strong>Note:</strong>
        You will set up your guest list after you have created your event under
        Event Options on your Events Dashboard.
        <span class="green-star">*</span>
      </p>
      <FormulateInput
        :value="formValues.ticketsOrGuestList"
        :options="{
          tickets: 'I will be selling tickets only for this event.',
          guestList: 'I will be offering a guest list only for this event.',
          ticketsAndGuestList:
            'I will issue tickets and offer a guest list for this event.'
        }"
        type="radio"
        name="ticketsOrGuestList"
        validation="required"
        autocomplete="false"
      />
    </div> -->
    <div class="divider"></div>
    <h2>Ticketing Options</h2>
    <div class="ticketing-options-msg">
      <p>
        Once you create your event, you will not be able to change whether
        buyers or planners pays fees.
        <span class="green-star">*</span>
      </p>
    </div>
    <div>
      <FormulateInput
        v-if="!isEditEvent"
        :value="formValues.buyerOrPlannerPaysFees || 'buyerPaysFees'"
        :options="{
          buyerPaysFees:
            'Buyer pays all taxes & fees on top of ticket and add fee price',
          plannerPaysFees:
            'Planner pays all taxes & fees off of the ticket and add fee price'
        }"
        type="radio"
        name="buyerOrPlannerPaysFees"
      />
      <FormulateInput
        v-if="isEditEvent"
        :value="formValues.buyerOrPlannerPaysFees || 'buyerPaysFees'"
        :options="{
          buyerPaysFees:
            'Buyer pays all taxes & fees on top of ticket and add fee price',
          plannerPaysFees:
            'Planner pays all taxes & fees off of the ticket and add fee price'
        }"
        type="radio"
        name="buyerOrPlannerPaysFees"
        disabled
      />
    </div>

    <div>
      <!-- checkboxInput -->
      <FormulateInput
        :value="formValues.collectAttendeeInfo"
        v-model="formValues.collectAttendeeInfo"
        type="checkbox"
        name="collectAttendeeInfo"
        label="Collect Attendee Info"
        autocomplete="false"
        :disabled="isCollectAttendeeInfoDisabled"
      />
      <!-- -------------- -->
    </div>
    <div>
      <p>
        Attendees is a feature that allows buyers to distribute their tickets to
        their group. This gives you access to more data from people going to
        your event as well as all buyers and attendees will get updates from
        you. This is a great option for events with corporate bookings and large
        groups.
        <br />
        <br />
        **Please Note:** We cannot email tickets for events that have this
        option enabled. The tickets will be available in the purchaser/attendees
        account upon login but there is no way to edit QR codes once emailed.
        Your buyers and attendees will receive an email letting them know that
        they can fetch their purchases through their account.
      </p>
    </div>
    <div class="divider"></div>

    <h2>Taxes</h2>

    <FormulateInput
      type="group"
      name="taxes"
      repeatable
      add-label="Add Taxes"
      :validation="formValues.taxes.length === 0 && 'optional'"
      #default="{index}"
    >
      <div class="row taxes">
        <div class="col type">
          <FormulateInput
            type="select"
            name="type"
            label="Tax Type"
            placeholder="Select"
            validation="required"
            :options="taxes"
          />
        </div>
        <div class="col percentage">
          <FormulateInput
            type="number"
            name="percentage"
            label="Percentage"
            placeholder="%"
            validation="min:0|required"
            autocomplete="false"
          />
        </div>
        <div class="col type">
          <FormulateInput
            type="multiSelect"
            name="applicableTo"
            label="Applicable To"
            placeholder="Select"
            validation="required"
            use-count-label
            :options="applicableTos"
          />
        </div>
        <div class="col description">
          <FormulateInput
            type="text"
            name="description"
            label="Description"
            placeholder="Description"
            autocomplete="false"
          />
        </div>
        <div
          v-if="formValues.taxes && formValues.taxes[index]"
          class="col full save-brand-tax"
          @click="submitBrandTax(index)"
        >
          {{ saveBrandTax(index) }}
        </div>
      </div>
    </FormulateInput>
    <div class="divider"></div>
    <h2>User Search Options</h2>
    <div class="row">
      <div class="col half">
        <FormulateInput
          type="select"
          name="primaryCategory"
          label="Primary Category"
          placeholder="Primary Category"
          validation="required"
          help="Select a category for your event. This helps populate suggestions to our users for events they may want to go to."
          :options="primaryCategories"
          :validation-messages="{
            required: 'Please select a category'
          }"
          autocomplete="false"
        />
      </div>
      <div v-show="secondaryCategoryShown" class="col half">
        <FormulateInput
          type="select"
          name="secondaryCategory"
          label="Secondary Category"
          placeholder="Secondary Category"
          help="Select a secondary category for your event. This helps populate suggestions to our users for events they may want to go to."
          :validation="secondaryCategoryShown && 'required'"
          :options="secondaryCategories"
          :disabled="secondaryCategoryDisabled"
          :validation-messages="{
            required: 'Please select a secondary category'
          }"
          autocomplete="false"
        />
      </div>
      <div class="col half" @keypress.enter="onAddTag">
        <FormulateInput
          type="text"
          label="Tags"
          placeholder="Type a tag and hit enter"
          help="Press enter after each tag. Tags are used to index on search engines."
          v-model="tag"
          autocomplete="false"
        />
      </div>
      <div class="col half tags">
        <div
          v-for="(tag, index) in formValues.tags"
          class="tag"
          :key="index"
          @click="onRemoveTag(index)"
        >
          <span class="name">{{ tag }}</span>
          <span class="remove">
            <FontAwesomeIcon icon="times" />
          </span>
        </div>
      </div>
    </div>
  </FormulateForm>
</template>

<script>
import debounce from "debounce";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import Timezones from "dayjs/plugin/timezone";

dayjs.extend(Timezones);

library.add(faTimes);

export default {
  name: "Config",
  components: {
    FontAwesomeIcon
  },
  props: {
    info: Object,
    errors: Object,
    "is-edit-event": Boolean
    // ticketsOrGuestList: String
  },
  data() {
    return {
      eventTypes: [
        { label: "Live/In Person", value: "IN_PERSON" },
        { label: "Virtual Attendance", value: "VIRTUAL" },
        { label: "Hybrid", value: "HYBRID" }
      ],
      availableLanguages: [
        "Abkhaz",
        "Afar",
        "Afrikaans",
        "Akan",
        "Albanian",
        "Amharic",
        "Arabic",
        "Armenian",
        "Assamese",
        "Aymara",
        "Azerbaijani",
        "Balochi",
        "Bambara",
        "Bashkir",
        "Basque",
        "Belarusian",
        "Bengali",
        "Bhojpuri",
        "Bikol",
        "Bislama",
        "Bosnian",
        "Breton",
        "Bulgarian",
        "Burmese",
        "Cantonese",
        "Catalan",
        "Cebuano",
        "Chichewa",
        "Chittagonian",
        "Chinese",
        "Chuvash",
        "Cornish",
        "Corsican",
        "Croatian",
        "Czech",
        "Danish",
        "Dari",
        "Dhivehi",
        "Dholuo",
        "Dutch",
        "Dzongkha",
        "Edo",
        "English",
        "Esperanto",
        "Estonian",
        "Ewe",
        "Faroese",
        "Fijian",
        "Filipino",
        "Finnish",
        "French",
        "Fulah",
        "Ga",
        "Galician",
        "Ganda",
        "Georgian",
        "German",
        "Gilbertese",
        "Greek",
        "Guarani",
        "Gujarati",
        "Haitian Creole",
        "Hakka",
        "Hausa",
        "Hebrew",
        "Herero",
        "Hindi",
        "Hiri Motu",
        "Hmong",
        "Hungarian",
        "Icelandic",
        "Igbo",
        "Ilocano",
        "Indonesian",
        "Inuktitut",
        "Irish",
        "Italian",
        "Japanese",
        "Javanese",
        "Kannada",
        "Kanuri",
        "Kazakh",
        "Khmer",
        "Kikuyu",
        "Kinyarwanda",
        "Kirundi",
        "Komi",
        "Kongo",
        "Korean",
        "Kurdish",
        "Kyrgyz",
        "Lao",
        "Latin",
        "Latvian",
        "Lingala",
        "Lithuanian",
        "Luxembourgish",
        "Macedonian",
        "Malagasy",
        "Malay",
        "Malayalam",
        "Maltese",
        "Mandarin Chinese",
        "Manx",
        "Maori",
        "Marathi",
        "Marshallese",
        "Mongolian",
        "Nauruan",
        "Navajo",
        "Ndonga",
        "Nepali",
        "Norwegian",
        "Odia",
        "Oromo",
        "Ossetian",
        "Pali",
        "Panjabi",
        "Pashto",
        "Persian",
        "Polish",
        "Portuguese",
        "Quechua",
        "Romanian",
        "Russian",
        "Samoan",
        "Sango",
        "Sanskrit",
        "Scottish Gaelic",
        "Serbian",
        "Sesotho",
        "Setswana",
        "Shona",
        "Sindhi",
        "Sinhala",
        "Slovak",
        "Slovenian",
        "Somali",
        "Southern Ndebele",
        "Southern Sotho",
        "Spanish",
        "Sundanese",
        "Swahili",
        "Swati",
        "Swedish",
        "Tagalog",
        "Tajik",
        "Tamil",
        "Tatar",
        "Telugu",
        "Tetum",
        "Thai",
        "Tibetan",
        "Tigrinya",
        "Tok Pisin",
        "Tongan",
        "Tsonga",
        "Tswana",
        "Tumbuka",
        "Turkish",
        "Turkmen",
        "Twi",
        "Ukrainian",
        "Urdu",
        "Uyghur",
        "Uzbek",
        "Venda",
        "Vietnamese",
        "Walloon",
        "Welsh",
        "Western Frisian",
        "Wolof",
        "Xhosa",
        "Yiddish",
        "Yoruba",
        "Zulu"
      ],
      currency: [
        { label: "CAD (Canadian Dollar)", value: "cad" },
        { label: "USD (United States Dollar)", value: "usd" }
      ],
      currencies: [
        { label: "AED (United Arab Emirates Dirham)", value: "aed" },
        { label: "AFN (Afghan Afghani)", value: "afn" },
        { label: "ALL (Albanian Lek)", value: "all" },
        { label: "AMD (Armenian Dram)", value: "amd" },
        { label: "ANG (Netherlands Antillean Guilder)", value: "ang" },
        { label: "AOA (Angolan Kwanza)", value: "aoa" },
        { label: "ARS (Argentine Peso)", value: "ars" },
        { label: "AUD (Australian Dollar)", value: "aud" },
        { label: "AWG (Aruban Florin)", value: "awg" },
        { label: "AZN (Azerbaijani Manat)", value: "azn" },
        { label: "BAM (Bosnia-Herzegovina Convertible Mark)", value: "bam" },
        { label: "BBD (Barbadian Dollar)", value: "bbd" },
        { label: "BDT (Bangladeshi Taka)", value: "bdt" },
        { label: "BGN (Bulgarian Lev)", value: "bgn" },
        { label: "BHD (Bahraini Dinar)", value: "bhd" },
        { label: "BIF (Burundian Franc)", value: "bif" },
        { label: "BMD (Bermudan Dollar)", value: "bmd" },
        { label: "BND (Brunei Dollar)", value: "bnd" },
        { label: "BOB (Bolivian Boliviano)", value: "bob" },
        { label: "BRL (Brazilian Real)", value: "brl" },
        { label: "BSD (Bahamian Dollar)", value: "bsd" },
        { label: "BTC (Bitcoin)", value: "btc" },
        { label: "BTN (Bhutanese Ngultrum)", value: "btn" },
        { label: "BWP (Botswanan Pula)", value: "bwp" },
        { label: "BYN (Belarusian Ruble)", value: "byn" },
        { label: "BZD (Belize Dollar)", value: "bzd" },
        { label: "CAD (Canadian Dollar)", value: "cad" },
        { label: "CDF (Congolese Franc)", value: "cdf" },
        { label: "CHF (Swiss Franc)", value: "chf" },
        { label: "CLF (Chilean Unit of Account (UF))", value: "clf" },
        { label: "CLP (Chilean Peso)", value: "clp" },
        { label: "CNH (Chinese Yuan (Offshore))", value: "cnh" },
        { label: "CNY (Chinese Yuan)", value: "cny" },
        { label: "COP (Colombian Peso)", value: "cop" },
        { label: "CRC (Costa Rican Colón)", value: "crc" },
        { label: "CUC (Cuban Convertible Peso)", value: "cuc" },
        { label: "CUP (Cuban Peso)", value: "cup" },
        { label: "CVE (Cape Verdean Escudo)", value: "cve" },
        { label: "CZK (Czech Republic Koruna)", value: "czk" },
        { label: "DJF (Djiboutian Franc)", value: "djf" },
        { label: "DKK (Danish Krone)", value: "dkk" },
        { label: "DOP (Dominican Peso)", value: "dop" },
        { label: "DZD (Algerian Dinar)", value: "dzd" },
        { label: "EGP (Egyptian Pound)", value: "egp" },
        { label: "ERN (Eritrean Nakfa)", value: "ern" },
        { label: "ETB (Ethiopian Birr)", value: "etb" },
        { label: "EUR (Euro)", value: "eur" },
        { label: "FJD (Fijian Dollar)", value: "fjd" },
        { label: "FKP (Falkland Islands Pound)", value: "fkp" },
        { label: "GBP (British Pound Sterling)", value: "gbp" },
        { label: "GEL (Georgian Lari)", value: "gel" },
        { label: "GGP (Guernsey Pound)", value: "ggp" },
        { label: "GHS (Ghanaian Cedi)", value: "ghs" },
        { label: "GIP (Gibraltar Pound)", value: "gip" },
        { label: "GMD (Gambian Dalasi)", value: "gmd" },
        { label: "GNF (Guinean Franc)", value: "gnf" },
        { label: "GTQ (Guatemalan Quetzal)", value: "gtq" },
        { label: "GYD (Guyanaese Dollar)", value: "gyd" },
        { label: "HKD (Hong Kong Dollar)", value: "hkd" },
        { label: "HNL (Honduran Lempira)", value: "hnl" },
        { label: "HRK (Croatian Kuna)", value: "hrk" },
        { label: "HTG (Haitian Gourde)", value: "htg" },
        { label: "HUF (Hungarian Forint)", value: "huf" },
        { label: "IDR (Indonesian Rupiah)", value: "idr" },
        { label: "ILS (Israeli New Sheqel)", value: "ils" },
        { label: "IMP (Manx pound)", value: "imp" },
        { label: "INR (Indian Rupee)", value: "inr" },
        { label: "IQD (Iraqi Dinar)", value: "iqd" },
        { label: "IRR (Iranian Rial)", value: "irr" },
        { label: "ISK (Icelandic Króna)", value: "isk" },
        { label: "JEP (Jersey Pound)", value: "jep" },
        { label: "JMD (Jamaican Dollar)", value: "jmd" },
        { label: "JOD (Jordanian Dinar)", value: "jod" },
        { label: "JPY (Japanese Yen)", value: "jpy" },
        { label: "KES (Kenyan Shilling)", value: "kes" },
        { label: "KGS (Kyrgystani Som)", value: "kgs" },
        { label: "KHR (Cambodian Riel)", value: "khr" },
        { label: "KMF (Comorian Franc)", value: "kmf" },
        { label: "KPW (North Korean Won)", value: "kpw" },
        { label: "KRW (South Korean Won)", value: "krw" },
        { label: "KWD (Kuwaiti Dinar)", value: "kwd" },
        { label: "KYD (Cayman Islands Dollar)", value: "kyd" },
        { label: "KZT (Kazakhstani Tenge)", value: "kzt" },
        { label: "LAK (Laotian Kip)", value: "lak" },
        { label: "LBP (Lebanese Pound)", value: "lbp" },
        { label: "LKR (Sri Lankan Rupee)", value: "lkr" },
        { label: "LRD (Liberian Dollar)", value: "lrd" },
        { label: "LSL (Lesotho Loti)", value: "lsl" },
        { label: "LYD (Libyan Dinar)", value: "lyd" },
        { label: "MAD (Moroccan Dirham)", value: "mad" },
        { label: "MDL (Moldovan Leu)", value: "mdl" },
        { label: "MGA (Malagasy Ariary)", value: "mga" },
        { label: "MKD (Macedonian Denar)", value: "mkd" },
        { label: "MMK (Myanma Kyat)", value: "mmk" },
        { label: "MNT (Mongolian Tugrik)", value: "mnt" },
        { label: "MOP (Macanese Pataca)", value: "mop" },
        { label: "MRU (Mauritanian Ouguiya)", value: "mru" },
        { label: "MUR (Mauritian Rupee)", value: "mur" },
        { label: "MVR (Maldivian Rufiyaa)", value: "mvr" },
        { label: "MWK (Malawian Kwacha)", value: "mwk" },
        { label: "MXN (Mexican Peso)", value: "mxn" },
        { label: "MYR (Malaysian Ringgit)", value: "myr" },
        { label: "MZN (Mozambican Metical)", value: "mzn" },
        { label: "NAD (Namibian Dollar)", value: "nad" },
        { label: "NGN (Nigerian Naira)", value: "ngn" },
        { label: "NIO (Nicaraguan Córdoba)", value: "nio" },
        { label: "NOK (Norwegian Krone)", value: "nok" },
        { label: "NPR (Nepalese Rupee)", value: "npr" },
        { label: "NZD (New Zealand Dollar)", value: "nzd" },
        { label: "OMR (Omani Rial)", value: "omr" },
        { label: "PAB (Panamanian Balboa)", value: "pab" },
        { label: "PEN (Peruvian Nuevo Sol)", value: "pen" },
        { label: "PGK (Papua New Guinean Kina)", value: "pgk" },
        { label: "PHP (Philippine Peso)", value: "php" },
        { label: "PKR (Pakistani Rupee)", value: "pkr" },
        { label: "PLN (Polish Zloty)", value: "pln" },
        { label: "PYG (Paraguayan Guarani)", value: "pyg" },
        { label: "QAR (Qatari Rial)", value: "qar" },
        { label: "RON (Romanian Leu)", value: "ron" },
        { label: "RSD (Serbian Dinar)", value: "rsd" },
        { label: "RUB (Russian Ruble)", value: "rub" },
        { label: "RWF (Rwandan Franc)", value: "rwf" },
        { label: "SAR (Saudi Riyal)", value: "sar" },
        { label: "SBD (Solomon Islands Dollar)", value: "sbd" },
        { label: "SCR (Seychellois Rupee)", value: "scr" },
        { label: "SDG (Sudanese Pound)", value: "sdg" },
        { label: "SEK (Swedish Krona)", value: "sek" },
        { label: "SGD (Singapore Dollar)", value: "sgd" },
        { label: "SHP (Saint Helena Pound)", value: "shp" },
        { label: "SLL (Sierra Leonean Leone)", value: "sll" },
        { label: "SOS (Somali Shilling)", value: "sos" },
        { label: "SRD (Surinamese Dollar)", value: "srd" },
        { label: "SSP (South Sudanese Pound)", value: "ssp" },
        { label: "STD (São Tomé and Príncipe Dobra (pre-2018))", value: "std" },
        { label: "STN (São Tomé and Príncipe Dobra)", value: "stn" },
        { label: "SVC (Salvadoran Colón)", value: "svc" },
        { label: "SYP (Syrian Pound)", value: "syp" },
        { label: "SZL (Swazi Lilangeni)", value: "szl" },
        { label: "THB (Thai Baht)", value: "thb" },
        { label: "TJS (Tajikistani Somoni)", value: "tjs" },
        { label: "TMT (Turkmenistani Manat)", value: "tmt" },
        { label: "TND (Tunisian Dinar)", value: "tnd" },
        { label: "TOP (Tongan Pa'anga)", value: "top" },
        { label: "TRY (Turkish Lira)", value: "try" },
        { label: "TTD (Trinidad and Tobago Dollar)", value: "ttd" },
        { label: "TWD (New Taiwan Dollar)", value: "twd" },
        { label: "TZS (Tanzanian Shilling)", value: "tzs" },
        { label: "UAH (Ukrainian Hryvnia)", value: "uah" },
        { label: "UGX (Ugandan Shilling)", value: "ugx" },
        { label: "USD (United States Dollar)", value: "usd" },
        { label: "UYU (Uruguayan Peso)", value: "uyu" },
        { label: "UZS (Uzbekistan Som)", value: "uzs" },
        { label: "VEF (Venezuelan Bolívar Fuerte (Old))", value: "vef" },
        { label: "VES (Venezuelan Bolívar Soberano)", value: "ves" },
        { label: "VND (Vietnamese Dong)", value: "vnd" },
        { label: "VUV (Vanuatu Vatu)", value: "vuv" },
        { label: "WST (Samoan Tala)", value: "wst" },
        { label: "XAF (CFA Franc BEAC)", value: "xaf" },
        { label: "XAG (Silver Ounce)", value: "xag" },
        { label: "XAU (Gold Ounce)", value: "xau" },
        { label: "XCD (East Caribbean Dollar)", value: "xcd" },
        { label: "XDR (Special Drawing Rights)", value: "xdr" },
        { label: "XOF (CFA Franc BCEAO)", value: "xof" },
        { label: "XPD (Palladium Ounce)", value: "xpd" },
        { label: "XPF (CFP Franc)", value: "xpf" },
        { label: "XPT (Platinum Ounce)", value: "xpt" },
        { label: "YER (Yemeni Rial)", value: "yer" },
        { label: "ZAR (South African Rand)", value: "zar" },
        { label: "ZMW (Zambian Kwacha)", value: "zmw" },
        { label: "ZWL (Zimbabwean Dollar)", value: "zwl" }
      ],
      country: ["Canada", "USA"],
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia (Plurinational State of)",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo, Democratic Republic of the",
        "Costa Rica",
        "Côte d'Ivoire",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czechia",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran (Islamic Republic of)",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea (Democratic People's Republic of)",
        "Korea, Republic of",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia (Federated States of)",
        "Moldova, Republic of",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "North Macedonia",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russia",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan, Province of China",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom of Great Britain and Northern Ireland",
        "United States of America",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela (Bolivarian Republic of)",
        "Viet Nam",
        "Yemen",
        "Zambia",
        "Zimbabwe"
      ],

      primaryCategories: [
        { label: "Corporate", value: "Corporate" },
        { label: "Comedy", value: "Comedy" },
        { label: "Cultural", value: "Cultural" },
        { label: "DJ", value: "DJ" },
        { label: "Live Music", value: "Live Music" },
        { label: "Nightclub", value: "Nightclub" },
        { label: "Religious", value: "Religious" },
        { label: "Sport", value: "Sport" },
        { label: "Tourism", value: "Tourism" },
        { label: "Other", value: "Other" }
      ],
      secondaryCategories: [],
      djLiveMusicOptions: [
        { label: "Blues", value: "Blues" },
        { label: "Classical", value: "Classical" },
        { label: "Classic Rock", value: "Classic Rock" },
        { label: "Country", value: "Country" },
        { label: "EDM", value: "EDM" },
        { label: "Folk", value: "Folk" },
        { label: "Francophone", value: "Francophone" },
        { label: "Funk", value: "Funk" },
        { label: "Hip-Hop", value: "Hip-Hop" },
        { label: "Indie", value: "Indie" },
        { label: "Jazz", value: "Jazz" },
        { label: "K-Pop", value: "K-Pop" },
        { label: "Latin", value: "Latin" },
        { label: "Metal", value: "Metal" },
        { label: "Pop", value: "Pop" },
        { label: "Punk", value: "Punk" },
        { label: "R&B", value: "R&B" },
        { label: "Reggae", value: "Reggae" },
        { label: "Rock", value: "Rock" },
        { label: "Soca", value: "Soca" },
        { label: "Soul", value: "Soul" },
        { label: "Other", value: "Other" }
      ],
      corporateOptions: [
        { label: "After Parties", value: "After Parties" },
        { label: "Awards Show", value: "Awards Show" },
        { label: "Convention", value: "Convention" },
        { label: "Galas", value: "Galas" },
        { label: "Speakers", value: "Speakers" },
        { label: "Other", value: "Other" }
      ],
      sportsOptions: [
        { label: "Basketball", value: "Basketball" },
        { label: "Boxing", value: "Boxing" },
        { label: "Curling", value: "Curling" },
        { label: "Fencing", value: "Fencing" },
        { label: "Figure Skating", value: "Figure Skating" },
        { label: "Football", value: "Football" },
        { label: "Gymnastics", value: "Gymnastics" },
        { label: "Handball", value: "Handball" },
        { label: "Hockey", value: "Hockey" },
        { label: "Judo", value: "Judo" },
        { label: "Rugby", value: "Rugby" },
        { label: "Snowboarding", value: "Snowboarding" },
        { label: "Swimming", value: "Swimming" },
        { label: "Table Tennis", value: "Table Tennis" },
        { label: "Taekwondo", value: "Taekwondo" },
        { label: "Tennis", value: "Tennis" },
        { label: "VolleyBall", value: "VolleyBall" },
        { label: "Water Polo", value: "Water Polo" },
        { label: "Other", value: "Other" }
      ],
      nightclibOptions: [
        { label: "Art Show", value: "Art Show" },
        { label: "Charity", value: "Charity" },
        { label: "DJ", value: "DJ" },
        { label: "Fashion Show", value: "Fashion Show" },
        { label: "Holidays", value: "Holidays" },
        { label: "Live Performance", value: "Live Performance" },
        { label: "Other", value: "Other" }
      ],
      tourismOptions: [
        { label: "Ghost Tours", value: "Ghost Tours" },
        { label: "History Tours", value: "History Tours" },
        { label: "Sightseeing Tours", value: "Sightseeing Tours" },
        { label: "Venue Tours", value: "Venue Tours" },
        { label: "Walking Tours", value: "Walking Tours" },
        { label: "Other", value: "Other" }
      ],
      comedyOptions: [
        { label: "Celebrity", value: "Celebrity" },
        { label: "Conversational", value: "Conversational" },
        { label: "Family", value: "Family" },
        { label: "Impersonator", value: "Impersonator" },
        { label: "Musical Comedy", value: "Musical Comedy" },
        { label: "Raunchy", value: "Raunchy" },
        { label: "Ventriloquist", value: "Ventriloquist" },
        { label: "Other", value: "Other" }
      ],
      religiousOptions: [
        { label: "BBQ/Cook Out", value: "BBQ/Cook Out" },
        { label: "Charity", value: "Charity" },
        { label: "Concerts", value: "Concerts" },
        { label: "Faith Healings", value: "Faith Healings" },
        { label: "Holiday", value: "Holiday" },
        { label: "Speakers", value: "Speakers" },
        { label: "Sport", value: "Sport" },
        { label: "Youth Group Events", value: "Youth Group Events" },
        { label: "Other", value: "Other" }
      ],
      culturalOptions: [
        { label: "African", value: "African" },
        { label: "American", value: "American" },
        { label: "Asian", value: "Asian" },
        { label: "Australian", value: "Australian" },
        { label: "Austrian", value: "Austrian" },
        { label: "Balkan", value: "Balkan" },
        { label: "Baltic", value: "Baltic" },
        { label: "Belgian", value: "Belgian" },
        { label: "Brazilian", value: "Brazilian" },
        { label: "British", value: "British" },
        { label: "Bulgarian", value: "Bulgarian" },
        { label: "Canadian", value: "Canadian" },
        { label: "Chinese", value: "Chinese" },
        { label: "Croatian", value: "Croation" },
        { label: "Cuban", value: "Cuban" },
        { label: "Danish", value: "Danish" },
        { label: "Dutch", value: "Dutch" },
        { label: "Eastern", value: "Eastern" },
        { label: "Egyptian", value: "Egyptian" },
        { label: "Estonian", value: "Estonian" },
        { label: "European", value: "European" },
        { label: "Finnish", value: "Finnish" },
        { label: "French", value: "French" },
        { label: "German", value: "German" },
        { label: "Greek", value: "Greek" },
        { label: "Icelandic", value: "Icelandic" },
        { label: "Indian", value: "Indian" },
        { label: "Irish", value: "Irish" },
        { label: "Israeli", value: "Israeli" },
        { label: "Italian", value: "Italian" },
        { label: "Japanese", value: "Japanese" },
        { label: "Latvian", value: "Latvian" },
        { label: "Lithuanian", value: "Lithuanian" },
        { label: "Macadonian", value: "Macadonian" },
        { label: "Montenegrin", value: "Montenegrin" },
        { label: "Nordic", value: "Nordic" },
        { label: "Norwegian", value: "Norwegian" },
        { label: "Polish", value: "Polish" },
        { label: "Portuguese", value: "Portuguese" },
        { label: "Russian", value: "Russian" },
        { label: "Scandinavian", value: "Scandinavian" },
        { label: "Serbian", value: "Serbian" },
        { label: "Slavic", value: "Slavic" },
        { label: "Slovenian", value: "Slovenian" },
        { label: "South African", value: "South African" },
        { label: "South Korean", value: "South Korean" },
        { label: "Soviet", value: "Soviet" },
        { label: "Spanish", value: "Spanish" },
        { label: "Swedish", value: "Swedish" },
        { label: "Swiss", value: "Swiss" },
        { label: "Ukrainian", value: "Ukrainian" },
        { label: "Uzbek", value: "Uzbek" },
        { label: "Western", value: "Western" },
        { label: "Zimbabwean", value: "Zimbabwean" },
        { label: "Other", value: "Other" }
      ],
      tag: "",
      taxes: [
        "GST",
        "HST",
        "PST",
        "Sales",
        "Use",
        "Local",
        "State",
        "Entertainment",
        "Other"
      ],
      applicableTos: [
        { label: "Virtual Tickets", value: "VIRTUAL_TICKETS" },
        { label: "Live Tickets", value: "LIVE_TICKETS" },
        { label: "Products", value: "PRODUCTS" },
        { label: "Guest list", value: "GUESTLIST" }
      ],
      timezones: [
        { label: "(GMT+00:00) Africa/Abidjan", value: "Africa/Abidjan" },
        { label: "(GMT+01:00) Africa/Algiers", value: "Africa/Algiers" },
        { label: "(GMT+00:00) Africa/Bissau", value: "Africa/Bissau" },
        { label: "(GMT+02:00) Africa/Cairo", value: "Africa/Cairo" },
        { label: "(GMT+01:00) Africa/Casablanca", value: "Africa/Casablanca" },
        { label: "(GMT+02:00) Africa/Ceuta", value: "Africa/Ceuta" },
        { label: "(GMT+00:00) Africa/El_Aaiun", value: "Africa/El_Aaiun" },
        {
          label: "(GMT+02:00) Africa/Johannesburg",
          value: "Africa/Johannesburg"
        },
        { label: "(GMT+03:00) Africa/Juba", value: "Africa/Juba" },
        { label: "(GMT+02:00) Africa/Khartoum", value: "Africa/Khartoum" },
        { label: "(GMT+01:00) Africa/Lagos", value: "Africa/Lagos" },
        { label: "(GMT+02:00) Africa/Maputo", value: "Africa/Maputo" },
        { label: "(GMT+00:00) Africa/Monrovia", value: "Africa/Monrovia" },
        { label: "(GMT+03:00) Africa/Nairobi", value: "Africa/Nairobi" },
        { label: "(GMT+01:00) Africa/Ndjamena", value: "Africa/Ndjamena" },
        { label: "(GMT+00:00) Africa/Sao_Tome", value: "Africa/Sao_Tome" },
        { label: "(GMT+02:00) Africa/Tripoli", value: "Africa/Tripoli" },
        { label: "(GMT+01:00) Africa/Tunis", value: "Africa/Tunis" },
        { label: "(GMT+02:00) Africa/Windhoek", value: "Africa/Windhoek" },
        { label: "(GMT-10:00) America/Adak", value: "America/Adak" },
        { label: "(GMT-08:00) America/Anchorage", value: "America/Anchorage" },
        { label: "(GMT-03:00) America/Araguaina", value: "America/Araguaina" },
        {
          label: "(GMT-03:00) America/Argentina/Buenos_Aires",
          value: "America/Argentina/Buenos_Aires"
        },
        {
          label: "(GMT-03:00) America/Argentina/Catamarca",
          value: "America/Argentina/Catamarca"
        },
        {
          label: "(GMT-03:00) America/Argentina/Cordoba",
          value: "America/Argentina/Cordoba"
        },
        {
          label: "(GMT-03:00) America/Argentina/Jujuy",
          value: "America/Argentina/Jujuy"
        },
        {
          label: "(GMT-03:00) America/Argentina/La_Rioja",
          value: "America/Argentina/La_Rioja"
        },
        {
          label: "(GMT-03:00) America/Argentina/Mendoza",
          value: "America/Argentina/Mendoza"
        },
        {
          label: "(GMT-03:00) America/Argentina/Rio_Gallegos",
          value: "America/Argentina/Rio_Gallegos"
        },
        {
          label: "(GMT-03:00) America/Argentina/Salta",
          value: "America/Argentina/Salta"
        },
        {
          label: "(GMT-03:00) America/Argentina/San_Juan",
          value: "America/Argentina/San_Juan"
        },
        {
          label: "(GMT-03:00) America/Argentina/San_Luis",
          value: "America/Argentina/San_Luis"
        },
        {
          label: "(GMT-03:00) America/Argentina/Tucuman",
          value: "America/Argentina/Tucuman"
        },
        {
          label: "(GMT-03:00) America/Argentina/Ushuaia",
          value: "America/Argentina/Ushuaia"
        },
        { label: "(GMT-04:00) America/Asuncion", value: "America/Asuncion" },
        { label: "(GMT-05:00) America/Atikokan", value: "America/Atikokan" },
        { label: "(GMT-03:00) America/Bahia", value: "America/Bahia" },
        {
          label: "(GMT-06:00) America/Bahia_Banderas",
          value: "America/Bahia_Banderas"
        },
        { label: "(GMT-04:00) America/Barbados", value: "America/Barbados" },
        { label: "(GMT-03:00) America/Belem", value: "America/Belem" },
        { label: "(GMT-06:00) America/Belize", value: "America/Belize" },
        {
          label: "(GMT-04:00) America/Blanc-Sablon",
          value: "America/Blanc-Sablon"
        },
        { label: "(GMT-04:00) America/Boa_Vista", value: "America/Boa_Vista" },
        { label: "(GMT-05:00) America/Bogota", value: "America/Bogota" },
        { label: "(GMT-06:00) America/Boise", value: "America/Boise" },
        {
          label: "(GMT-06:00) America/Cambridge_Bay",
          value: "America/Cambridge_Bay"
        },
        {
          label: "(GMT-04:00) America/Campo_Grande",
          value: "America/Campo_Grande"
        },
        { label: "(GMT-05:00) America/Cancun", value: "America/Cancun" },
        { label: "(GMT-04:00) America/Caracas", value: "America/Caracas" },
        { label: "(GMT-03:00) America/Cayenne", value: "America/Cayenne" },
        { label: "(GMT-5:00) America/Chicago", value: "America/Chicago" },
        { label: "(GMT-6:00) America/Chihuahua", value: "America/Chihuahua" },
        { label: "(GMT-6:00) America/Costa_Rica", value: "America/Costa_Rica" },
        { label: "(GMT-7:00) America/Creston", value: "America/Creston" },
        { label: "(GMT-4:00) America/Cuiaba", value: "America/Cuiaba" },
        { label: "(GMT-4:00) America/Curacao", value: "America/Curacao" },
        {
          label: "(GMT+0:00) America/Danmarkshavn",
          value: "America/Danmarkshavn"
        },
        { label: "(GMT-7:00) America/Dawson", value: "America/Dawson" },
        {
          label: "(GMT-7:00) America/Dawson_Creek",
          value: "America/Dawson_Creek"
        },
        { label: "(GMT-6:00) America/Denver", value: "America/Denver" },
        { label: "(GMT-4:00) America/Detroit", value: "America/Detroit" },
        { label: "(GMT-6:00) America/Edmonton", value: "America/Edmonton" },
        { label: "(GMT-5:00) America/Eirunepe", value: "America/Eirunepe" },
        {
          label: "(GMT-6:00) America/El_Salvador",
          value: "America/El_Salvador"
        },
        {
          label: "(GMT-7:00) America/Fort Nelson",
          value: "America/Fort_Nelson"
        },
        { label: "(GMT-3:00) America/Fortaleza", value: "America/Fortaleza" },
        { label: "(GMT-3:00) America/Glace Bay", value: "America/Glace_Bay" },
        { label: "(GMT-2:00) America/Godthab", value: "America/Godthab" },
        { label: "(GMT-3:00) America/Goose Bay", value: "America/Goose_Bay" },
        { label: "(GMT-4:00) America/Grand Turk", value: "America/Grand_Turk" },
        { label: "(GMT-6:00) America/Guatemala", value: "America/Guatemala" },
        { label: "(GMT-5:00) America/Guayaquil", value: "America/Guayaquil" },
        { label: "(GMT-4:00) America/Guyana", value: "America/Guyana" },
        { label: "(GMT-3:00) America/Halifax", value: "America/Halifax" },
        { label: "(GMT-4:00) America/Havana", value: "America/Havana" },
        { label: "(GMT-7:00) America/Hermosillo", value: "America/Hermosillo" },
        {
          label: "(GMT-5:00) America/Indiana/Indianapolis",
          value: "America/Indiana/Indianapolis"
        },
        {
          label: "(GMT-5:00) America/Indiana/Knox",
          value: "America/Indiana/Knox"
        },
        {
          label: "(GMT-4:00) America/Indiana/Marengo",
          value: "America/Indiana/Marengo"
        },
        {
          label: "(GMT-4:00) America/Indiana/Petersburg",
          value: "America/Indiana/Petersburg"
        },
        {
          label: "(GMT-5:00) America/Indiana/Tell City",
          value: "America/Indiana/Tell_City"
        },
        {
          label: "(GMT-4:00) America/Indiana/Vevay",
          value: "America/Indiana/Vevay"
        },
        {
          label: "(GMT-4:00) America/Indiana/Vincennes",
          value: "America/Indiana/Vincennes"
        },
        {
          label: "(GMT-4:00) America/Indiana/Winamac",
          value: "America/Indiana/Winamac"
        },
        { label: "(GMT-6:00) America/Inuvik", value: "America/Inuvik" },
        { label: "(GMT-5:00) America/Iqaluit", value: "America/Iqaluit" },
        { label: "(GMT-5:00) America/Jamaica", value: "America/Jamaica" },
        { label: "(GMT-8:00) America/Juneau", value: "America/Juneau" },
        {
          label: "(GMT-4:00) America/Kentucky/Louisville",
          value: "America/Kentucky/Louisville"
        },
        {
          label: "(GMT-4:00) America/Kentucky/Monticello",
          value: "America/Kentucky/Monticello"
        },
        { label: "(GMT-4:00) America/La Paz", value: "America/La_Paz" },
        { label: "(GMT-5:00) America/Lima", value: "America/Lima" },
        {
          label: "(GMT-7:00) America/Los_Angeles",
          value: "America/Los_Angeles"
        },
        { label: "(GMT-3:00) America/Maceio", value: "America/Maceio" },
        { label: "(GMT-6:00) America/Managua", value: "America/Managua" },
        { label: "(GMT-4:00) America/Manaus", value: "America/Manaus" },
        { label: "(GMT-4:00) America/Martinique", value: "America/Martinique" },
        { label: "(GMT-5:00) America/Matamoros", value: "America/Matamoros" },
        { label: "(GMT-7:00) America/Mazatlan", value: "America/Mazatlan" },
        { label: "(GMT-5:00) America/Menominee", value: "America/Menominee" },
        { label: "(GMT-5:00) America/Merida", value: "America/Merida" },
        { label: "(GMT-8:00) America/Metlakatla", value: "America/Metlakatla" },
        {
          label: "(GMT-5:00) America/Mexico_City",
          value: "America/Mexico_City"
        },
        { label: "(GMT-2:00) America/Miquelon", value: "America/Miquelon" },
        { label: "(GMT-3:00) America/Moncton", value: "America/Moncton" },
        { label: "(GMT-5:00) America/Monterrey", value: "America/Monterrey" },
        { label: "(GMT-3:00) America/Montevideo", value: "America/Montevideo" },
        { label: "(GMT-4:00) America/Nassau", value: "America/Nassau" },
        { label: "(GMT-4:00) America/New_York", value: "America/New_York" },
        { label: "(GMT-4:00) America/Nipigon", value: "America/Nipigon" },
        { label: "(GMT-8:00) America/Nome", value: "America/Nome" },
        { label: "(GMT-2:00) America/Noronha", value: "America/Noronha" },
        {
          label: "(GMT-06:00) America/North_Dakota/Beulah",
          value: "America/North_Dakota/Beulah"
        },
        {
          label: "(GMT-06:00) America/North_Dakota/Center",
          value: "America/North_Dakota/Center"
        },
        {
          label: "(GMT-06:00) America/North_Dakota/New_Salem",
          value: "America/North_Dakota/New_Salem"
        },
        { label: "(GMT-07:00) America/Ojinaga", value: "America/Ojinaga" },
        { label: "(GMT-05:00) America/Panama", value: "America/Panama" },
        {
          label: "(GMT-04:00) America/Pangnirtung",
          value: "America/Pangnirtung"
        },
        {
          label: "(GMT-03:00) America/Paramaribo",
          value: "America/Paramaribo"
        },
        { label: "(GMT-07:00) America/Phoenix", value: "America/Phoenix" },
        {
          label: "(GMT-04:00) America/Port-au-Prince",
          value: "America/Port-au-Prince"
        },
        {
          label: "(GMT-04:00) America/Port_of_Spain",
          value: "America/Port_of_Spain"
        },
        {
          label: "(GMT-04:00) America/Porto_Velho",
          value: "America/Porto_Velho"
        },
        {
          label: "(GMT-04:00) America/Puerto_Rico",
          value: "America/Puerto_Rico"
        },
        {
          label: "(GMT-03:00) America/Punta_Arenas",
          value: "America/Punta_Arenas"
        },
        {
          label: "(GMT-06:00) America/Rainy_River",
          value: "America/Rainy_River"
        },
        {
          label: "(GMT-06:00) America/Rankin_Inlet",
          value: "America/Rankin_Inlet"
        },
        { label: "(GMT-03:00) America/Recife", value: "America/Recife" },
        { label: "(GMT-06:00) America/Regina", value: "America/Regina" },
        { label: "(GMT-06:00) America/Resolute", value: "America/Resolute" },
        {
          label: "(GMT-05:00) America/Rio_Branco",
          value: "America/Rio_Branco"
        },
        { label: "(GMT-03:00) America/Santarem", value: "America/Santarem" },
        { label: "(GMT-04:00) America/Santiago", value: "America/Santiago" },
        {
          label: "(GMT-04:00) America/Santo_Domingo",
          value: "America/Santo_Domingo"
        },
        { label: "(GMT-03:00) America/Sao_Paulo", value: "America/Sao_Paulo" },
        {
          label: "(GMT-01:00) America/Scoresbysund",
          value: "America/Scoresbysund"
        },
        { label: "(GMT-08:00) America/Sitka", value: "America/Sitka" },
        { label: "(GMT-03:30) America/St_Johns", value: "America/St_Johns" },
        {
          label: "(GMT-06:00) America/Swift_Current",
          value: "America/Swift_Current"
        },
        {
          label: "(GMT-06:00) America/Tegucigalpa",
          value: "America/Tegucigalpa"
        },
        { label: "(GMT-03:00) America/Thule", value: "America/Thule" },
        {
          label: "(GMT-04:00) America/Thunder_Bay",
          value: "America/Thunder_Bay"
        },
        { label: "(GMT-07:00) America/Tijuana", value: "America/Tijuana" },
        { label: "(GMT-04:00) America/Toronto", value: "America/Toronto" },
        { label: "(GMT-07:00) America/Vancouver", value: "America/Vancouver" },
        {
          label: "(GMT-07:00) America/Whitehorse",
          value: "America/Whitehorse"
        },
        { label: "(GMT-05:00) America/Winnipeg", value: "America/Winnipeg" },
        { label: "(GMT-08:00) America/Yakutat", value: "America/Yakutat" },
        {
          label: "(GMT-06:00) America/Yellowknife",
          value: "America/Yellowknife"
        },
        { label: "(GMT+06:00) Asia/Almaty", value: "Asia/Almaty" },
        { label: "(GMT+03:00) Asia/Amman", value: "Asia/Amman" },
        { label: "(GMT+12:00) Asia/Anadyr", value: "Asia/Anadyr" },
        { label: "(GMT+05:00) Asia/Aqtau", value: "Asia/Aqtau" },
        { label: "(GMT+05:00) Asia/Aqtobe", value: "Asia/Aqtobe" },
        { label: "(GMT+05:00) Asia/Ashgabat", value: "Asia/Ashgabat" },
        { label: "(GMT+05:00) Asia/Atyrau", value: "Asia/Atyrau" },
        { label: "(GMT+03:00) Asia/Baghdad", value: "Asia/Baghdad" },
        { label: "(GMT+04:00) Asia/Baku", value: "Asia/Baku" },
        { label: "(GMT+07:00) Asia/Bangkok", value: "Asia/Bangkok" },
        { label: "(GMT+07:00) Asia/Barnaul", value: "Asia/Barnaul" },
        { label: "(GMT+03:00) Asia/Beirut", value: "Asia/Beirut" },
        { label: "(GMT+06:00) Asia/Bishkek", value: "Asia/Bishkek" },
        { label: "(GMT+09:00) Asia/Chita", value: "Asia/Chita" },
        { label: "(GMT+08:00) Asia/Choibalsan", value: "Asia/Choibalsan" },
        { label: "(GMT+05:30) Asia/Colombo", value: "Asia/Colombo" },
        { label: "(GMT+03:00) Asia/Damascus", value: "Asia/Damascus" },
        { label: "(GMT+06:00) Asia/Dhaka", value: "Asia/Dhaka" },
        { label: "(GMT+09:00) Asia/Dili", value: "Asia/Dili" },
        { label: "(GMT+04:00) Asia/Dubai", value: "Asia/Dubai" },
        { label: "(GMT+05:00) Asia/Dushanbe", value: "Asia/Dushanbe" },
        { label: "(GMT+03:00) Asia/Famagusta", value: "Asia/Famagusta" },
        { label: "(GMT+03:00) Asia/Gaza", value: "Asia/Gaza" },
        { label: "(GMT+03:00) Asia/Hebron", value: "Asia/Hebron" },
        { label: "(GMT+07:00) Asia/Ho_Chi_Minh", value: "Asia/Ho_Chi_Minh" },
        { label: "(GMT+08:00) Asia/Hong_Kong", value: "Asia/Hong_Kong" },
        { label: "(GMT+07:00) Asia/Hovd", value: "Asia/Hovd" },
        { label: "(GMT+09:00) Asia/Irkutsk", value: "Asia/Irkutsk" },
        { label: "(GMT+07:00) Asia/Jakarta", value: "Asia/Jakarta" },
        { label: "(GMT+09:00) Asia/Jayapura", value: "Asia/Jayapura" },
        { label: "(GMT+03:00) Asia/Jerusalem", value: "Asia/Jerusalem" },
        { label: "(GMT+04:30) Asia/Kabul", value: "Asia/Kabul" },
        { label: "(GMT+12:00) Asia/Kamchatka", value: "Asia/Kamchatka" },
        { label: "(GMT+05:00) Asia/Karachi", value: "Asia/Karachi" },
        { label: "(GMT+05:45) Asia/Kathmandu", value: "Asia/Kathmandu" },
        { label: "(GMT+09:00) Asia/Khandyga", value: "Asia/Khandyga" },
        { label: "(GMT+05:30) Asia/Kolkata", value: "Asia/Kolkata" },
        { label: "(GMT+07:00) Asia/Krasnoyarsk", value: "Asia/Krasnoyarsk" },
        { label: "(GMT+08:00) Asia/Kuala_Lumpur", value: "Asia/Kuala_Lumpur" },
        { label: "(GMT+08:00) Asia/Kuching", value: "Asia/Kuching" },
        { label: "(GMT+08:00) Asia/Macau", value: "Asia/Macau" },
        { label: "(GMT+11:00) Asia/Magadan", value: "Asia/Magadan" },
        { label: "(GMT+08:00) Asia/Makassar", value: "Asia/Makassar" },
        { label: "(GMT+08:00) Asia/Manila", value: "Asia/Manila" },
        { label: "(GMT+03:00) Asia/Nicosia", value: "Asia/Nicosia" },
        { label: "(GMT+07:00) Asia/Novokuznetsk", value: "Asia/Novokuznetsk" },
        { label: "(GMT+07:00) Asia/Novosibirsk", value: "Asia/Novosibirsk" },
        { label: "(GMT+06:00) Asia/Omsk", value: "Asia/Omsk" },
        { label: "(GMT+05:00) Asia/Oral", value: "Asia/Oral" },
        { label: "(GMT+07:00) Asia/Pontianak", value: "Asia/Pontianak" },
        { label: "(GMT+08:30) Asia/Pyongyang", value: "Asia/Pyongyang" },
        { label: "(GMT+03:00) Asia/Qatar", value: "Asia/Qatar" },
        { label: "(GMT+06:00) Asia/Qostanay", value: "Asia/Qostanay" },
        { label: "(GMT+06:00) Asia/Qyzylorda", value: "Asia/Qyzylorda" },
        { label: "(GMT+03:00) Asia/Riyadh", value: "Asia/Riyadh" },
        { label: "(GMT+11:00) Asia/Sakhalin", value: "Asia/Sakhalin" },
        { label: "(GMT+05:00) Asia/Samarkand", value: "Asia/Samarkand" },
        { label: "(GMT+09:00) Asia/Seoul", value: "Asia/Seoul" },
        { label: "(GMT+08:00) Asia/Shanghai", value: "Asia/Shanghai" },
        { label: "(GMT+08:00) Asia/Singapore", value: "Asia/Singapore" },
        {
          label: "(GMT+11:00) Asia/Srednekolymsk",
          value: "Asia/Srednekolymsk"
        },
        { label: "(GMT+08:00) Asia/Taipei", value: "Asia/Taipei" },
        { label: "(GMT+05:00) Asia/Tashkent", value: "Asia/Tashkent" },
        { label: "(GMT+04:00) Asia/Tbilisi", value: "Asia/Tbilisi" },
        { label: "(GMT+03:30) Asia/Tehran", value: "Asia/Tehran" },
        { label: "(GMT+06:00) Asia/Thimphu", value: "Asia/Thimphu" },
        { label: "(GMT+09:00) Asia/Tokyo", value: "Asia/Tokyo" },
        { label: "(GMT+07:00) Asia/Tomsk", value: "Asia/Tomsk" },
        { label: "(GMT+08:00) Asia/Ulaanbaatar", value: "Asia/Ulaanbaatar" },
        { label: "(GMT+06:00) Asia/Urumqi", value: "Asia/Urumqi" },
        { label: "(GMT+10:00) Asia/Ust-Nera", value: "Asia/Ust-Nera" },
        { label: "(GMT+07:00) Asia/Vientiane", value: "Asia/Vientiane" },
        { label: "(GMT+10:00) Asia/Vladivostok", value: "Asia/Vladivostok" },
        { label: "(GMT+09:00) Asia/Yakutsk", value: "Asia/Yakutsk" },
        { label: "(GMT+06:30) Asia/Yangon", value: "Asia/Yangon" },
        {
          label: "(GMT+05:00) Asia/Yekaterinburg",
          value: "Asia/Yekaterinburg"
        },
        { label: "(GMT+04:00) Asia/Yerevan", value: "Asia/Yerevan" },
        { label: "(GMT-01:00) Atlantic/Azores", value: "Atlantic/Azores" },
        { label: "(GMT+00:00) Atlantic/Canary", value: "Atlantic/Canary" },
        {
          label: "(GMT-01:00) Atlantic/Cape_Verde",
          value: "Atlantic/Cape_Verde"
        },
        { label: "(GMT+00:00) Atlantic/Faroe", value: "Atlantic/Faroe" },
        { label: "(GMT+00:00) Atlantic/Madeira", value: "Atlantic/Madeira" },
        {
          label: "(GMT+00:00) Atlantic/Reykjavik",
          value: "Atlantic/Reykjavik"
        },
        {
          label: "(GMT-02:00) Atlantic/South_Georgia",
          value: "Atlantic/South_Georgia"
        },
        {
          label: "(GMT+00:00) Atlantic/St_Helena",
          value: "Atlantic/St_Helena"
        },
        {
          label: "(GMT+09:30) Australia/Adelaide",
          value: "Australia/Adelaide"
        },
        {
          label: "(GMT+10:00) Australia/Brisbane",
          value: "Australia/Brisbane"
        },
        {
          label: "(GMT+09:30) Australia/Broken_Hill",
          value: "Australia/Broken_Hill"
        },
        { label: "(GMT+10:00) Australia/Currie", value: "Australia/Currie" },
        { label: "(GMT+09:30) Australia/Darwin", value: "Australia/Darwin" },
        { label: "(GMT+08:45) Australia/Eucla", value: "Australia/Eucla" },
        { label: "(GMT+10:00) Australia/Hobart", value: "Australia/Hobart" },
        {
          label: "(GMT+10:00) Australia/Lindeman",
          value: "Australia/Lindeman"
        },
        {
          label: "(GMT+10:30) Australia/Lord_Howe",
          value: "Australia/Lord_Howe"
        },
        {
          label: "(GMT+10:00) Australia/Melbourne",
          value: "Australia/Melbourne"
        },
        { label: "(GMT+08:00) Australia/Perth", value: "Australia/Perth" },
        { label: "(GMT+10:00) Australia/Sydney", value: "Australia/Sydney" },
        { label: "(GMT+02:00) Europe/Amsterdam", value: "Europe/Amsterdam" },
        { label: "(GMT+02:00) Europe/Andorra", value: "Europe/Andorra" },
        { label: "(GMT+04:00) Europe/Astrakhan", value: "Europe/Astrakhan" },
        { label: "(GMT+03:00) Europe/Athens", value: "Europe/Athens" },
        { label: "(GMT+02:00) Europe/Belgrade", value: "Europe/Belgrade" },
        { label: "(GMT+02:00) Europe/Berlin", value: "Europe/Berlin" },
        { label: "(GMT+02:00) Europe/Bratislava", value: "Europe/Bratislava" },
        { label: "(GMT+02:00) Europe/Brussels", value: "Europe/Brussels" },
        { label: "(GMT+03:00) Europe/Bucharest", value: "Europe/Bucharest" },
        { label: "(GMT+02:00) Europe/Budapest", value: "Europe/Budapest" },
        { label: "(GMT+01:00) Europe/Busingen", value: "Europe/Busingen" },
        { label: "(GMT+03:00) Europe/Chisinau", value: "Europe/Chisinau" },
        { label: "(GMT+02:00) Europe/Copenhagen", value: "Europe/Copenhagen" },
        { label: "(GMT+01:00) Europe/Dublin", value: "Europe/Dublin" },
        { label: "(GMT+01:00) Europe/Gibraltar", value: "Europe/Gibraltar" },
        { label: "(GMT+01:00) Europe/Guernsey", value: "Europe/Guernsey" },
        { label: "(GMT+03:00) Europe/Helsinki", value: "Europe/Helsinki" },
        {
          label: "(GMT+01:00) Europe/Isle_of_Man",
          value: "Europe/Isle_of_Man"
        },
        { label: "(GMT+03:00) Europe/Istanbul", value: "Europe/Istanbul" },
        { label: "(GMT+01:00) Europe/Jersey", value: "Europe/Jersey" },
        {
          label: "(GMT+02:00) Europe/Kaliningrad",
          value: "Europe/Kaliningrad"
        },
        { label: "(GMT+03:00) Europe/Kiev", value: "Europe/Kiev" },
        { label: "(GMT+03:00) Europe/Kirov", value: "Europe/Kirov" },
        { label: "(GMT+01:00) Europe/Lisbon", value: "Europe/Lisbon" },
        { label: "(GMT+02:00) Europe/Ljubljana", value: "Europe/Ljubljana" },
        { label: "(GMT+01:00) Europe/London", value: "Europe/London" },
        { label: "(GMT+01:00) Europe/Luxembourg", value: "Europe/Luxembourg" },
        { label: "(GMT+02:00) Europe/Madrid", value: "Europe/Madrid" },
        { label: "(GMT+02:00) Europe/Malta", value: "Europe/Malta" },
        { label: "(GMT+03:00) Europe/Mariehamn", value: "Europe/Mariehamn" },
        { label: "(GMT+03:00) Europe/Minsk", value: "Europe/Minsk" },
        { label: "(GMT+01:00) Europe/Monaco", value: "Europe/Monaco" },
        { label: "(GMT+03:00) Europe/Moscow", value: "Europe/Moscow" },
        { label: "(GMT+02:00) Europe/Oslo", value: "Europe/Oslo" },
        { label: "(GMT+02:00) Europe/Paris", value: "Europe/Paris" },
        { label: "(GMT+02:00) Europe/Podgorica", value: "Europe/Podgorica" },
        { label: "(GMT+02:00) Europe/Prague", value: "Europe/Prague" },
        { label: "(GMT+03:00) Europe/Riga", value: "Europe/Riga" },
        { label: "(GMT+02:00) Europe/Rome", value: "Europe/Rome" },
        { label: "(GMT+04:00) Europe/Samara", value: "Europe/Samara" },
        { label: "(GMT+02:00) Europe/San_Marino", value: "Europe/San_Marino" },
        { label: "(GMT+02:00) Europe/Sarajevo", value: "Europe/Sarajevo" },
        { label: "(GMT+04:00) Europe/Saratov", value: "Europe/Saratov" },
        { label: "(GMT+03:00) Europe/Simferopol", value: "Europe/Simferopol" },
        { label: "(GMT+02:00) Europe/Skopje", value: "Europe/Skopje" },
        { label: "(GMT+03:00) Europe/Sofia", value: "Europe/Sofia" },
        { label: "(GMT+02:00) Europe/Stockholm", value: "Europe/Stockholm" },
        { label: "(GMT+03:00) Europe/Tallinn", value: "Europe/Tallinn" },
        { label: "(GMT+02:00) Europe/Tirane", value: "Europe/Tirane" },
        { label: "(GMT+04:00) Europe/Ulyanovsk", value: "Europe/Ulyanovsk" },
        { label: "(GMT+03:00) Europe/Uzhgorod", value: "Europe/Uzhgorod" },
        { label: "(GMT+02:00) Europe/Vaduz", value: "Europe/Vaduz" },
        { label: "(GMT+02:00) Europe/Vatican", value: "Europe/Vatican" },
        { label: "(GMT+02:00) Europe/Vienna", value: "Europe/Vienna" },
        { label: "(GMT+03:00) Europe/Vilnius", value: "Europe/Vilnius" },
        { label: "(GMT+04:00) Europe/Volgograd", value: "Europe/Volgograd" },
        { label: "(GMT+02:00) Europe/Warsaw", value: "Europe/Warsaw" },
        { label: "(GMT+02:00) Europe/Zagreb", value: "Europe/Zagreb" },
        { label: "(GMT+03:00) Europe/Zaporozhye", value: "Europe/Zaporozhye" },
        { label: "(GMT+02:00) Europe/Zurich", value: "Europe/Zurich" },
        {
          label: "(GMT+03:00) Indian/Antananarivo",
          value: "Indian/Antananarivo"
        },
        { label: "(GMT+06:00) Indian/Chagos", value: "Indian/Chagos" },
        { label: "(GMT+07:00) Indian/Christmas", value: "Indian/Christmas" },
        { label: "(GMT+06:30) Indian/Cocos", value: "Indian/Cocos" },
        { label: "(GMT+03:00) Indian/Comoro", value: "Indian/Comoro" },
        { label: "(GMT+05:00) Indian/Kerguelen", value: "Indian/Kerguelen" },
        { label: "(GMT+04:00) Indian/Mahe", value: "Indian/Mahe" },
        { label: "(GMT+05:00) Indian/Maldives", value: "Indian/Maldives" },
        { label: "(GMT+04:00) Indian/Mauritius", value: "Indian/Mauritius" },
        { label: "(GMT+03:00) Indian/Mayotte", value: "Indian/Mayotte" },
        { label: "(GMT+04:00) Indian/Reunion", value: "Indian/Reunion" },
        { label: "(GMT+13:00) Pacific/Apia", value: "Pacific/Apia" },
        { label: "(GMT+12:00) Pacific/Auckland", value: "Pacific/Auckland" },
        {
          label: "(GMT+11:00) Pacific/Bougainville",
          value: "Pacific/Bougainville"
        },
        { label: "(GMT+12:45) Pacific/Chatham", value: "Pacific/Chatham" },
        { label: "(GMT+10:00) Pacific/Chuuk", value: "Pacific/Chuuk" },
        { label: "(GMT-06:00) Pacific/Easter", value: "Pacific/Easter" },
        { label: "(GMT+11:00) Pacific/Efate", value: "Pacific/Efate" },
        { label: "(GMT+13:00) Pacific/Enderbury", value: "Pacific/Enderbury" },
        { label: "(GMT+13:00) Pacific/Fakaofo", value: "Pacific/Fakaofo" },
        { label: "(GMT+12:00) Pacific/Fiji", value: "Pacific/Fiji" },
        { label: "(GMT+12:00) Pacific/Funafuti", value: "Pacific/Funafuti" },
        { label: "(GMT-06:00) Pacific/Galapagos", value: "Pacific/Galapagos" },
        { label: "(GMT-09:00) Pacific/Gambier", value: "Pacific/Gambier" },
        {
          label: "(GMT+11:00) Pacific/Guadalcanal",
          value: "Pacific/Guadalcanal"
        },
        { label: "(GMT+10:00) Pacific/Guam", value: "Pacific/Guam" },
        { label: "(GMT-10:00) Pacific/Honolulu", value: "Pacific/Honolulu" },
        {
          label: "(GMT+14:00) Pacific/Kiritimati",
          value: "Pacific/Kiritimati"
        },
        { label: "(GMT+12:00) Pacific/Kosrae", value: "Pacific/Kosrae" },
        { label: "(GMT+12:00) Pacific/Kwajalein", value: "Pacific/Kwajalein" },
        { label: "(GMT+12:00) Pacific/Majuro", value: "Pacific/Majuro" },
        { label: "(GMT-09:30) Pacific/Marquesas", value: "Pacific/Marquesas" },
        { label: "(GMT-11:00) Pacific/Midway", value: "Pacific/Midway" },
        { label: "(GMT+12:00) Pacific/Nauru", value: "Pacific/Nauru" },
        { label: "(GMT-11:00) Pacific/Niue", value: "Pacific/Niue" },
        { label: "(GMT+11:00) Pacific/Norfolk", value: "Pacific/Norfolk" },
        { label: "(GMT+11:00) Pacific/Noumea", value: "Pacific/Noumea" },
        { label: "(GMT-11:00) Pacific/Pago_Pago", value: "Pacific/Pago_Pago" },
        { label: "(GMT+09:00) Pacific/Palau", value: "Pacific/Palau" },
        { label: "(GMT-08:00) Pacific/Pitcairn", value: "Pacific/Pitcairn" },
        { label: "(GMT+11:00) Pacific/Pohnpei", value: "Pacific/Pohnpei" },
        {
          label: "(GMT+10:00) Pacific/Port_Moresby",
          value: "Pacific/Port_Moresby"
        },
        { label: "(GMT-10:00) Pacific/Rarotonga", value: "Pacific/Rarotonga" },
        { label: "(GMT-10:00) Pacific/Tahiti", value: "Pacific/Tahiti" },
        { label: "(GMT+12:00) Pacific/Tarawa", value: "Pacific/Tarawa" },
        { label: "(GMT+13:00) Pacific/Tongatapu", value: "Pacific/Tongatapu" },
        { label: "(GMT+12:00) Pacific/Wake", value: "Pacific/Wake" },
        { label: "(GMT+12:00) Pacific/Wallis", value: "Pacific/Wallis" }
      ],
      newTaxes: [],
      copyFunnelID: [],
      formValues: {
        country: this.info.config.country,
        name: this.info.config.name,
        tagline: this.info.config.tagline,
        pixelID: this.info.config.pixelID,
        obv_link: this.info.config.obv_link,
        sw_link: this.info.config.sw_link,
        primaryCategory: this.info.config.primaryCategory,
        secondaryCategory: this.info.config.secondaryCategory,
        tags: this.info.config.tags,
        timezone: this.info.config.timezone,
        taxes: this.info.config.taxes || [],
        cf_event_url: this.info.config.cf_event_url || "",
        // Keap Values
        keap_language_custom_field_id:
          this.info.config.keap_language_custom_field_id || null,
        keap_company_custom_field_id:
          this.info.config.keap_company_custom_field_id || null,
        keap_holder_tag_id: this.info.config.keap_holder_tag_id || null,

        funnel_id: this.info.config.funnel_id || [],
        ticketsOrGuestList: "tickets",
        buyerOrPlannerPaysFees: this.info.config.buyerOrPlannerPaysFees,
        collectAttendeeInfo: this.info.config.collectAttendeeInfo,
        eventType: this.info.config.eventType,
        currency: this.eventCurrency(),
        languages: this.info.config.languages,
        seatsEventKey: this.info.config.seatsEventKey
      },
      keapTags: []
    };
  },
  computed: {
    secondaryCategoryDisabled() {
      return !this.formValues.primaryCategory;
    },
    secondaryCategoryShown() {
      return this.formValues.primaryCategory !== "Other";
    },
    showSeatsIoField() {
      return this.formValues.eventType == "RESERVED_SEATS";
    }
  },
  watch: {
    "formValues.primaryCategory": {
      handler: function(val) {
        if (val === "Live Music" || val === "DJ") {
          this.secondaryCategories = this.djLiveMusicOptions;
        } else if (val === "Corporate") {
          this.secondaryCategories = this.corporateOptions;
        } else if (val === "Sport") {
          this.secondaryCategories = this.sportsOptions;
        } else if (val === "Nightclub") {
          this.secondaryCategories = this.nightclibOptions;
        } else if (val === "Tourism") {
          this.secondaryCategories = this.tourismOptions;
        } else if (val === "Comedy") {
          this.secondaryCategories = this.comedyOptions;
        } else if (val === "Religious") {
          this.secondaryCategories = this.religiousOptions;
        } else if (val === "Cultural") {
          this.secondaryCategories = this.culturalOptions;
        } else if (val === "Other") {
          this.secondaryCategory = val;
          this.secondaryActive = false;
        }

        if (val !== "Other") {
          this.secondaryCategory = "";
        }
      },
      // we need to load the secondary categories every time
      immediate: true
    },
    "errors.config": {
      handler: function(val) {
        if (this.errors.config) {
          this.$refs.form.showErrors();
          this.$refs.form.$el
            .querySelector('[data-has-errors="true"]')
            .scrollIntoView({ block: "center" });
        }
      }
    },
    "formValues.eventType": function(newEventType) {
      if (newEventType === "HYBRID" || newEventType === "VIRTUAL") {
        this.formValues.collectAttendeeInfo = true;
        this.isCollectAttendeeInfoDisabled = true;
      } else {
        this.isCollectAttendeeInfoDisabled = false;
      }
    }
  },
  methods: {
    getKeapTags: debounce(function(val) {
      if (!val) {
        return;
      }
      this.$axios
        .post("/planners/get-keap-tags", {
          searchString: val
        })
        .then(response => {
          let tags = [];
          response.data.forEach(tag => {
            tags.push({ value: tag._id, label: tag.name });
          });
          this.keapTags = tags;
        })
        .catch(error => {});
    }, 300),

    selectEventType() {
      if (this.formValues.eventType == "RESERVED_SEATS") {
        this.$store.state.bus.$emit("alert", {
          title: "Heads Up!",
          msg:
            "You will first create your event and then once you have submitted all details, you will then design your floor plan."
        });
      }
    },
    disableFunnelId(index) {
      if (this.copyFunnelID[index] && this.copyFunnelID[index].id) {
        return true;
      }

      return false;
    },
    eventCurrency() {
      if (!this.info.config.currency) {
        return null;
      }
      if (this.info.config.currency.hasOwnProperty("label")) {
        return this.info.config.currency.value;
      } else {
        return this.info.config.currency;
      }
    },
    timeZone(input) {
      this.$set(this.info.config, "timezone", input.value);
    },
    selectCurrency(input) {
      this.$set(this.info.config, "currency", input.value);
    },
    selectCountry(input) {
      console.log(input);
      this.$set(this.info.config, "country", input.value);
    },
    checkBrandTaxForm(form) {
      if (form.type === undefined) {
        return false;
      }
      if (form.percentage === "" || form.percentage === undefined) {
        return false;
      }
      if (form.applicableTo.length === 0) {
        return false;
      }
      return true;
    },
    async submitBrandTax(index) {
      const brandtax = this.formValues.taxes[index];
      if (this.checkBrandTaxForm(brandtax)) {
        brandtax.percentage = Number(brandtax.percentage);
        try {
          await this.$axios.post(
            `/brand/${this.$store.state.activeBrand.subdomain}/taxes/create`,
            this.formValues.taxes[index]
          );
          this.newTaxes = [...this.newTaxes, brandtax];
          this.$toast("Successfully saved to your default brand taxes. ", {
            type: "success"
          });
        } catch (e) {
          console.log(e);
        }
      }
    },
    saveBrandTax(index) {
      const brandTax = this.formValues.taxes[index];
      for (const newBrandTax of this.newTaxes) {
        if (JSON.stringify(newBrandTax) === JSON.stringify(brandTax)) {
          return;
        }
      }
      return "Save as a Brand Tax.";
    },

    onAddTag() {
      const tag = this.tag.toLowerCase();
      if (!this.formValues.tags.includes(tag)) {
        this.formValues.tags.push(tag);
      }

      this.tag = "";
    },
    onRemoveTag(index) {
      this.formValues.tags.splice(index, 1);
    },
    onInput: debounce(function() {
      this.$emit(
        "data",
        "config",
        this.formValues,
        !this.$refs.form || this.$refs.form.hasErrors ? undefined : false
      );
    }, 300)
  },
  created() {
    this.copyFunnelID = JSON.parse(JSON.stringify(this.formValues.funnel_id));
  },
  async mounted() {
    // setTimeout(() => {
    //   console.log("kev", this.ticketsOrGuestList);
    // }, 2000);
    // TODO: If we ever want to add more timezones
    // this.timezones = Intl.supportedValuesOf("timeZone");

    try {
      const { data } = await this.$axios.get(
        `/brand/${this.$store.state.activeBrand.subdomain}/taxes`
      );
      for (const tax of data.taxes) {
        if (tax.active) {
          this.newTaxes.push({
            type: tax.type,
            applicableTo: tax.applicableTo,
            percentage: tax.percentage,
            description: tax.description
          });
        }
      }
    } catch (e) {
      console.log(e);
    }

    if (this.errors.config) {
      this.$refs.form.showErrors();
    }

    if (!this.formValues.timezone) {
      this.formValues.timezone = dayjs.tz.guess();
    }

    if (this.$store.state.user.seats_enabled) {
      this.eventTypes.push({
        label: "Reserved Seating",
        value: "RESERVED_SEATS"
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.form.hasErrors) {
    }

    this.$emit("data", "config", this.formValues, this.$refs.form.hasErrors);
    next();
  }
};
</script>

<style lang="less" scoped>
.green-star {
  color: var(--primary-green);
}

p {
  margin-bottom: 16px;
}

.tickets-guestlist-message {
  margin: 24px 0;
}

.ticketing-options-msg {
  margin-bottom: 24px;
}

.taxes {
  .formulate-input {
    margin: 0;
  }

  .col {
    &.type {
      width: calc(20% - 10px);
    }

    &.percentage {
      width: calc(15% - 10px);
    }

    &.description {
      width: calc(45% - 10px);
    }

    &.save-brand-tax {
      cursor: pointer;
      margin-bottom: 18px;
      font-size: 0.8em;
      font-weight: 300;
      text-align: end;
      color: var(--new-green);
    }

    @media screen and (max-width: 750px) {
      width: calc(50% - 10px) !important;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  gap: 8px;
  margin-top: 32px;

  .tag {
    display: inline-flex;
    height: fit-content;
    gap: 8px;
    align-items: center;
    color: var(--primary-green);
    padding: 0 8px;
    background: var(--input-background);
    font-size: 13px;
    border: 1px solid var(--input-border);
    border-radius: 4px;
    cursor: pointer;

    .remove {
      padding: 0 4px;
      line-height: 1.5;
      font-size: 8px;
      border: 1px solid var(--input-border);
      border-radius: 4px;
    }
  }
}
</style>
