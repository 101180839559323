<template>
  <div>
    <div class="sale-info-buttons">
      <font-awesome-icon
        icon="caret-left"
        class="sale-button prev"
        v-if="currentIndex != 0"
        v-on:click="swapSaleInfo(-1)"
      />
      <p class="sale-info-button-index" :id="'sale-info-index-' + indexOfTier">
        {{ currentIndex + 1 }}
      </p>
      <font-awesome-icon
        icon="caret-right"
        class="sale-button next"
        v-if="currentIndex != saleDates.length - 1"
        v-on:click="swapSaleInfo(1)"
      />
    </div>
    <p class="sale-info">
      {{ tierDate(saleDates[currentIndex].startDate) }} -
      {{ tierDate(saleDates[currentIndex].endDate) }}
    </p>
    <p class="sale-info">
      {{ dateTime(saleDates[currentIndex].startDate) }} -
      {{ dateTime(saleDates[currentIndex].endDate) }}
    </p>
    <p class="sale-tickets">
      # of Tickets:
      {{
        tier.type === "General Tier"
          ? saleDates[currentIndex].qty
          : tier.totalTicketQuantity
      }}
    </p>
  </div>
</template>

<style lang="less" scoped>
.sale-info-buttons {
  display: flex;
  justify-content: center;
  text-align: center;

  p.sale-info-button-index {
    height: fit-content;
    width: fit-content;
    margin: 0px !important;
    position: absolute;
  }

  .sale-button {
    //margin-top: 7px;
    color: #4cac55;
    font-size: 34px;
    z-index: 10;
    cursor: pointer;
  }
  .next {
    position: relative;
    bottom: 5px;
    left: 23px;
  }
  .prev {
    position: relative;
    right: 23px;
    bottom: 5px;
  }
}

.sale-info,
.sale-tickets {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0px;
}
p {
  font-size: 12px !important;
}
</style>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
import dayjs from "dayjs";
import UTC from "dayjs/plugin/utc";
import Timezones from "dayjs/plugin/timezone";

dayjs.extend(UTC);
dayjs.extend(Timezones);

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    info: Array,
    tier: Object,
    indexOfTier: Number
  },
  data() {
    return {
      currentIndex: 0,
      saleDates: [...this.info]
    };
  },
  methods: {
    swapSaleInfo(modifier) {
      if (this.currentIndex == 0) this.currentIndex++;
      else if (this.currentIndex + modifier > this.saleDates.length - 1) 1 + 1;
      else this.currentIndex += modifier;
    },
    unixTierDate(val) {
      return dateFormat(
        Date.parse(+val.$date.$numberLong),
        "ddd, mmm dS, yyyy"
      );
    },
    tierDate(val) {
      return dayjs
        .tz(dayjs(val).utc(), this.timezone)
        .format("ddd, MMM D, YYYY");
    },
    tierTime(val) {
      return dayjs.tz(dayjs(val).utc(), this.timezone).format("hh:mm A");
    },
    dateTime(val) {
      return dateFormat(Date.parse(val), "hh:MM TT");
    },
    time(val) {
      console.log(val);
      let time;
      let timeArr = val.split(":");

      if (parseInt(timeArr[0]) >= 12) {
        if (parseInt(timeArr[0]) == 12) {
          time = parseInt(timeArr[0]) + ":" + timeArr[1] + " PM";
        } else {
          time = parseInt(timeArr[0] - 12) + ":" + timeArr[1] + " PM";
        }
      } else {
        if (parseInt(timeArr[0]) == 0) {
          timeArr[0] = 12;
        }

        time = timeArr[0] + ":" + timeArr[1] + " AM";
      }

      return time;
    }
  }
};
</script>
