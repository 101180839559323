import { render, staticRenderFns } from "./8PlannerTerms.vue?vue&type=template&id=07515075&scoped=true&"
import script from "./8PlannerTerms.vue?vue&type=script&lang=js&"
export * from "./8PlannerTerms.vue?vue&type=script&lang=js&"
import style0 from "./8PlannerTerms.vue?vue&type=style&index=0&id=07515075&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07515075",
  null
  
)

export default component.exports