<template>
  <modal title="Report A Bug" :active="active" v-on:close="active = false">
    <FormulateForm
      name="report-bug"
      v-model="formValues"
      @submit="handleSubmit"
      #default="{isLoading}"
      autocomplete="false"
    >
      <FormulateInput
        type="text"
        name="issue"
        label="Issue"
        placeholder="What is the issue?"
        validation="required"
        autocomplete="false"
      />
      <FormulateInput
        type="textarea"
        name="description"
        label="Description"
        placeholder="Additional information (reproduction steps etc.)"
        validation="required"
        autocomplete="false"
      />
      <FormulateInput type="submit" name="Report Issue" :loading="isLoading" />
    </FormulateForm>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import ModalContent from "../../helpers/modals";

export default {
  name: "report-bug-modal",
  components: {
    Modal
  },
  data() {
    return {
      active: false,
      formValues: {
        issue: "",
        description: ""
      }
    };
  },
  methods: {
    open() {
      this.active = true;
    },
    async handleSubmit() {
      await this.$axios
        .post("/beta/report", {
          issue: this.formValues.issue,
          description: this.formValues.description
        })
        .then(() => {
          this.active = false;

          this.$root.$emit("alert", ModalContent.bugReport);
          this.$formulate.reset("report-bug");
        });
    }
  }
};
</script>
