<template>
  <div class="tier">
    <div class="tier-inner">
      <div class="form-row" v-if="type == 'General Tier'">
        <h3 class="date-text">
          Tier Info
          <!-- <pop-ups-for-icons
            :indexToUse="0"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'Basic Tier Information'"
          /> -->
        </h3>

        <div class="info-wrapper">
          <div class="form-col name">
            <diyobo-input
              type="text"
              label="Ticket Tier Name"
              placeholder="Ticket Tier Name"
              :required="true"
              v-model="name"
              :val="name"
              :error="error('name')"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="Price"
              placeholder="Price"
              :required="true"
              v-model="priceOutput"
              v-on:keydown="checkKeyPrice"
              :val="price != undefined ? price.toString() : ''"
              v-on:blur="formatMoney('price')"
              :error="error('price')"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="CC Fee"
              placeholder="CC Fee"
              v-model="ccFee"
              v-on:blur="formatMoney('ccFee')"
              :val="ccFee != undefined ? ccFee.toString() : ''"
              :disabled="true"
              tab-index="-1"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="T-Fee"
              placeholder="T Fee"
              v-model="dFee"
              v-on:blur="formatMoney('dFee')"
              :val="dFee != undefined ? dFee.toString() : ''"
              :disabled="true"
              tab-index="-1"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="Add. Fees"
              placeholder="Add. Fees"
              v-model="aFeeOutput"
              v-on:blur="formatMoney('aFee')"
              :val="aFee != undefined ? aFee.toString() : ''"
            />
          </div>
          <div class="form-col quaterx3 description">
            <diyobo-input
              type="text"
              label="Tier Description"
              placeholder="Add something to write about your tier."
              sublabel="Please leave a tier description that says something about your tier. 200 max characters."
              v-model="description"
              :val="description"
              :error="error('description')"
              :max="200"
              required
            />
          </div>
          <div class="form-col order">
            <diyobo-input
              type="number"
              label="Tix Start #"
              :id="`tix-start-num`"
              placeholder="Tix Start #"
              :required="true"
              :val="tixStartNum"
              v-model="tixStartNum"
              :error="error(`tixStartNum`)"
            />
          </div>
        </div>
        <h3 class="date-text">
          Purchase Availability
          <!-- <pop-ups-for-icons
            :indexToUse="1"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'This Section will let the coordinator determine their date/dates of sale for their tier.'"
          /> -->
        </h3>
        <div
          class="date-wrapper"
          v-for="(saleDate, index) in saleDateToEdit"
          :key="saleDate.id"
        >
          <div class="form-col date">
            <diyobo-input
              type="number"
              label="Quantity"
              :id="`sale-qty-${saleDate.id}`"
              placeholder="Quantity"
              :required="true"
              :val="saleDate.qty != undefined ? saleDate.qty.toString() : ''"
              v-on:blur="quantityFormat(`sale-qty-${saleDate.id}`, saleDate.id)"
              :error="error(`sale-qty-${saleDate.id}`)"
            />
          </div>
          <div class="form-col date">
            <diyobo-calendar
              label="Start Date"
              :pickerId="`start-date-${saleDate.id}`"
              :inputId="`start-date-${saleDate.id}-input`"
              :val="saleDate.startDate"
              :maxDate="eventEndDateToUse + ' ' + eventEndTime"
              :error="error(`sale-startDate-${saleDate.id}`)"
              v-on:updateDate="
                updateSaleDates('startDate', index, ...arguments)
              "
            ></diyobo-calendar>
          </div>
          <div class="form-col date">
            <diyobo-time-picker
              :required="true"
              label="Start Time"
              :val="saleDate.startTime"
              :timerWrapperId="`sale-start-time-${saleDate.id}`"
              v-on:updateTime="
                setTime(
                  'saleDateToEdit',
                  saleDate.id,
                  'startTime',
                  ...arguments
                )
              "
              :error="error(`sale-startTime-${saleDate.id}`)"
              inputClass="start-time"
            />
          </div>
          <div class="form-col date">
            <diyobo-calendar
              label="End Date"
              :pickerId="`end-date-${saleDate.id}`"
              :inputId="`end-date-${saleDate.id}-input`"
              :val="saleDate.endDate"
              :minDate="saleDate.startDate"
              :maxDate="eventEndDateToUse + ' ' + eventEndTime"
              :error="error(`sale-endDate-${saleDate.id}`)"
              v-on:updateDate="updateSaleDates('endDate', index, ...arguments)"
            ></diyobo-calendar>
          </div>
          <div class="form-col date">
            <diyobo-time-picker
              :required="true"
              label="End Time"
              :val="saleDate.endTime"
              :timerWrapperId="`sale-end-time-${saleDate.id}`"
              v-on:updateTime="
                setTime('saleDateToEdit', saleDate.id, 'endTime', ...arguments)
              "
              :error="error(`sale-endTime-${saleDate.id}`)"
              inputClass="end-time"
            />
          </div>
          <diyobo-checkbox
            v-if="edit"
            type="checkbox"
            label="Mark Sold Out"
            v-model="soldout"
            :checked="soldout"
          />
          <label
            v-if="index != 0"
            v-on:click="removeSaleDate(saleDate.id)"
            class="remove-date"
          >
            <font-awesome-icon icon="times" />
          </label>
        </div>
        <div class="label-wrapper">
          <label
            v-on:click="addSaleDate"
            class="add-date"
            :saleDateMsg="saleDateMsg"
          >
            {{ saleDateMsg }}
          </label>
        </div>
        <h3 class="availability-text">
          Venue Access
          <!-- <pop-ups-for-icons
            :indexToUse="2"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            popUpMsg="Here you can specify the date and time window that ticket buyers can access the venue."
          /> -->
          <p style="color: var(--text)">
            Please select the dates and times as they pertain to when you start
            letting people into the event and when you will stop allowing entry.
          </p>
        </h3>
        <div class="availability-checkbox">
          <diyobo-checkbox
            type="checkbox"
            :label="venueAccessEventMsg"
            v-model="sameAsEventDate"
            :checked="sameAsEventDate"
          />
        </div>
        <div class="availability-wrapper">
          <div class="availability-dates">
            <div class="start-date">
              <diyobo-calendar
                label="Start Date"
                pickerId="start-Date-venue"
                inputId="start-Date-venue-Input"
                :val="venueAccess.startDate"
                :excludedDates="excludedDatesToUse"
                :error="error(`venueAccessStartDate`)"
                v-on:updateDate="updateVenueStartDate"
              ></diyobo-calendar>
            </div>
            <div class="end-date">
              <diyobo-calendar
                label="End Date"
                pickerId="end-Date-venue"
                inputId="end-Date-venue-Input"
                :val="venueAccess.endDate"
                :minDate="
                  venueAccess.startDate
                    ? venueAccess.startDate
                    : eventStartDateToUse
                "
                :excludedDates="excludedDatesToUse"
                :maxDate="eventEndDateToUse"
                :error="error(`venueAccessEndDate`)"
                v-on:updateDate="updateVenueEndDate"
              ></diyobo-calendar>
            </div>
          </div>
          <div class="availability-times">
            <div class="start-time">
              <diyobo-time-picker
                :required="true"
                label="Start Time"
                :val="venueAccess.startTime"
                timerWrapperId="venue-start-time"
                v-on:updateTime="
                  setTime('venueAccess', 0, 'startTime', ...arguments)
                "
                :error="error(`venueAccessStartTime`)"
                inputClass="start-time"
              />
            </div>
            <div class="end-time">
              <diyobo-time-picker
                :required="true"
                label="End Time"
                :val="venueAccess.endTime"
                timerWrapperId="venue-end-time"
                v-on:updateTime="
                  setTime('venueAccess', 0, 'endTime', ...arguments)
                "
                :error="error(`venueAccessEndTime`)"
                inputClass="end-time"
              />
            </div>
          </div>
        </div>
        <div class="password-wrapper">
          <diyobo-checkbox
            type="checkbox"
            label="Would you like to add a password to this tier?"
            v-model="isThereAPassword"
            :checked="isThereAPassword"
          />
          <div class="form-col password" v-if="isThereAPassword">
            <diyobo-input
              type="text"
              label="Enter Password"
              :placeholder="'Password'"
              v-model="tierPassword"
              :val="tierPassword"
              :error="error(`tierPassword`)"
              :requried="true"
            />
          </div>
        </div>
      </div>
      <div class="form-row" v-if="type == 'Time'">
        <h3 class="entry-text">
          Tier Timing
          <!-- <pop-ups-for-icons
            :indexToUse="3"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'"
          /> -->
        </h3>
        <div
          class="date-wrapper"
          v-for="(time, index) in entryTimeArray"
          :key="(index + 100) * 10"
        >
          <div class="form-col startDate">
            <diyobo-time-picker
              :required="true"
              label="Start Time"
              :val="time.startTime"
              :timerWrapperId="`entry-start-time-${time.id}`"
              v-on:updateTime="
                setTime('entryTimeArray', time.id, 'startTime', ...arguments)
              "
              :error="error(`entry-startTime-${time.id}`)"
              inputClass="start-time"
            />
          </div>
          <div class="form-col endDate">
            <diyobo-time-picker
              :required="true"
              label="End Time"
              :val="time.endTime"
              :timerWrapperId="`entry-end-time-${time.id}`"
              v-on:updateTime="
                setTime('entryTimeArray', time.id, 'endTime', ...arguments)
              "
              :error="error(`entry-endTime-${time.id}`)"
              inputClass="end-time"
            />
          </div>
          <label
            v-if="time.id != 0"
            v-on:click="removeEntryDate(time.id)"
            class="remove-date"
            :entryTimeArrayMsg="entryTimeArrayMsg"
          >
            <font-awesome-icon icon="times" />
          </label>
        </div>
        <div class="label-wrapper">
          <label
            v-on:click="addEntryTime"
            class="add-date"
            :entryTimeArrayMsg="entryTimeArrayMsg"
          >
            {{ entryTimeArrayMsg }}
          </label>
        </div>
        <h3 class="date-text">
          Tier Info
          <!-- <pop-ups-for-icons
            :indexToUse="0"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'Basic Tier Information'"
          /> -->
        </h3>
        <div class="info-wrapper">
          <div class="form-col name">
            <diyobo-input
              type="text"
              label="Ticket Tier Name"
              placeholder="Ticket Tier Name"
              :required="true"
              v-model="name"
              :val="name"
              :error="error('name')"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="Price"
              placeholder="Price"
              :required="true"
              v-model="priceOutput"
              v-on:keydown="checkKeyPrice"
              :val="price != undefined ? price.toString() : ''"
              v-on:blur="formatMoney('price')"
              :error="error('price')"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="CC Fee"
              placeholder="CC Fee"
              v-model="ccFee"
              v-on:blur="formatMoney('ccFee')"
              :val="ccFee != undefined ? ccFee.toString() : ''"
              :disabled="true"
              tab-index="-1"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="T-Fee"
              placeholder="T Fee"
              v-model="dFee"
              v-on:blur="formatMoney('dFee')"
              :val="dFee != undefined ? dFee.toString() : ''"
              :disabled="true"
              tab-index="-1"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="Add. Fees"
              placeholder="Add. Fees"
              v-model="aFeeOutput"
              v-on:blur="formatMoney('aFee')"
              :val="aFee != undefined ? aFee.toString() : ''"
            />
          </div>
          <div class="form-col quaterx3 description">
            <diyobo-input
              type="text"
              label="Tier Description"
              placeholder="Add something to write about your tier."
              v-model="description"
              :val="description"
              :error="error('description')"
              :max="200"
            />
            <p class="tier-info">
              Please Leave a tier description that says something about your
              tier. 200 max characters.
            </p>
          </div>
          <div class="form-col order">
            <diyobo-input
              type="number"
              label="Tix Start #"
              :id="`tix-start-num`"
              placeholder="Tix Start #"
              :required="true"
              :val="tixStartNum"
              v-model="tixStartNum"
              :error="error(`tixStartNum`)"
            />
          </div>
        </div>
        <h3 class="date-text">
          Purchase Availability
          <!-- <pop-ups-for-icons
            :indexToUse="1"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'This Section will let the coordinator determine their date/dates of sale for their tier.'"
          /> -->
        </h3>
        <div
          class="date-wrapper"
          v-for="(saleDate, index) in saleDateToEdit"
          :key="saleDate.id"
        >
          <div class="form-col date">
            <diyobo-input
              type="number"
              label="Quantity"
              :id="`sale-qty-${saleDate.id}`"
              placeholder="Quantity"
              :required="true"
              :val="saleDate.qty != undefined ? saleDate.qty.toString() : ''"
              v-on:blur="quantityFormat(`sale-qty-${saleDate.id}`, saleDate.id)"
              :error="error(`sale-qty-${saleDate.id}`)"
            />
          </div>
          <div class="form-col date">
            <diyobo-calendar
              label="Start Date"
              :pickerId="`start-date-${saleDate.id}`"
              :inputId="`start-date-${saleDate.id}-input`"
              :val="saleDate.startDate"
              :maxDate="eventEndDateToUse"
              :error="error(`sale-startDate-${saleDate.id}`)"
              v-on:updateDate="
                updateSaleDates('startDate', index, ...arguments)
              "
            ></diyobo-calendar>
          </div>
          <div class="form-col date">
            <diyobo-time-picker
              :required="true"
              label="Start Time"
              :val="saleDate.startTime"
              :timerWrapperId="`sale-start-time-${saleDate.id}`"
              v-on:updateTime="
                setTime(
                  'saleDateToEdit',
                  saleDate.id,
                  'startTime',
                  ...arguments
                )
              "
              :error="error(`sale-startTime-${saleDate.id}`)"
              inputClass="start-time"
            />
          </div>
          <div class="form-col date">
            <diyobo-calendar
              label="End Date"
              :pickerId="`end-date-${saleDate.id}`"
              :inputId="`end-date-${saleDate.id}-input`"
              :val="saleDate.endDate"
              :minDate="saleDate.startDate"
              :maxDate="eventEndDateToUse"
              :error="error(`sale-endDate-${saleDate.id}`)"
              v-on:updateDate="updateSaleDates('endDate', index, ...arguments)"
            ></diyobo-calendar>
          </div>
          <div class="form-col date">
            <diyobo-time-picker
              :required="true"
              label="End Time"
              :val="saleDate.endTime"
              :timerWrapperId="`sale-end-time-${saleDate.id}`"
              v-on:updateTime="
                setTime('saleDateToEdit', saleDate.id, 'endTime', ...arguments)
              "
              :error="error(`sale-endTime-${saleDate.id}`)"
              inputClass="end-time"
            />
          </div>
          <label
            v-if="index != 0"
            v-on:click="removeSaleDate(saleDate.id)"
            class="remove-date"
          >
            <font-awesome-icon icon="times" />
          </label>
        </div>
        <div class="label-wrapper">
          <label
            v-on:click="addSaleDate"
            class="add-date"
            :saleDateMsg="saleDateMsg"
          >
            {{ saleDateMsg }}
          </label>
        </div>
        <h3 class="availability-text">
          Venue Access
          <!-- <pop-ups-for-icons
            :indexToUse="2"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'"
          /> -->
        </h3>
        <div class="availability-wrapper">
          <div class="availability-checkbox">
            <diyobo-checkbox
              type="checkbox"
              :label="venueAccessEventMsg"
              v-model="sameAsEventDate"
              :checked="sameAsEventDate"
            />
          </div>
          <div class="availability-times">
            <div class="start-time">
              <diyobo-time-picker
                :required="true"
                label="Start Time"
                :val="venueAccess.startTime"
                timerWrapperId="venue-start-time"
                v-on:updateTime="
                  setTime('venueAccess', 0, 'startTime', ...arguments)
                "
                :error="error(`venueAccessStartTime`)"
                inputClass="start-time"
              />
            </div>
            <div class="end-time">
              <diyobo-time-picker
                :required="true"
                label="End Time"
                :val="venueAccess.endTime"
                timerWrapperId="venue-end-time"
                v-on:updateTime="
                  setTime('venueAccess', 0, 'endTime', ...arguments)
                "
                :error="error(`venueAccessEndTime`)"
                inputClass="end-time"
              />
            </div>
          </div>
        </div>
        <div class="password-wrapper">
          <diyobo-checkbox
            type="checkbox"
            label="Would you like to add a password to this tier?"
            v-model="isThereAPassword"
            :checked="isThereAPassword"
          />
          <div class="form-col password" v-if="isThereAPassword">
            <diyobo-input
              type="text"
              label="Enter Password"
              :placeholder="'Password'"
              v-model="tierPassword"
              :val="tierPassword"
              :error="error(`tierPassword`)"
              :requried="true"
            />
          </div>
        </div>
      </div>
      <div class="form-row" v-if="type == 'Date'">
        <h3 class="date-text">
          Tier Dates
          <!-- <pop-ups-for-icons
            :indexToUse="0"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'Select your event dates here'"
          /> -->
        </h3>
        <div class="info-wrapper">
          <div class="form-col calendar">
            <calendar-set
              :eventStartDate="eventStartDateToUse"
              :eventEndDate="eventEndDateToUse"
              :savedDates="savedDates"
              :reApplySavedDates="editIndex"
              :excludedDates="excludedDatesToUse"
              :error="error('savedDates')"
              :toggle="true"
            />
          </div>
        </div>
        <h3 class="date-text">
          Tier Info
          <!-- <pop-ups-for-icons
            :indexToUse="0"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'Basic Tier Info'"
          /> -->
        </h3>
        <div class="info-wrapper">
          <div class="form-col name">
            <diyobo-input
              type="text"
              label="Ticket Tier Name"
              placeholder="Ticket Tier Name"
              :required="true"
              v-model="name"
              :val="name"
              :error="error('name')"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="Price"
              placeholder="Price"
              :required="true"
              v-model="priceOutput"
              v-on:keydown="checkKeyPrice"
              :val="price != undefined ? price.toString() : ''"
              v-on:blur="formatMoney('price')"
              :error="error('price')"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="CC Fee"
              placeholder="CC Fee"
              v-model="ccFee"
              v-on:blur="formatMoney('ccFee')"
              :val="ccFee != undefined ? ccFee.toString() : ''"
              :disabled="true"
              tab-index="-1"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="T-Fee"
              placeholder="T Fee"
              v-model="dFee"
              v-on:blur="formatMoney('dFee')"
              :val="dFee != undefined ? dFee.toString() : ''"
              :disabled="true"
              tab-index="-1"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="Add. Fees"
              placeholder="Add. Fees"
              v-model="aFeeOutput"
              v-on:blur="formatMoney('aFee')"
              :val="aFee != undefined ? aFee.toString() : ''"
            />
          </div>
          <div class="form-col quaterx3 description">
            <diyobo-input
              type="text"
              label="Tier Description"
              placeholder="Add something to write about your tier."
              v-model="description"
              :val="description"
              :error="error('description')"
              :max="200"
            />
            <p class="tier-info">
              Please Leave a tier description that says something about your
              tier. 200 max characters.
            </p>
          </div>
          <div class="form-col order">
            <diyobo-input
              type="number"
              label="Tix Start #"
              :id="`tix-start-num`"
              placeholder="Tix Start #"
              :required="true"
              :val="tixStartNum"
              v-model="tixStartNum"
              :error="error(`tixStartNum`)"
            />
          </div>
        </div>
        <h3 class="date-text">
          Purchase Availability
          <!-- <pop-ups-for-icons
            :indexToUse="1"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'This Section will let the coordinator determine their date/dates of sale for their tier.'"
          /> -->
        </h3>
        <div
          class="date-wrapper"
          v-for="(saleDate, index) in saleDateToEdit"
          :key="saleDate.id"
        >
          <div class="form-col date">
            <diyobo-input
              type="number"
              label="Quantity"
              :id="`sale-qty-${saleDate.id}`"
              placeholder="Quantity"
              :required="true"
              :val="saleDate.qty != undefined ? saleDate.qty.toString() : ''"
              v-on:blur="quantityFormat(`sale-qty-${saleDate.id}`, saleDate.id)"
              :error="error(`sale-qty-${saleDate.id}`)"
            />
          </div>
          <div class="form-col date">
            <diyobo-calendar
              label="Start Date"
              :pickerId="`start-date-${saleDate.id}`"
              :inputId="`start-date-${saleDate.id}-input`"
              :val="saleDate.startDate"
              :maxDate="eventEndDateToUse"
              :error="error(`sale-startDate-${saleDate.id}`)"
              v-on:updateDate="
                updateSaleDates('startDate', index, ...arguments)
              "
            ></diyobo-calendar>
          </div>
          <div class="form-col date">
            <diyobo-time-picker
              :required="true"
              label="Start Time"
              :val="saleDate.startTime"
              :timerWrapperId="`sale-start-time-${saleDate.id}`"
              v-on:updateTime="
                setTime(
                  'saleDateToEdit',
                  saleDate.id,
                  'startTime',
                  ...arguments
                )
              "
              :error="error(`sale-startTime-${saleDate.id}`)"
              inputClass="start-time"
            />
          </div>
          <div class="form-col date">
            <diyobo-calendar
              label="End Date"
              :pickerId="`end-date-${saleDate.id}`"
              :inputId="`end-date-${saleDate.id}-input`"
              :val="saleDate.endDate"
              :minDate="saleDate.startDate"
              :maxDate="eventEndDateToUse"
              :error="error(`sale-endDate-${saleDate.id}`)"
              v-on:updateDate="updateSaleDates('endDate', index, ...arguments)"
            ></diyobo-calendar>
          </div>
          <div class="form-col date">
            <diyobo-time-picker
              :required="true"
              label="End Time"
              :val="saleDate.endTime"
              :timerWrapperId="`sale-end-time-${saleDate.id}`"
              v-on:updateTime="
                setTime('saleDateToEdit', saleDate.id, 'endTime', ...arguments)
              "
              :error="error(`sale-endTime-${saleDate.id}`)"
              inputClass="end-time"
            />
          </div>
          <label
            v-if="index != 0"
            v-on:click="removeSaleDate(saleDate.id)"
            class="remove-date"
          >
            <font-awesome-icon icon="times" />
          </label>
        </div>
        <div class="label-wrapper">
          <label
            v-on:click="addSaleDate"
            class="add-date"
            :saleDateMsg="saleDateMsg"
          >
            {{ saleDateMsg }}
          </label>
        </div>
        <h3 class="availability-text">
          Venue Access
          <!-- <pop-ups-for-icons
            :indexToUse="2"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'"
          /> -->
        </h3>
        <div class="availability-checkbox">
          <diyobo-checkbox
            type="checkbox"
            :label="venueAccessEventMsg"
            v-model="sameAsEventDate"
            :checked="sameAsEventDate"
          />
        </div>
        <div class="availability-wrapper">
          <div class="availability-times">
            <div class="start-time">
              <diyobo-time-picker
                :required="true"
                label="Start Time"
                :val="venueAccess.startTime"
                timerWrapperId="venue-start-time"
                v-on:updateTime="
                  setTime('venueAccess', 0, 'startTime', ...arguments)
                "
                :error="error(`venueAccessStartTime`)"
                inputClass="start-time"
              />
            </div>
            <div class="end-time">
              <diyobo-time-picker
                :required="true"
                label="End Time"
                :val="venueAccess.endTime"
                timerWrapperId="venue-end-time"
                v-on:updateTime="
                  setTime('venueAccess', 0, 'endTime', ...arguments)
                "
                :error="error(`venueAccessEndTime`)"
                inputClass="end-time"
              />
            </div>
          </div>
        </div>
        <div class="password-wrapper">
          <diyobo-checkbox
            type="checkbox"
            label="Would you like to add a password to this tier?"
            v-model="isThereAPassword"
            :checked="isThereAPassword"
          />
          <div class="form-col password" v-if="isThereAPassword">
            <diyobo-input
              type="text"
              label="Enter Password"
              :placeholder="'Password'"
              v-model="tierPassword"
              :val="tierPassword"
              :error="error(`tierPassword`)"
              :requried="true"
            />
          </div>
        </div>
      </div>
      <div class="form-row" v-if="type == 'Date and Time'">
        <h3 class="date-text">
          Tier Dates
          <!-- <pop-ups-for-icons
            :indexToUse="0"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'Select your event dates here'"
          /> -->
        </h3>
        <div class="info-wrapper">
          <div class="form-col calendar">
            <calendar-set
              :eventStartDate="eventStartDateToUse"
              :eventEndDate="eventEndDateToUse"
              :savedDates="savedDates"
              :excludedDates="excludedDatesToUse"
              :reApplySavedDates="editIndex"
              :error="error('savedDates')"
              :toggle="true"
            />
          </div>
        </div>
        <h3 class="entry-text">
          Select a Specific Time for Your Tier Dates
          <!-- <pop-ups-for-icons
            :indexToUse="3"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'"
          /> -->
        </h3>
        <div
          class="date-wrapper"
          v-for="(time, index) in entryTimeArray"
          :key="(index + 100) * 10"
        >
          <div class="form-col startDate">
            <diyobo-time-picker
              :required="true"
              label="Start Time"
              :val="time.startTime"
              :timerWrapperId="`entry-start-time-${time.id}`"
              v-on:updateTime="
                setTime('entryTimeArray', time.id, 'startTime', ...arguments)
              "
              :error="error(`entry-startTime-${time.id}`)"
              inputClass="start-time"
            />
          </div>
          <div class="form-col endDate">
            <diyobo-time-picker
              :required="true"
              label="End Time"
              :val="time.endTime"
              :timerWrapperId="`entry-end-time-${time.id}`"
              v-on:updateTime="
                setTime('entryTimeArray', time.id, 'endTime', ...arguments)
              "
              :error="error(`entry-endTime-${time.id}`)"
              inputClass="end-time"
            />
          </div>
          <label
            v-if="time.id != 0"
            v-on:click="removeEntryDate(time.id)"
            class="remove-date"
            :entryTimeArrayMsg="entryTimeArrayMsg"
          >
            <font-awesome-icon icon="times" />
          </label>
        </div>
        <div class="label-wrapper">
          <label
            v-on:click="addEntryTime"
            class="add-date"
            :entryTimeArrayMsg="entryTimeArrayMsg"
          >
            {{ entryTimeArrayMsg }}
          </label>
        </div>
        <h3 class="date-text">
          Tier Info
          <!-- <pop-ups-for-icons
            :indexToUse="0"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'Basic Tier Information'"
          /> -->
        </h3>
        <div class="info-wrapper">
          <div class="form-col name">
            <diyobo-input
              type="text"
              label="Ticket Tier Name"
              placeholder="Ticket Tier Name"
              :required="true"
              v-model="name"
              :val="name"
              :error="error('name')"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="Price"
              placeholder="Price"
              :required="true"
              v-model="priceOutput"
              v-on:keydown="checkKeyPrice"
              :val="price != undefined ? price.toString() : ''"
              v-on:blur="formatMoney('price')"
              :error="error('price')"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="CC Fee"
              placeholder="CC Fee"
              v-model="ccFee"
              v-on:blur="formatMoney('ccFee')"
              :val="ccFee != undefined ? ccFee.toString() : ''"
              :disabled="true"
              tab-index="-1"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="T-Fee"
              placeholder="T Fee"
              v-model="dFee"
              v-on:blur="formatMoney('dFee')"
              :val="dFee != undefined ? dFee.toString() : ''"
              :disabled="true"
              tab-index="-1"
            />
          </div>
          <div class="form-col fee">
            <diyobo-input
              type="money"
              label="Add. Fees"
              placeholder="Add. Fees"
              v-model="aFeeOutput"
              v-on:blur="formatMoney('aFee')"
              :val="aFee != undefined ? aFee.toString() : ''"
            />
          </div>
          <div class="form-col quaterx3 description">
            <diyobo-input
              type="text"
              label="Tier Description"
              placeholder="Add something to write about your tier."
              v-model="description"
              :val="description"
              :error="error('description')"
              :max="200"
            />
            <p class="tier-info">
              Please Leave a tier description that says something about your
              tier. 200 max characters.
            </p>
          </div>
          <div class="form-col order">
            <diyobo-input
              type="number"
              label="Tix Start #"
              :id="`tix-start-num`"
              placeholder="Tix Start #"
              :required="true"
              :val="tixStartNum"
              v-model="tixStartNum"
              :error="error(`tixStartNum`)"
            />
          </div>
        </div>
        <h3 class="date-text">
          Purchase Availability
          <!-- <pop-ups-for-icons
            :indexToUse="1"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'This Section will let the coordinator determine their date/dates of sale for their tier.'"
          /> -->
        </h3>
        <div
          class="date-wrapper"
          v-for="(saleDate, index) in saleDateToEdit"
          :key="saleDate.id"
        >
          <div class="form-col date">
            <diyobo-input
              type="number"
              label="Quantity"
              :id="`sale-qty-${saleDate.id}`"
              placeholder="Quantity"
              :required="true"
              :val="saleDate.qty != undefined ? saleDate.qty.toString() : ''"
              v-on:blur="quantityFormat(`sale-qty-${saleDate.id}`, saleDate.id)"
              :error="error(`sale-qty-${saleDate.id}`)"
            />
          </div>
          <div class="form-col date">
            <diyobo-calendar
              label="Start Date"
              :pickerId="`start-date-${saleDate.id}`"
              :inputId="`start-date-${saleDate.id}-input`"
              :val="saleDate.startDate"
              :maxDate="eventEndDateToUse"
              :error="error(`sale-startDate-${saleDate.id}`)"
              v-on:updateDate="
                updateSaleDates('startDate', index, ...arguments)
              "
            ></diyobo-calendar>
          </div>
          <div class="form-col date">
            <diyobo-time-picker
              :required="true"
              label="Start Time"
              :val="saleDate.startTime"
              :timerWrapperId="`sale-start-time-${saleDate.id}`"
              v-on:updateTime="
                setTime(
                  'saleDateToEdit',
                  saleDate.id,
                  'startTime',
                  ...arguments
                )
              "
              :error="error(`sale-startTime-${saleDate.id}`)"
              inputClass="start-time"
            />
          </div>
          <div class="form-col date">
            <diyobo-calendar
              label="End Date"
              :pickerId="`end-date-${saleDate.id}`"
              :inputId="`end-date-${saleDate.id}-input`"
              :val="saleDate.endDate"
              :minDate="saleDate.startDate"
              :maxDate="eventEndDateToUse"
              :error="error(`sale-endDate-${saleDate.id}`)"
              v-on:updateDate="updateSaleDates('endDate', index, ...arguments)"
            ></diyobo-calendar>
          </div>
          <div class="form-col date">
            <diyobo-time-picker
              :required="true"
              label="End Time"
              :val="saleDate.endTime"
              :timerWrapperId="`sale-end-time-${saleDate.id}`"
              v-on:updateTime="
                setTime('saleDateToEdit', saleDate.id, 'endTime', ...arguments)
              "
              :error="error(`sale-endTime-${saleDate.id}`)"
              inputClass="end-time"
            />
          </div>
          <label
            v-if="index != 0"
            v-on:click="removeSaleDate(saleDate.id)"
            class="remove-date"
          >
            <font-awesome-icon icon="times" />
          </label>
        </div>
        <div class="label-wrapper">
          <label
            v-on:click="addSaleDate"
            class="add-date"
            :saleDateMsg="saleDateMsg"
          >
            {{ saleDateMsg }}
          </label>
        </div>
        <h3 class="availability-text">
          Venue Access
          <!-- <pop-ups-for-icons
            :indexToUse="2"
            :popUpIcon="'question-circle'"
            :popUpText="''"
            :popUpMsg="'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'"
          /> -->
        </h3>
        <div class="availability-checkbox">
          <diyobo-checkbox
            type="checkbox"
            :label="venueAccessEventMsg"
            v-model="sameAsEventDate"
            :checked="sameAsEventDate"
          />
        </div>
        <div class="availability-wrapper">
          <div class="availability-times">
            <div class="start-time">
              <diyobo-time-picker
                :required="true"
                label="Start Time"
                :val="venueAccess.startTime"
                timerWrapperId="venue-start-time"
                v-on:updateTime="
                  setTime('venueAccess', 0, 'startTime', ...arguments)
                "
                :error="error(`venueAccessStartTime`)"
                inputClass="start-time"
              />
            </div>
            <div class="end-time">
              <diyobo-time-picker
                :required="true"
                label="End Time"
                :val="venueAccess.endTime"
                timerWrapperId="venue-end-time"
                v-on:updateTime="
                  setTime('venueAccess', 0, 'endTime', ...arguments)
                "
                :error="error(`venueAccessEndTime`)"
                inputClass="end-time"
              />
            </div>
          </div>
        </div>
        <div class="password-wrapper">
          <diyobo-checkbox
            type="checkbox"
            label="Would you like to add a password to this tier?"
            v-model="isThereAPassword"
            :checked="isThereAPassword"
          />
          <div class="form-col password" v-if="isThereAPassword">
            <diyobo-input
              type="text"
              label="Enter Password"
              :placeholder="'Password'"
              v-model="tierPassword"
              :val="tierPassword"
              :error="error(`tierPassword`)"
              :requried="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="add-tier-wrapper"
      v-if="
        type == 'General Tier' ||
        type == 'Date' ||
        type == 'Date and Time' ||
        type == 'Time'
      "
    >
      <diyobo-button
        type="primary"
        v-if="tierData.id == 'initial'"
        txt="Add Tier"
        v-on:click="addTier"
        icon="plus"
      />
      <diyobo-button
        type="primary"
        txt="Add Another Tier"
        v-on:click="addTier"
        v-if="tierData.id == 'addition'"
        icon="plus"
      />
      <diyobo-button
        type="primary"
        txt="Update Tier"
        v-on:click="updateTier"
        v-if="tierData.id != 'addition' && tierData.id != 'initial'"
        icon="plus"
      />
      <!-- <button
        class="add-itinerary"
        v-on:click="addTier"
        v-if="tierData.id == 'initial'"
      >
        <font-awesome-icon class="icon-size" icon="plus" />Add Tier
      </button> -->
      <!-- <button
        class="add-itinerary"
       
      >
        <font-awesome-icon class="icon-size" icon="plus" />
      </button>
      <button
        class="add-itinerary"
        v-on:click="updateTier"
        v-if="tierData.id != 'addition' && tierData.id != 'initial'"
      >
        <font-awesome-icon class="icon-size" icon="plus" />Update Tier
      </button> -->
    </div>
  </div>
</template>

<style lang="less" scoped>
.tier {
  label {
    font-size: 0.98em;
  }
  .tier-inner {
    // overflow: hidden;
    position: relative;
    padding: 4px 8px;
    margin: 20px 0px;
    border: 1px solid var(--dashboard-border);
    border-radius: 5px;
    box-shadow: var(--table-shadow);

    .tier-info {
      color: #999999;
      //padding-bottom: 35px;
      //padding-left: 15px;
      font-size: 12px !important;
    }
    .description {
      .input-wrapper {
        margin: 0px !important;
      }
    }
    .form-row {
      display: flex;
      flex-wrap: wrap;
      padding: 25px 16px 0px;
      .form-col {
        margin: 0.25em;
        width: 100%;

        .date {
          display: flex;
          position: relative;
          width: 100%;
          .placeholder {
            position: absolute;
            top: 12px;
            left: 14px;
            color: #878787;
          }
          // end placeholder
          .icon-calender {
            position: absolute;
            top: 12px;
            right: 14px;
            color: #878787;
          }
          // end icon-calender
          .edit-icon {
            height: 22px;
            width: 22px;
            margin-top: 4px;
          }
          input[type="date"],
          textarea {
            display: block;
            padding: 11px 0px;
            text-indent: 14px;
            width: 100%;
            font-family: inherit;
            font-size: 1em;
            color: #ffffff;
            background-color: #181818;
            border: none;
            border-bottom: 1px solid #3b3b3b;
            border-radius: 8px;
            box-shadow: inset 0 2px 7px 2px #000000b9;
            outline: none;
            -webkit-appearance: none;
            transition: 0.4s;
            margin-top: 7px;
            &:focus {
              padding-left: 16px;
              padding-right: 12px;
              background-color: #1f1f1f;
            }
          }
          input[type="date"]::-webkit-calendar-picker-indicator {
            opacity: 0.0000000001;
            position: relative;
            right: 2px;
            z-index: 777;
          }
          input[type="date"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            display: none;
          }
          input[type="date"]::-webkit-clear-button {
            display: none;
          }
        }
        // end date

        &.calendar {
          width: 100%;
        }

        // end textarea
        &.name {
          // order: 1;
          width: calc(40% - 0.5em);
        }
        // end col name

        &.small-name {
          width: calc(35% - 0.5em);
        }

        &.fee {
          width: calc(15% - 0.5em);
          // &:nth-child(2) { order: 2; }
          // &:nth-child(3) { order: 3; }
          // &:nth-child(4) { order: 4; }
          // &:nth-child(5) { order: 5; }
          // &:nth-child(6) { order: 6; }
        }

        &.small-fee {
          width: calc(13% - 0.5em);
        }
        // end col fee
        &.label {
          width: calc(50% - 0.5em);

          font-size: 0.98em;
          margin-bottom: 0;

          // &:nth-child(12) { order: 12; }
          // &:nth-child(14) { order: 14; }
          &.error {
            color: #e60022;
          }
        }
        &.date {
          width: calc(20% - 0.5em);
          // &:nth-child(7) { order: 7; }
          // &:nth-child(8) { order: 8; }
          // &:nth-child(9) { order: 9; }
          // &:nth-child(10) { order: 10; }
          // &:nth-child(11) { order: 11; }
          // &:nth-child(14) { order: 14; }
          // &:nth-child(15) { order: 15; }
          // &:nth-child(16) { order: 16; }
          // &:nth-child(17) { order: 17; }
        }
        // end col date
      }
      // end form-col
      // @media only screen and (max-width: 850) {

      // }
      .order {
        width: 20%;
      }
    }
    // end form-row
    .info-wrapper {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      .order {
        width: 14%;
      }
      .description {
        width: 83.3%;
      }
    }
    //end info wrapper
    .times-icon {
      font-size: 20px;
      position: absolute;
      top: 5px;
      right: 13px;
      cursor: pointer;
      //z-index: 10;
    }
    //end times-icon
    .label-wrapper {
      width: 100%;
      text-align: end;
    }
    div.form-col.password {
      width: 99.6%;
    }
    //end div.password
    .date-wrapper {
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .remove-date {
        position: absolute;
        right: -15px;
        top: 46px;
        cursor: pointer;
      }
    }
    //end date-wrapper
    .edit-sale-date-wrapper {
      width: 100%;
      display: flex;
      .edit-sale-date-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        padding: 0px 10px;
        // height: 100px;
        .edit-sale-date {
          display: flex;
          // width: fit-content;
          flex-flow: row;
          background: #4eb95e;
          border-radius: 10px;
          margin-top: 15px;
          padding: 18px 21px;
          cursor: pointer;
          width: 299px;
          position: relative;
          .edit-sale-date-info {
            min-width: max-content;
            padding-right: 10px;
            p {
              //padding-left: 5px;
              font-size: 14px !important;
              margin: auto;
            }
          }
          .edit-sale-date-icons {
            display: flex;
            margin: auto;
            div:first-child {
              //margin-right:3px;
              position: absolute;
              bottom: 4px;
              right: 4px;
            }
            div:last-child {
              //  margin-left: 3px;
              //  padding-right: 2px;
              position: absolute;
              text-align: center;
              top: 2px;
              right: 3px;
              width: 19px;
              height: 20px;
              svg:focus {
                background-color: #e60022;
              }
              // svg:hover {
              //   background-color:#e60022;
              //   border-radius: 10px;
              //   padding: 2px;
              // }
            }
            div:last-child:hover {
              background-color: #e60022;
              border-radius: 8px;
              //padding: 1px 4px;
            }
          }
        }
        //end edit-date
      }
      //end edit-date-container
    }
    //end edit-sale-date-wrapper
    .edit-entry-date-wrapper {
      width: 100%;
      display: flex;
      margin-left: 4px;
      .edit-entry-date-container {
        width: 100%;
        .edit-entry-container-wrapper {
          margin-right: 7.5px;
          margin-left: 7.5px;
          //margin-top: 15px;
          display: inline-block;
        }
        .edit-entry-date {
          display: flex;
          width: 167px;
          flex-flow: row;
          background: #4eb95e;
          border-radius: 10px;
          margin-top: 15px;
          //margin-top: 20px;
          cursor: pointer;
          position: relative;
          padding: 10px;
          .edit-entry-date-info {
            min-width: max-content;
            p {
              padding-left: 5px;
            }
          }
          .edit-entry-date-icons {
            display: flex;
            margin: auto;
            div:first-child {
              //margin-right:3px;
              position: absolute;
              bottom: 4px;
              right: 4px;
            }
            div:last-child {
              //  margin-left: 3px;
              //  padding-right: 2px;
              position: absolute;
              text-align: center;
              top: 2px;
              right: 3px;
              width: 19px;
              height: 20px;
              svg:focus {
                background-color: #e60022;
              }
              // svg:hover {
              //   background-color:#e60022;
              //   border-radius: 10px;
              //   padding: 2px;
              // }
            }
            div:last-child:hover {
              background-color: #e60022;
              border-radius: 8px;
              //  padding: 1px 4px;
            }
          }
        }
        .edit-entry-date-time {
          display: flex;
          width: 270px;
          flex-flow: row;
          background: #4eb95e;
          border-radius: 10px;
          margin-top: 15px;
          //margin-top: 20px;
          cursor: pointer;
          position: relative;
          padding: 10px;
          .edit-entry-date-info {
            min-width: max-content;
            p {
              padding-left: 5px;
            }
          }
          .edit-entry-date-icons {
            display: flex;
            margin: auto;
            div:first-child {
              //margin-right:3px;
              position: absolute;
              bottom: 4px;
              right: 4px;
            }
            div:last-child {
              //  margin-left: 3px;
              //  padding-right: 2px;
              position: absolute;
              text-align: center;
              top: 0;
              right: 3px;
              width: 19px;
              height: 20px;
              svg:focus {
                background-color: #e60022;
              }
            }
            div:last-child:hover {
              background-color: #e60022;
              border-radius: 8px;
            }
          }
        }
        .edit-entry-time {
          display: flex;
          width: 130px;
          flex-flow: row;
          background: #4eb95e;
          border-radius: 10px;
          margin-top: 15px;
          // margin-top: 20px;
          cursor: pointer;
          position: relative;
          padding: 10px;
          padding-right: 12px;
          .edit-entry-date-info {
            min-width: max-content;
            p {
              padding-left: 5px;
            }
          }
          .edit-entry-date-icons {
            display: flex;
            margin: auto;
            div:first-child {
              //margin-right:3px;
              position: absolute;
              bottom: 4px;
              right: 4px;
            }
            div:last-child {
              //  margin-left: 3px;
              //  padding-right: 2px;
              position: absolute;
              text-align: center;
              top: 2px;
              right: 3px;
              width: 19px;
              height: 20px;
              svg:focus {
                background-color: #e60022;
              }
            }
            div:last-child:hover {
              background-color: #e60022;
              border-radius: 8px;
              //  padding: 1px 4px;
            }
          }
        }
      }
      .edit-entry-date-container.date-time {
        justify-content: space-between;
      }
    }
    //end edit-entry-date-wrapper
    .availability-text {
      height: 0%;
      width: 100%;
    }
    //end availability-text
    .availability-checkbox {
      position: relative;
      bottom: 10px;
    }
    //end availability-checkbox
    .availability-wrapper {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      justify-content: space-between;
      .availability-dates {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .start-date {
          width: calc(50% - 0.5em);
        }
        .end-date {
          width: calc(50% - 0.5em);
        }
      }
      .availability-times {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .start-time {
          width: calc(50% - 0.5em);
        }
        .end-time {
          width: calc(50% - 0.5em);
        }
      }
    }
    //end availability-wrapper
    .availability-start,
    .availability-end {
      width: 49.5%;
    }
    //end availability-start/end
    .password-wrapper {
      width: 100%;
      margin-top: 30px;
    }
    //end password-wrapper
    .add-date {
      height: 0%;
      width: 100%;
      text-align: end;
      cursor: pointer;
    }
  }
  // end form-inner

  .add-tier-wrapper {
    button {
      width: 100%;
    }
    // button.add-itinerary {
    //   cursor: pointer;
    //   position: relative;
    //   margin-bottom: 10px;
    //   padding: 2px 16px;
    //   width: 100%;
    //   height: 50px;
    //   text-transform: uppercase;
    //   background-color: #1e1e1e;
    //   border: 1px solid;
    //   border-radius: 4px;
    //   outline: none;
    //   -webkit-appearance: none;
    //   background-color: #4cac55;
    //   background: linear-gradient(#4cac55, #39793f);
    //   color: #fff;
    //   border-color: #5fb469;

    //   .icon-size {
    //     position: relative;
    //     height: 17px;
    //     width: 17px;
    //     color: #fff;
    //     left: -1%;
    //     top: 2px;
    //     //  z-index: 5;
    //   }
    //   //end icon-size
    // }
    //end button.add-itinerary
  }
  //end add-tier-wrapper
}
// end tier
@media screen and (max-width: 1049px) {
  .tier {
    .tier-inner {
      .form-row {
        .form-col {
          .date {
            display: flex;
            position: relative;
            width: 100%;
            .placeholder {
              position: absolute;
              top: 12px;
              left: 14px;
              color: #878787;
            }
            // end placeholder
            .icon-calender {
              position: absolute;
              top: 12px;
              right: 14px;
              color: #878787;
            }
            // end icon-calender
            .edit-icon {
              height: 22px;
              width: 22px;
              margin-top: 4px;
            }
            input[type="date"],
            textarea {
              display: block;
              padding: 11px 0px;
              text-indent: 14px;
              width: 100%;
              font-family: inherit;
              font-size: 1em;
              color: #ffffff;
              background-color: #181818;
              border: none;
              border-bottom: 1px solid #3b3b3b;
              border-radius: 8px;
              box-shadow: inset 0 2px 7px 2px #000000b9;
              outline: none;
              -webkit-appearance: none;
              transition: 0.4s;
              margin-top: 7px;
              &:focus {
                padding-left: 16px;
                padding-right: 12px;
                background-color: #1f1f1f;
              }
            }
            input[type="date"]::-webkit-calendar-picker-indicator {
              opacity: 0.0000000001;
              position: relative;
              right: 2px;
              z-index: 777;
            }
            input[type="date"]::-webkit-inner-spin-button {
              -webkit-appearance: none;
              display: none;
            }
            input[type="date"]::-webkit-clear-button {
              display: none;
            }
          }
          // end date
          &.fee {
            width: calc(33.33% - 0.5em);
            &:nth-child(2),
            &:nth-child(3) {
              width: calc(30% - 0.5em);
            }
          }
          //
        }
        // end form-col
      }
      // end form-row
    }
    // end tier-inner
  }
  // end tier
}
// end 1049px breakpoint
@media screen and (max-width: 859px) {
  .tier {
    .tier-inner {
      .form-row {
        .form-col {
          .date {
            display: flex;
            position: relative;
            width: 100%;
            .placeholder {
              position: absolute;
              top: 12px;
              left: 14px;
              color: #878787;
            }
            // end placeholder
            .icon-calender {
              position: absolute;
              top: 12px;
              right: 14px;
              color: #878787;
            }
            // end icon-calender
            .edit-icon {
              height: 22px;
              width: 22px;
              margin-top: 4px;
            }
            input[type="date"],
            textarea {
              display: block;
              padding: 11px 0px;
              text-indent: 14px;
              width: 100%;
              font-family: inherit;
              font-size: 1em;
              color: #ffffff;
              background-color: #181818;
              border: none;
              border-bottom: 1px solid #3b3b3b;
              border-radius: 8px;
              box-shadow: inset 0 2px 7px 2px #000000b9;
              outline: none;
              -webkit-appearance: none;
              transition: 0.4s;
              margin-top: 7px;
              &:focus {
                padding-left: 16px;
                padding-right: 12px;
                background-color: #1f1f1f;
              }
            }
            input[type="date"]::-webkit-calendar-picker-indicator {
              opacity: 0.0000000001;
              position: relative;
              right: 2px;
              z-index: 777;
            }
            input[type="date"]::-webkit-inner-spin-button {
              -webkit-appearance: none;
              display: none;
            }
            input[type="date"]::-webkit-clear-button {
              display: none;
            }
          }
          // end date
          &.name,
          &.fee,
          &.date {
            width: calc(50% - 0.5em);
          }
          //
          &.fee {
            &:nth-child(2),
            &:nth-child(3) {
              width: calc(50% - 0.5em);
            }
          }
          //

          &.date {
            &:nth-child(10) {
              order: 10;
            }
          }
          //
        }
        // end form-col
      }
      // end form-row
    }
    // end tier-inner
  }
  // end tier
}
// end 859px breakpoint

@media screen and (max-width: 600px) {
  .tier {
    .tier-inner {
      .info-wrapper {
        .order {
          width: 20%;
        }
      }
      .form-row {
        padding: 20px 0 0;
        .form-col {
          .date {
            display: flex;
            position: relative;
            width: 100%;
            .placeholder {
              position: absolute;
              top: 12px;
              left: 14px;
              color: #878787;
            }
            // end placeholder
            .icon-calender {
              position: absolute;
              top: 12px;
              right: 14px;
              color: #878787;
            }
            // end icon-calender
            .edit-icon {
              height: 22px;
              width: 22px;
              margin-top: 4px;
            }
            input[type="date"],
            textarea {
              display: block;
              padding: 11px 0px;
              text-indent: 14px;
              width: 100%;
              font-family: inherit;
              font-size: 1em;
              color: #ffffff;
              background-color: #181818;
              border: none;
              border-bottom: 1px solid #3b3b3b;
              border-radius: 8px;
              box-shadow: inset 0 2px 7px 2px #000000b9;
              outline: none;
              -webkit-appearance: none;
              transition: 0.4s;
              margin-top: 7px;
              &:focus {
                padding-left: 16px;
                padding-right: 12px;
                background-color: #1f1f1f;
              }
            }
            input[type="date"]::-webkit-calendar-picker-indicator {
              opacity: 0.0000000001;
              position: relative;
              right: 2px;
              z-index: 777;
            }
            input[type="date"]::-webkit-inner-spin-button {
              -webkit-appearance: none;
              display: none;
            }
            input[type="date"]::-webkit-clear-button {
              display: none;
            }
          }
          // end date
          &.name,
          &.fee,
          &.date,
          &.description {
            width: 100%;
          }
          //
          &.fee {
            &:nth-child(2),
            &:nth-child(3) {
              width: calc(50% - 0.5em);
            }
          }
          //

          &.date {
            &:nth-child(10) {
              order: 10;
            }
          }
          //
        }
        // end form-col
      }

      .availability-wrapper {
        .availability-dates {
          flex-wrap: wrap;
          .start-date,
          .end-date {
            width: 100%;
          }
        }
        .availability-times {
          width: 100%;
        }
      }
      // end form-row
    }
    // end tier-inner
  }
  // end tier
}
</style>

<script>
import Vue from "vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox";
import DiyoboTimePicker from "../components/diyoboTimePicker.vue";
import PopUpsForIcons from "../components/PopUpsForIcons.vue";
import { library, parse } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faCalendarAlt,
  faClock,
  faTimes,
  faPen,
  faQuestionCircle,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import DiyoboButton from "@/components/DiyoboButton.vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
import DiyoboCalendar from "../components/diyoboCalendar.vue";
import CalendarSet from "./CalendarSet.vue";
import moment from "moment";

library.add(
  faAngleDown,
  faCalendarAlt,
  faClock,
  faTimes,
  faPen,
  faQuestionCircle,
  faPlus
);

export default {
  name: "ticket-tier",
  components: {
    DiyoboInput,
    DiyoboButton,
    DiyoboCheckbox,
    FontAwesomeIcon,
    PopUpsForIcons,
    DiyoboCalendar,
    DiyoboTimePicker,
    CalendarSet
  },
  props: {
    info: Object,
    bus: Object,
    edit: Boolean,
    id: Number | String,
    index: Number,
    type: String,
    editTier: Object,
    optional: Number,
    eventStartDate: String,
    eventEndDate: String,
    excludedDates: Array,
    tierNames: Array
  },
  data() {
    return {
      name: this.tierData ? this.tierData.name : "",
      price: this.tierData ? this.tierData.price : "",
      ccFee: this.tierData ? this.tierData.ccFee.toFixed(2) : (0).toFixed(2),
      dFee: this.tierData ? this.tierData.dFee : (0).toFixed(2),
      aFee: this.tierData ? this.tierData.addfee : "",
      qty: null,
      description: "",
      saleDateToEdit: [
        {
          id: 0,
          startDate: "",
          endDate: "",
          startTime: "",
          endTime: "",
          qty: ""
        }
      ],
      prevSaleDateToEdit: {},
      saleDates:
        this.tierData && this.tierData.saleDates ? this.tierData.saleDates : [],
      saleDateMsg: "Add Another Date/Time",
      isSaleDateEditable: false,
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      tdIndex: 0,
      tierType: "",
      errorReload: 0,
      editIndex: 0,
      errors: [
        { name: "savedDates", display: "Tier Dates", error: false },
        { name: "name", display: "Ticket Tier Name", error: false },
        { name: "price", display: "Price", error: false },
        { name: "description", display: "Description", error: false },
        {
          name: "venueAccessStartTime",
          display: "Venue Access Start Time",
          error: false
        },
        {
          name: "venueAccessEndTime",
          display: "Venue Access End Time",
          error: false
        },
        {
          name: "venueAccessStartDate",
          display: "Venue Access Start Date",
          error: false
        },
        {
          name: "venueAccessEndDate",
          display: "Venue Access End Date",
          error: false
        },
        // { name: 'currencyType', display: 'Currency Type', error: false},
        { name: "tierPassword", display: "Password", error: false },
        {
          name: `sale-startDate-0`,
          display: "Purchase Start Date",
          error: false
        },
        { name: `sale-endDate-0`, display: "Purchase End Date", error: false },
        {
          name: `sale-startTime-0`,
          display: "Purchase Start Time",
          error: false
        },
        { name: `sale-endTime-0`, display: "Purchase End Time", error: false },
        { name: `sale-qty-0`, display: "Quantity", error: false },
        { name: `sale-order-0`, display: "Display Order", error: false },
        {
          name: "entry-startTime-0",
          display: "Entry Start Time",
          error: false
        },
        { name: "entry-endTime-0", display: "Entry End Time", error: false },
        { name: "tixStartNum", display: "Tix Start #", error: false }
      ],
      tempTier: this.tierData,
      soldout: false,
      venueAccess:
        this.tierData && this.tierData.venueAccess
          ? this.tierData.venueAccess
          : { startDate: "", startTime: "", endDate: "", endTime: "" },
      isThereAPassword: false,
      tierPassword: this.tierData ? this.tierData.password : "",
      EntryDates:
        this.tierData && this.tierData.entryDates
          ? this.tierData.entryDates
          : [],
      EntryDateToEdit: [{ id: 0, startDate: "", endDate: "" }],
      EntryDateEdit: false,
      applyToAllEventDates: false,
      multipleEventDateIds: [],
      TFeePriceRange: [],
      ccfeeToUse: 0.03,
      tierBus: new Vue(),

      tixStartNum: 1,
      excludedDatesToUse: this.excludedDates,
      savedDates: [],
      sameAsEventDate: false,
      entryTimeArray: [
        {
          id: 0,
          startTime: "",
          endTime: ""
        }
      ]
    };
  },
  watch: {
    editTier: function (val) {
      this.tempTier = val;
      if (!["addition", "initial"].includes(val.id)) {
        this.fillEditData();
      }
    },
    isSaleDateEditable: function (val) {
      if (val) {
        this.saleDateMsg = "Edit Date/Time";
      } else {
        this.saleDateMsg = "Add Another Date/Time";
      }
    },
    price: function (val) {
      let self = this;
      this.calculateTierFees(val, this.aFee);
    },
    aFee(val) {
      let self = this;
      this.calculateTierFees(this.price, val);
    },
    name(val) {
      if (this.sameName(val)) {
        this.errors[1].error = false;
        this.errors[1].errors = [];
      } else {
        this.errors[1].error = true;
        this.errors[1].errors = [];
        this.errors[1].errors.push(`${this.errors[1].display} must be unique`);
      }
    },
    soldout(val) {
      if (val) {
        this.soldout = val;
      }
    },
    sameAsEventDate(val) {
      if (val) {
        this.venueAccess.startDate = this.eventStartDateToUse;
        this.venueAccess.startTime = this.eventStartTime;
        this.venueAccess.endDate = this.eventEndDateToUse;
        this.venueAccess.endTime = this.eventEndTime;
      } else {
        this.venueAccess.startDate = "";
        this.venueAccess.startTime = "";
        this.venueAccess.endDate = "";
        this.venueAccess.endTime = "";
      }
    }
  },
  computed: {
    priceOutput: {
      get: function () {
        return this.price;
      },
      set: function (val) {
        if (val == "") {
          this.price = "";
        } else {
          this.price = parseFloat(val);
        }
      }
    },
    aFeeOutput: {
      get: function () {
        return this.aFee;
      },
      set: function (val) {
        if (val == "") {
          this.aFee = "";
        } else {
          this.aFee = parseFloat(val);
        }
      }
    },
    TFeeOutput() {
      return parseInt(this.dFee).toFixed(2);
    },
    CCFeeOutput() {
      return parseInt(this.ccFee).toFixed(2);
    },
    today() {
      let now = new Date();
      return dateFormat(now, "yyyy/mm/dd");
    },
    entryTimeArrayMsg() {
      return "Add Another Time";
    },
    tierData() {
      return this.editTier;
    },
    eventStartDateToUse() {
      let startDate;
      if (this.eventStartDate.includes("-")) {
        startDate = this.eventStartDate.split("-")[0];
      } else {
        let dateArray = this.eventStartDate.split("");
        let indexToUse = dateArray.length - 9;
        let dateToUse = dateArray.slice(0, indexToUse);
        startDate = dateToUse.join("");
      }
      return startDate;
    },
    eventEndDateToUse() {
      let endDate;
      if (this.eventEndDate.includes("-")) {
        endDate = this.eventEndDate.split("-")[0];
      } else {
        let dateArray = this.eventEndDate.split("");
        let indexToUse = dateArray.length - 9;
        let dateToUse = dateArray.slice(0, indexToUse);
        endDate = dateToUse.join("");
      }
      return endDate;
    },
    eventStartTime() {
      let startTime;
      if (this.eventStartDate.includes("-")) {
        startTime = this.eventStartDate.split("-")[1];
      } else {
        let dateArray = this.eventStartDate.split("");
        let indexToUse = dateArray.length - 8;
        let dateToUse = dateArray.slice(indexToUse, dateArray.length);
        startTime = dateToUse.join("");
      }
      return startTime;
    },
    eventEndTime() {
      let endTime;
      if (this.eventEndDate.includes("-")) {
        endTime = this.eventEndDate.split("-")[1];
      } else {
        let dateArray = this.eventEndDate.split("");
        let indexToUse = dateArray.length - 8;
        let dateToUse = dateArray.slice(indexToUse, dateArray.length);
        endTime = dateToUse.join("");
      }
      return endTime;
    },
    // soldoutVal: {
    //   get() {
    //     if (this.tierData.soldout) {
    //       this.soldout = this.tierData.soldout;
    //       return this.tierData.soldout;
    //     } else {
    //       this.soldout = false;
    //       return false;
    //     }
    //   },
    // },
    saleDateToEditMiddleMan: {
      get() {
        let length = this.saleDateToEdit.length;
        return this.saleDateToEdit[length - 1];
      },
      set(val) {
        console.log("huff and puff", val);
        if (val != undefined) {
          console.log("hahahahahahahhahahaahahahahahahah I win");
          this.saleDateToEdit.push(val);
        }
      }
    },
    entryTimeArrayMiddleMan: {
      get() {
        let length = this.entryTimeArray.length;
        return this.entryTimeArray[length - 1];
      },
      set(val) {
        if (val != undefined) {
          this.entryTimeArray.push(val);
        }
      }
    },
    sortedDates() {
      let self = this;
      let arrayToMutate = [...this.savedDates];
      return arrayToMutate.sort((a, b) => {
        let firstDate = new Date(a.month + "-" + a.day + "-" + a.year);
        let secondDate = new Date(b.month + "-" + b.day + "-" + b.year);
        if (firstDate > secondDate) {
          return 1;
        } else {
          return -1;
        }
      });
    },
    tierNamesToUse() {
      return this.tierNames;
    },
    venueAccessEventMsg() {
      if (this.type == "Date" || this.type == "Date and Time") {
        return "Same as Event Times";
      } else if (this.type == "Time") {
        return "Same as Event Dates";
      } else {
        return "Same as Event Dates and Times";
      }
    }
  },
  methods: {
    calculateTierFees(price, aFee) {
      for (let i = 0; i < this.TFeePriceRange.length; i++) {
        let ele = this.TFeePriceRange[i];
        if (+price + +aFee >= ele.min && +price + +aFee <= ele.max) {
          this.dFee = ele.value.toFixed(2);
          i = this.TFeePriceRange.length;
        } else if (+price + +aFee === 0) {
          this.dFee = "0.00";
        }
      }
      let ccFee = price * this.ccfeeToUse;
      this.ccFee = ccFee.toFixed(2);
    },
    sameName(name) {
      let uniqueName = true;
      this.tierNamesToUse.forEach((tierName) => {
        if (tierName.name == name && tierName.id != this.id) {
          uniqueName = false;
        }
      });
      return uniqueName;
    },
    error(input) {
      let error = this.errors.find((e) => e.name == input);
      return error && error.error;
    },
    addTier() {
      if (this.validate()) {
        let dateArray = this.clearDateFields();
        let saleDateArray = dateArray[1];
        let entryDateArray = dateArray[2];
        let addfee;
        let obj;
        if (this.aFee) {
          addfee = parseFloat(this.aFee);
        } else {
          addfee = null;
        }
        let passwordToStore = this.tierPassword;
        obj = {
          id: 0,
          name: this.name,
          description: this.description,
          password: passwordToStore,
          // currencyType: this.currencyType,
          tierUpdatedFromDb: true,
          tixStartNum: this.tixStartNum,
          price: parseFloat(this.price),
          ccfee: parseFloat(this.ccFee),
          dfee: parseFloat(this.dFee),
          addfee: addfee,
          saleDates: saleDateArray,
          entryDates: entryDateArray,
          venueAccess: dateArray[0],
          type: this.type
        };

        this.name = "";
        this.price = "";
        this.aFee = "";
        this.qty = "";
        this.description = "";
        this.tierPassword = "";
        this.isThereAPassword = false;
        this.$emit("addTier", obj, null);
      }
    },
    updateTier() {
      if (this.validate()) {
        let addfee;
        if (this.aFee) {
          addfee = parseFloat(this.aFee);
        } else {
          addfee = null;
        }
        let obj;
        let dateArray = this.clearDateFields();
        if (this.editTier.type == "General Tier") {
          obj = {
            id: this.editTier.id,
            name: this.name,
            description: this.description,
            price: parseFloat(this.price),
            ccfee: parseFloat(this.ccFee),
            dfee: parseFloat(this.dFee),
            addfee: addfee,
            type: this.type,
            soldout: this.soldout,
            saleDates: dateArray[1],
            entryDates: dateArray[2],
            venueAccess: dateArray[0],
            password: this.isThereAPassword ? this.tierPassword : false,
            currencyType: this.currencyType,
            tierUpdatedFromDb: true,
            tixStartNum: this.tixStartNum
          };
        } else {
          obj = {
            id: this.editTier.id,
            name: this.name,
            description: this.description,
            price: parseFloat(this.price),
            ccfee: parseFloat(this.ccFee),
            dfee: parseFloat(this.dFee),
            addfee: addfee,
            saleDates: dateArray[1],
            entryDates: dateArray[2],
            venueAccess: dateArray[0],
            password: this.isThereAPassword ? this.tierPassword : false,
            currencyType: this.currencyType,
            type: this.type,
            soldout: this.soldout,

            tierUpdatedFromDb: true,
            tixStartNum: this.tixStartNum
          };
        }

        this.name = "";
        this.price = "";
        this.aFee = "";
        this.qty = "";
        this.description = "";
        this.tierPassword = "";
        this.isThereAPassword = false;
        this.tempTier = { id: "addition" };

        // remove all sale date errors
        this.errors = this.errors.filter((e) => !e.name.startsWith("sale-"));

        // re-add the default errors
        this.errors.push(
          {
            name: `sale-startDate-0`,
            display: "Purchase Start Date",
            error: false
          },
          {
            name: `sale-endDate-0`,
            display: "Purchase End Date",
            error: false
          },
          {
            name: `sale-startTime-0`,
            display: "Purchase Start Time",
            error: false
          },
          {
            name: `sale-endTime-0`,
            display: "Purchase End Time",
            error: false
          },
          { name: `sale-qty-0`, display: "Quantity", error: false },
          { name: `sale-order-0`, display: "Display Order", error: false }
        );

        this.$emit("addTier", null, obj);
      }
    },
    clearDateFields() {
      let self = this;
      let dateArray = [{ ...this.venueAccess }, [...this.saleDateToEdit]];
      let entryDates = [];
      if (this.type == "Date and Time") {
        this.savedDates.forEach((date) => {
          self.entryTimeArray.forEach((time) => {
            let stringDateToUse = date.month + " " + date.day + " " + date.year;
            let dateObjToUse = new Date(stringDateToUse);
            let dateToUse = dateFormat(dateObjToUse, "ddd mmmm dd, yyyy");
            let newEntryDateObj = {
              eventDate: dateToUse,
              startTime: time.startTime,
              endTime: time.endTime
            };
            entryDates.push(newEntryDateObj);
          });
        });
      } else if (this.type == "Date") {
        this.savedDates.forEach((date) => {
          let stringDateToUse = date.month + " " + date.day + " " + date.year;
          let dateObjToUse = new Date(stringDateToUse);
          let dateToUse = dateFormat(dateObjToUse, "ddd mmmm dd, yyyy");
          let newEntryDateObj = {
            eventDate: dateToUse
          };
          entryDates.push(newEntryDateObj);
        });
      } else if (this.type == "Time") {
        this.entryTimeArray.forEach((time) => {
          let newEntryTimeObj = {
            eventDate: this.eventStartDateToUse,
            startTime: time.startTime,
            endTime: time.endTime
          };
          entryDates.push(newEntryTimeObj);
        });
      }
      dateArray.push(entryDates);
      this.saleDateToEdit.splice(0, this.saleDateToEdit.length);
      this.entryTimeArray.splice(0, this.entryTimeArray.length);
      this.savedDates.splice(0, this.savedDates.length);
      this.saleDateToEditMiddleMan = {
        id: 0,
        qty: "",
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        order: 1
      };
      this.entryTimeArrayMiddleMan = {
        id: 0,
        startTime: "",
        endTime: ""
      };
      this.venueAccess = {
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: ""
      };
      let errorsToReplace = this.errors.filter((error) => {
        if (error.name.includes("sale") || error.name.includes("entry")) {
          let key = error.name.split("-")[2];
          if (+key > 0) {
            return false;
          }
        }
        return error;
      });
      this.errors.splice(0, this.errors.length, ...errorsToReplace);
      this.editIndex++;
      this.sameAsEventDate = false;
      return dateArray;
    },
    validate() {
      let self = this;
      this.errors.forEach((e) => {
        e.errors = [];
        if (e.name.includes("sale")) {
          let key = e.name.split("-")[1];
          let id = e.name.split("-")[2];
          let element = this.saleDateToEdit.find((ele) => ele.id == id);
          if (key == "startDate") {
            if (!element[key]) {
              e.error = true;
            } else {
              e.error = false;
            }
          } else if (key == "endDate") {
            if (!element[key]) {
              e.error = true;
            } else {
              let startDateToCompare = new Date(
                element.startDate + " " + element.startTime
              );
              let endDateToCompare = new Date(
                element.endDate + " " + element.endTime
              );
              if (startDateToCompare > endDateToCompare) {
                e.error = true;
                e.errors.push(`${e.display} must be after the start Date`);
              } else {
                e.error = false;
              }
            }
          } else if (key == "startTime") {
            if (!element[key]) {
              e.error = true;
            } else {
              let chosenStartDate = element.startDate
                ? element.startDate
                : this.today;
              let chosenEndDate = element.endDate
                ? element.endDate
                : this.today;
              let startDateToCompare = new Date(
                chosenStartDate + " " + element.startTime
              );
              let endDateToCompare = new Date(
                chosenEndDate + " " + element.endTime
              );
              if (startDateToCompare > endDateToCompare) {
                e.error = true;
                e.errors.push(`${e.display} must be before the end time`);
              } else {
                e.error = false;
              }
            }
          } else if (key == "endTime") {
            if (!element[key]) {
              e.error = true;
            } else {
              let chosenStartDate = element.startDate
                ? element.startDate
                : this.today;
              let chosenEndDate = element.endDate
                ? element.endDate
                : this.today;
              let startDateToCompare = new Date(
                chosenStartDate + " " + element.startTime
              );
              let endDateToCompare = new Date(
                chosenEndDate + " " + element.endTime
              );
              if (startDateToCompare > endDateToCompare) {
                e.error = true;
                e.errors.push(`${e.display} must be after the start time`);
              } else {
                e.error = false;
              }
            }
          } else if (key == "qty") {
            if (
              element.qty == undefined ||
              parseInt(element.qty) <= 0 ||
              element.qty.length == 0
            ) {
              e.error = true;
            } else {
              e.error = false;
            }
          }
        }
        if (this.type == "Date" || this.type == "Date and Time") {
          if (e.name == "savedDates") {
            if (this.savedDates.length > 0) {
              e.error = false;
            } else {
              e.error = true;
            }
          }
        }
        if (this.type == "Date and Time") {
          if (e.name.includes(`entry-startTime`)) {
            let key = e.name.split("-")[1];
            let id = e.name.split("-")[2];
            let timeObj = this.entryTimeArray.find((time) => time.id == id);
            let offender = timeObj[key];
            if (offender && offender.length == 8) {
              let endDateToCompare = new Date(
                this.today + " " + timeObj.endTime
              );
              let bigger;
              let startTime = new Date(this.today + " " + offender);
              if (endDateToCompare < startTime) {
                e.error = true;
                e.errors.push(`${e.display} must be after the end date`);
              } else {
                e.error = false;
              }
            } else {
              e.error = true;
            }
          } else if (e.name.includes(`entry-endTime`)) {
            let key = e.name.split("-")[1];
            let id = e.name.split("-")[2];
            let timeObj = this.entryTimeArray.find((time) => time.id == id);
            let offender = timeObj[key];
            if (offender && offender.length == 8) {
              let startDateToCompare = new Date(
                this.today + " " + timeObj.startTime
              );
              let bigger;
              let endDate = new Date(this.today + " " + offender);
              if (startDateToCompare > endDate) {
                e.error = true;
                e.errors.push(`${e.display} must be after the start date`);
              } else {
                e.error = false;
              }
            } else {
              e.error = true;
            }
          }
        }
        if (this.type == "General Tier") {
          if (e.name == "venueAccessEndDate") {
            if (this.venueAccess.endDate) {
              if (this.eventStartDateToUse != this.eventEndDateToUse) {
                if (
                  new Date(
                    this.venueAccess.endDate + " " + this.venueAccess.endTime
                  ) >
                  new Date(
                    this.venueAccess.startDate +
                      " " +
                      this.venueAccess.startTime
                  )
                ) {
                  e.error = false;
                } else {
                  e.error = true;
                }
              } else {
                e.error = false;
              }
            } else {
              e.error = true;
            }
          }
          if (e.name == "venueAccessStartDate") {
            if (this.venueAccess.startDate) {
              e.error = false;
            } else {
              e.error = true;
            }
          }
        }
        if (e.name == "venueAccessStartTime") {
          if (this.venueAccess.startTime) {
            let chosenStartDate = this.venueAccess.startDate
              ? this.venueAccess.startDate
              : this.today;
            let chosenEndDate = this.venueAccess.endDate
              ? this.venueAccess.endDate
              : this.today;
            let startTime = new Date(
              chosenStartDate + " " + this.venueAccess.startTime
            );
            let endTime = new Date(
              chosenEndDate + " " + this.venueAccess.endTime
            );
            if (startTime < endTime) {
              e.error = false;
            } else {
              e.error = true;
              e.errors.push(`${e.display} must be before the end time`);
            }
          } else {
            e.error = true;
          }
        }
        if (e.name == "venueAccessEndTime") {
          if (this.venueAccess.endTime) {
            let chosenStartDate = this.venueAccess.startDate
              ? this.venueAccess.startDate
              : this.today;
            let chosenEndDate = this.venueAccess.endDate
              ? this.venueAccess.endDate
              : this.today;
            let startTime = new Date(
              chosenStartDate + " " + this.venueAccess.startTime
            );
            let endTime = new Date(
              chosenEndDate + " " + this.venueAccess.endTime
            );
            if (endTime > startTime) {
              e.error = false;
            } else {
              e.error = true;
              e.errors.push(`${e.display} must be after the start time`);
            }
          } else {
            e.error = true;
          }
        }

        if (e.name == "tixStartNum") {
          if (self[e.name] && parseInt(self[e.name]) > 0) {
            e.error = false;
          } else {
            e.error = true;
          }
        }

        if (e.name === "price") {
          e.error = Number.isNaN(this[e.name]) || Number(this[e.name]) < 0;
        }

        if (e.name == "description" || e.name == "currencyType") {
          e.error = !this[e.name];
        }
        if (e.name == "name") {
          if (this[e.name]) {
            if (this.sameName(this[e.name])) {
              e.error = false;
            } else {
              e.error = true;
            }
          } else {
            e.error = true;
          }
        }
        if (e.name == "tierPassword" && self.isThereAPassword) {
          e.error = !this[e.name];
        } else if (e.name == "tierPassword") {
          e.error = false;
        }
        if (e.error) {
          this.errorReload++;
          if (e.errors.length == 0) {
            if (
              e.name == "endDate" ||
              (e.name == "venueAccessEndDate" && this.venueAccess.endDate)
            ) {
              e.errors.push(`${e.display} must be after the start date`);
            } else {
              e.errors.push(`${e.display} is required`);
            }
          }
        }
      });

      if (!this.errors[1].error && isNaN(this.price)) {
        this.errors[1].error = true;
        this.errors[1].errors.push("Price value is invalid");
      }

      let isThereAnError = this.errors.some((ele) => ele.error);
      this.$emit("error-check", !isThereAnError);

      return !this.errors.some((e) => e.error);
    },
    saleDateValidateCheck() {
      let noErrors = true;
      this.errors.forEach((e) => {
        if (e.name.includes("sale")) {
          e.errors = [];
          let key = e.name.split("-")[1];
          let id = e.name.split("-")[2];
          let element = this.saleDateToEdit.find((ele) => ele.id == id);
          if (key == "startDate") {
            if (!element[key]) {
              noErrors = false;
              e.error = true;
            } else {
              e.error = false;
            }
          } else if (key == "endDate") {
            if (!element[key]) {
              noErrors = false;
              e.error = true;
            } else {
              let startDateToCompare = new Date(
                element.startDate + " " + element.startTime
              );
              let endDateToCompare = new Date(
                element.endDate + " " + element.endTime
              );
              if (startDateToCompare > endDateToCompare) {
                e.error = true;
                noErrors = false;
                e.errors.push(`${e.display} must be after the start Date`);
              } else {
                e.error = false;
              }
            }
          } else if (key == "startTime") {
            if (!element[key]) {
              e.error = true;
              noErrors = false;
            } else {
              let chosenStartDate = element.startDate
                ? element.startDate
                : this.today;
              let chosenEndDate = element.endDate
                ? element.endDate
                : this.today;
              let startDateToCompare = new Date(
                chosenStartDate + " " + element.startTime
              );
              let endDateToCompare = new Date(
                chosenEndDate + " " + element.endTime
              );
              if (startDateToCompare > endDateToCompare) {
                e.error = true;
                noErrors = false;
                e.errors.push(`${e.display} must be before the end time`);
              } else {
                e.error = false;
              }
            }
          } else if (key == "endTime") {
            if (!element[key]) {
              e.error = true;
              noErrors = false;
            } else {
              let chosenStartDate = element.startDate
                ? element.startDate
                : this.today;
              let chosenEndDate = element.endDate
                ? element.endDate
                : this.today;
              let startDateToCompare = new Date(
                chosenStartDate + " " + element.startTime
              );
              let endDateToCompare = new Date(
                chosenEndDate + " " + element.endTime
              );
              if (startDateToCompare > endDateToCompare) {
                e.error = true;
                noErrors = false;
                e.errors.push(`${e.display} must be after the start time`);
              } else {
                e.error = false;
              }
            }
          } else if (key == "qty") {
            if (
              element.qty == undefined ||
              parseInt(element.qty) <= 0 ||
              element.qty.length == 0
            ) {
              e.error = true;
              noErrors = false;
            } else {
              e.error = false;
            }
          }
        }
        if (e.error) {
          this.errorReload++;
          if (e.errors.length == 0) {
            if (
              e.name == "endDate" ||
              (e.name == "venueAccessEndDate" && this.venueAccess.endDate)
            ) {
              e.errors.push(`${e.display} must be after the start date`);
            } else {
              e.errors.push(`${e.display} is required`);
            }
          }
        }
      });
      return noErrors;
    },
    entryTimeValidateCheck() {
      let noErrors = true;
      this.errors.forEach((e) => {
        e.errors = [];
        if (e.name.includes(`entry-startTime`)) {
          let key = e.name.split("-")[1];
          let id = e.name.split("-")[2];
          let timeObj = this.entryTimeArray.find((time) => time.id == id);
          let offender = timeObj[key];
          if (offender && offender.length == 8) {
            let endDateToCompare = new Date(this.today + " " + timeObj.endTime);
            let startTime = new Date(this.today + " " + offender);
            if (endDateToCompare < startTime) {
              noErrors = false;
              e.error = true;
              e.errors.push(`${e.display} must be after the end date`);
            } else {
              e.error = false;
            }
          } else {
            noErrors = false;
            e.error = true;
          }
        } else if (e.name.includes(`entry-endTime`)) {
          let key = e.name.split("-")[1];
          let id = e.name.split("-")[2];
          let timeObj = this.entryTimeArray.find((time) => time.id == id);
          let offender = timeObj[key];
          if (offender && offender.length == 8) {
            let startDateToCompare = new Date(
              this.today + " " + timeObj.startTime
            );
            let endDate = new Date(this.today + " " + offender);
            if (startDateToCompare > endDate) {
              noErrors = false;
              e.error = true;
              e.errors.push(`${e.display} must be after the start date`);
            } else {
              e.error = false;
            }
          } else {
            noErrors = false;
            e.error = true;
          }
        }
        if (
          (e.error && e.name.includes`entry-endTime`) ||
          (e.error && e.name.includes(`entry-startTime`))
        ) {
          if (e.errors.length == 0) {
            e.errors.push(`${e.display} is required`);
          }
          this.errorReload++;
        }
      });
      return noErrors;
    },
    addSaleDate() {
      let self = this;
      if (this.saleDateValidateCheck()) {
        let objToSave = this.saleDateToEditMiddleMan;
        let idOfLastElement = this.saleDateToEdit.length;
        this.saleDateToEditMiddleMan = {
          id: idOfLastElement,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          qty: ""
        };
        let doIalreadyHaveError = this.errors.find(
          (ele) => ele.name == `sale-startDate-${idOfLastElement}`
        );
        if (!doIalreadyHaveError)
          this.errors.push(
            ...[
              {
                name: `sale-startDate-${idOfLastElement}`,
                display: "Purchase Start Date",
                error: false
              },
              {
                name: `sale-endDate-${idOfLastElement}`,
                display: "Purchase End Date",
                error: false
              },
              {
                name: `sale-startTime-${idOfLastElement}`,
                display: "Purchase Start Time",
                error: false
              },
              {
                name: `sale-endTime-${idOfLastElement}`,
                display: "Purchase End Time",
                error: false
              },
              {
                name: `sale-qty-${idOfLastElement}`,
                display: "Quantity",
                error: false
              }
            ]
          );
      }
    },
    addEntryTime() {
      let self = this;
      //we have to first perfect the validate check
      if (this.entryTimeValidateCheck()) {
        let objToSave = this.entryTimeArrayMiddleMan;
        let idOfLastElement = objToSave.id + 1;
        let newEntryDate = {
          id: idOfLastElement,
          startTime: "",
          endTime: ""
        };
        this.entryTimeArrayMiddleMan = newEntryDate;
        let doIalreadyHaveError = this.errors.find(
          (ele) => ele.name == `entry-startTime-${idOfLastElement}`
        );
        if (!doIalreadyHaveError) {
          this.errors.push(
            ...[
              {
                name: `entry-startTime-${idOfLastElement}`,
                display: "Entry Start Time",
                error: false
              },
              {
                name: `entry-endTime-${idOfLastElement}`,
                display: "Entry End Time",
                error: false
              }
            ]
          );
        }
      }
    },
    removeEntryDate(id) {
      let arrayToReplace = this.entryTimeArray.filter((time) => {
        if (time.id != id) {
          return time;
        }
      });
      this.entryTimeArray.splice(
        0,
        this.entryTimeArray.length,
        ...arrayToReplace
      );
      this.removeErrorsWithDate(id, "entry");
    },
    setTime(obj, id, type, time) {
      if (obj == "venueAccess") {
        console.log(type);
        if (type === "endTime" && this.venueAccess.endDate) {
          let dateToUse = new Date(this.venueAccess.endDate + " " + time);
          console.log(dateToUse);
          let eventEnd = new Date(this.eventEndDate);

          if (dateToUse > eventEnd) {
            this.errors["5"].error = true;
          } else {
            this.venueAccess[type] = time;
            this.errors["5"].error = false;
          }
        } else {
          this.venueAccess[type] = time;
        }
      } else {
        let timeObj = this[obj].find((ele) => ele.id == id);
        if (timeObj) {
          if (type === "endTime" && timeObj.endDate) {
            let dateToUse = new Date(timeObj.endDate + " " + time);
            let eventEnd = new Date(this.eventEndDate);
            if (dateToUse > eventEnd) {
              this.errors["15"].error = true;
            } else {
              timeObj[type] = time;
              this.errors["15"].error = false;
            }
          } else {
            timeObj[type] = time;
          }
        }
      }
    },
    getTime(unixTimeStamp) {
      let hour = dateFormat(new Date(+unixTimeStamp), "hh:MM TT");
      return hour;
    },
    getDate(unixTimeStamp) {
      let date = dateFormat(new Date(+unixTimeStamp), "dddd mmmm dd, yyyy");
      return date;
    },
    formatMoney(key) {
      let money = this[key];
      if (money) this[key] = parseFloat(money).toFixed(2);
    },
    checkKeyPrice(event) {
      if (
        event.key === "Backspace" ||
        event.key === "Delete" ||
        event.key === "ArrowLeft" ||
        event.key === "ArrowRight" ||
        event.key === "Tab"
      )
        return true;
      if (isNaN(event.key) && event.key !== ".") {
      }
    },
    quantityFormat(idOfElement, idOfSaleDate) {
      let val = document.getElementById(idOfElement).value;
      let index = idOfElement.split("-")[2];
      let key = idOfElement.split("-")[1];
      let saleDate = this.saleDateToEdit.find((ele) => ele.id == idOfSaleDate);
      saleDate[key] = parseFloat(val);
    },
    updateSaleDates(key, index, value) {
      this.saleDateToEdit[index][key] = value;
    },
    checkFields(howManyTiers) {
      if (howManyTiers != 0) {
        let isTierBeingFilled = false;
        if (this.saleDates.length > 0) isTierBeingFilled = true;
        if (this.EntryDates.length > 0) isTierBeingFilled = true;
        for (let key in this.saleDateToEdit[0]) {
          if (key != "id") {
            if (this.saleDateToEdit[0][key]) isTierBeingFilled = true;
            if (key == "order") {
              if (this.saleDateToEdit[0][key] == 1) {
                isTierBeingFilled = false;
              }
            }
          }
        }
        for (let key in this.EntryDateToEdit[0]) {
          if (key != "id") {
            if (this.EntryDateToEdit[0][key]) isTierBeingFilled = true;
          }
        }
        if (this.name || this.price || this.aFee || this.isThereAPassword)
          isTierBeingFilled = true;

        if (isTierBeingFilled) this.addTier();
        else this.$emit("error-check", !isTierBeingFilled);
      } else {
        this.addTier();
      }
    },
    removeSaleDate(id) {
      let arrayToInject = this.saleDateToEdit.filter((ele, index) => {
        if (ele.id != id) {
          return ele;
        }
      });
      let length = this.saleDateToEdit.length;
      this.saleDateToEdit.splice(0, length);
      this.saleDateToEdit.push(...arrayToInject);
      this.removeErrorsWithDate(id, "sale");
      let removingQtyError = this.errors.filter((element) => {
        if (element.name != `sale-qty-${id}`) {
          return element;
        }
      });
      this.errors.splice(0, this.errors.length, ...removingQtyError);
    },
    removeErrorsWithDate(id, type) {
      let newErrorArr = this.errors.filter((element) => {
        if (
          element.name != `${type}-startDate-${id}` &&
          element.name != `${type}-startTime-${id}` &&
          element.name != `${type}-endDate-${id}` &&
          element.name != `${type}-endTime-${id}`
        ) {
          return element;
        }
      });
      let length = this.errors.length;
      this.errors.splice(0, length);
      this.errors.push(...newErrorArr);
    },
    updateVenueStartDate(date) {
      this.venueAccess.startDate = date;
    },
    updateVenueEndDate(date) {
      this.venueAccess.endDate = date;
    },
    updateCalenderDates(key, id, type, date) {
      if (key == "venueAccess") {
        this.venueAccess[type] = date;
      } else {
        let dateObj = this[key].find((ele) => ele.id == id);
        if (dateObj) {
          dateObj[type] = date;
        }
      }
    },
    fillEditData() {
      this.soldout = this.editTier.soldout ? this.editTier.soldout : false;
      this.name = this.editTier.name;
      this.price = this.editTier.price.toFixed(2);
      this.dFee = this.editTier.dfee.toFixed(2);
      this.aFee = this.editTier.addfee.toFixed(2);
      this.calculateTierFees(this.price, this.aFee);
      this.description = this.editTier.description;
      let tempArr = [];
      if (this.editTier.saleDates) {
        this.editTier.saleDates.forEach((element, index) => {
          if (!element.start) {
            tempArr.push({
              id: element.id,
              startDate: element.startDate,
              endDate: element.endDate,
              startTime: element.startTime,
              endTime: element.endTime,
              qty: element.qty
            });
          } else {
            if (typeof element.sale_start == "object") {
              tempArr.push({
                id: element.id,
                startDate: element.sale_start
                  ? this.getDate(parseInt(element.sale_start.$date.$numberLong))
                  : "",
                startTime: element.sale_start
                  ? this.getTime(parseInt(element.sale_start.$date.$numberLong))
                  : "",
                endDate: element.sale_end
                  ? this.getDate(parseInt(element.sale_end.$date.$numberLong))
                  : "",
                endTime: element.sale_end
                  ? this.getTime(parseInt(element.sale_end.$date.$numberLong))
                  : "",
                qty: element.quantity ? element.quantity : element.qty
              });
            } else {
              tempArr.push({
                id: element.id,
                startDate: this.getDate(
                  parseInt(Date.parse(element.sale_start))
                ),
                startTime: this.getTime(
                  parseInt(Date.parse(element.sale_start))
                ),
                endDate: this.getDate(parseInt(Date.parse(element.sale_end))),
                endTime: this.getTime(parseInt(Date.parse(element.sale_end))),
                qty: element.quantity ? element.quantity : element.qty
              });
            }
          }

          // clear current sale date errors
          this.errors = this.errors.filter((e) => !e.name.startsWith("sale-"));

          let newErrorArray = [
            {
              name: `sale-startTime-${element.id}`,
              display: "Start Time",
              error: false
            },
            {
              name: `sale-startDate-${element.id}`,
              display: "Start Date",
              error: false
            },
            {
              name: `sale-endDate-${element.id}`,
              display: "End Date",
              error: false
            },
            {
              name: `sale-endTime-${element.id}`,
              display: "End Time",
              error: false
            },
            {
              name: `sale-qty-${element.id}`,
              display: "Quantity",
              error: false
            }
          ];
          this.errors.push(...newErrorArray);
        });
      }
      if (this.editTier.venueAccess) {
        if (this.editTier.venueAccess.start) {
          this.venueAccess.startDate = this.getDate(
            this.editTier.venueAccess.start.$date.$numberLong
          );
          this.venueAccess.startTime = this.getTime(
            this.editTier.venueAccess.start.$date.$numberLong
          );
          this.venueAccess.endDate = this.getDate(
            this.editTier.venueAccess.end.$date.$numberLong
          );
          this.venueAccess.endTime = this.getTime(
            this.editTier.venueAccess.end.$date.$numberLong
          );
        } else {
          this.venueAccess.startDate = this.editTier.venueAccess.startDate;
          this.venueAccess.startTime = this.editTier.venueAccess.startTime;
          this.venueAccess.endDate = this.editTier.venueAccess.endDate;
          this.venueAccess.endTime = this.editTier.venueAccess.endTime;
        }
      }
      if (this.editTier.entryDates) {
        if (this.editTier.entryDates.length > 0) {
          let entryDateArrToUse = [];
          let timeArray = [];
          this.editTier.entryDates.forEach((ele, index) => {
            if (ele.eventDate) {
              if (ele.startTime || ele.endTime) {
                let timeObj = {
                  id: index,
                  startTime: ele.startTime,
                  endTime: ele.endTime
                };
                let amIAlreadyHere = timeArray.some(
                  (time) =>
                    time.startTime == ele.startTime &&
                    time.endTime == ele.endTime
                );
                if (!amIAlreadyHere) {
                  timeArray.push(timeObj);
                  if (index > 0) {
                    let newErrorArray = [
                      {
                        name: `entry-startTime-${index}`,
                        display: "Entry Start Time",
                        error: false
                      },
                      {
                        name: `entry-endTime-${index}`,
                        display: "Entry End Time",
                        error: false
                      }
                    ];
                    this.errors.push(...newErrorArray);
                  }
                }
              }
              let dayToUse = dateFormat(ele.eventDate, "yyyy-dd-mmmm").split(
                "-"
              );
              let dayToInject = {
                day: dayToUse[1],
                year: dayToUse[0],
                month: dayToUse[2]
              };
              entryDateArrToUse.push(dayToInject);
            } else {
              if (
                this.editTier.type == "Date" ||
                this.editTier.type == "Date and Time"
              ) {
                let startDateToUse = dateFormat(
                  new Date(+ele.startDate.$date.$numberLong),
                  "mmmm-d-yyyy"
                ).split("-");
                let endDateToUse = dateFormat(
                  new Date(+ele.endDate.$date.$numberLong),
                  "mmmm-d-yyyy"
                ).split("-");
                let startDateObj = {
                  year: startDateToUse[2],
                  month: startDateToUse[0],
                  day: startDateToUse[1]
                };
                let endDateObj = {
                  year: endDateToUse[2],
                  month: endDateToUse[0],
                  day: endDateToUse[1]
                };
                let someDataAlreadyThere = entryDateArrToUse.some(
                  (date) =>
                    startDateObj.year == date.year &&
                    startDateObj.month == date.month &&
                    startDateObj.day == date.day
                );
                let amIUnique = true;
                if (
                  startDateObj.year == endDateObj.year &&
                  startDateObj.month == endDateObj.month &&
                  startDateObj.day == endDateObj.day
                ) {
                  amIUnique = false;
                }
                if (amIUnique && !someDataAlreadyThere) {
                  entryDateArrToUse.push(startDateObj, endDateObj);
                } else {
                  if (!someDataAlreadyThere) {
                    entryDateArrToUse.push(startDateObj);
                  }
                }
              }
              if (
                this.editTier.type === "Time" ||
                this.editTier.type === "Date and Time"
              ) {
                let startDateToUse = dateFormat(
                  new Date(+ele.startDate.$date.$numberLong),
                  "hh:MM TT"
                );
                let endDateToUse = dateFormat(
                  new Date(+ele.endDate.$date.$numberLong),
                  "hh:MM TT"
                );
                let timeObj = {
                  id: index,
                  startTime: startDateToUse,
                  endTime: endDateToUse
                };
                let amIAlreadyHere = timeArray.some(
                  (time) =>
                    time.startTime == timeObj.startTime &&
                    time.endTime == timeObj.endTime
                );
                if (!amIAlreadyHere) {
                  timeArray.push(timeObj);
                  if (index > 0) {
                    let newErrorArray = [
                      {
                        name: `entry-startTime-${index}`,
                        display: "Entry Start Time",
                        error: false
                      },
                      {
                        name: `entry-endTime-${index}`,
                        display: "Entry End Time",
                        error: false
                      }
                    ];
                    this.errors.push(...newErrorArray);
                  }
                }
              }
            }
          });
          let entryDateLength = this.EntryDateToEdit.length;
          this.savedDates.splice(
            0,
            this.savedDates.length,
            ...entryDateArrToUse
          );
          this.entryTimeArray.splice(
            0,
            this.entryTimeArray.length,
            ...timeArray
          );
          this.editIndex++;
        }
      }
      if (
        this.editTier.password &&
        this.editTier.password.split("").length > 0
      ) {
        this.tierPassword = this.editTier.password;
        this.isThereAPassword = true;
      }
      this.errors.forEach((error) => {
        error.error = false;
        error.errors = [];
      });
      let currencyObj = {
        value: this.editTier.currencyType,
        placeholder: this.editTier.currencyType
      };
      this.tixStartNum = this.editTier.tixStartNum;
      this.tierBus.$emit("set-value-placeholder", currencyObj);
      let saleDateLength = this.saleDateToEdit.length;
      this.saleDateToEdit.splice(0, saleDateLength);
      this.saleDateToEdit.push(...tempArr);
    }
  },
  async mounted() {
    let self = this;
    let ticketTierDate = null;

    if (this.bus) {
      this.bus.$on("ticket-tier-data", (args) => {
        this.$emit("data", {
          id: this.tierData ? this.tierData.id : undefined,
          name: this.name,
          description: this.description,
          tixStartNum: this.tixStartNum,
          price: this.price ? parseFloat(this.price) : undefined,
          ccfee: parseFloat(this.ccFee),
          dfee: parseFloat(this.dFee),
          addfee: this.aFee ? parseFloat(this.aFee) : undefined,
          saleDates: this.saleDateToEdit,
          entryDates: this.EntryDateToEdit,
          venueAccess: this.venueAccess,
          type: this.type,
          password: this.tierPassword,
          currencyType: this.currencyType
        });
        if (!this.validate()) {
          if (this.optional < 1) {
            this.$emit("errored");
          } else {
            this.errors.forEach((e) => {
              e.error = false;
            });
          }
        } else {
          this.addTier();
        }
      });
      this.bus.$on("update-tier", (args) => {
        if (this.validate()) {
          if (args) this.updateTier();
          else this.addTier();
        }
      });
      this.bus.$on("save-tier-info", (args) => {
        let objToSend = {
          id: this.tierData ? this.tierData.id : undefined,
          name: this.name,
          description: this.description,
          password: this.tierPassword,
          tixStartNum: this.tixStartNum,
          currencyType: this.currencyType,
          price: this.price ? parseFloat(this.price) : undefined,
          ccfee: parseFloat(this.ccFee),
          dfee: parseFloat(this.dFee),
          addfee: this.aFee ? parseFloat(this.aFee) : undefined,
          saleDates: this.saleDateToEdit,
          entryDates: this.EntryDateToEdit,
          venueAccess: this.venueAccess,
          type: this.type
        };
        this.$emit("saveTierInfo", objToSend);
      });
      this.bus.$on("validate-tier-info", (args) => {
        if (this.editTier.id != "initial" && this.editTier.id != "addition")
          this.updateTier();
        else this.checkFields(args);
      });
    }
    if (
      this.editTier != undefined &&
      this.editTier.id != "initial" &&
      this.editTier.id != "addition"
    ) {
      this.fillEditData();
    }
  },
  async created() {
    this.$axios
      .post(`/planners/retrieve-tier-fees`, {
        event: this.$route.params.url
      })
      .then((response) => {
        if (response.status == 200) {
          let feeObj = response.data.fee_Obj;
          let ccFeeToUse =
            parseFloat(feeObj.feeData.ccFee.$numberDecimal) / 100;
          let feeArray = [];
          feeObj.ticketData.forEach((ticketFee) => {
            let objToInsert = {
              min: parseFloat(ticketFee.startAmount),
              max: parseFloat(ticketFee.endAmount),
              value: parseFloat(ticketFee.price)
            };
            feeArray.push(objToInsert);
          });
          this.TFeePriceRange = feeArray;
          this.ccfeeToUse = ccFeeToUse;
        }
      });
  }
};
</script>
