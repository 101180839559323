<template>
  <FormulateForm
    ref="form"
    v-model="formValues"
    @submit="handleSubmit"
    autocomplete="false"
  >
    <div class="row merch-item-form">
      <div class="col full">
        <!-- <FormulateInput
          type="checkbox"
          name="absorb"
          label="Planner Pays Fees"
          @input="recalculatePrices"
        /> -->
      </div>
      <div class="col half">
        <FormulateInput
          type="text"
          name="title"
          label="Product Title"
          placeholder="Product Title"
          validation="bail|required|max: 20"
          :disabled="formValues.isPrevious"
          :maxlength="20"
          help="LIMIT: 20 Characters"
          autocomplete="false"
        />
      </div>
      <div class="col half">
        <div class="image-input-wrapper">
          <FormulateInput
            type="image"
            name="image"
            label="Product Image"
            validation="required"
            autocomplete="false"
            v-on:error="handleError"
            :error="errorMessage"
          />
        </div>
      </div>
      <div class="row">
        <div class="col full variants-title">
          <h4>Product Variants</h4>
          <small>
            Product variants can be sizes, colors, or any other variations you
            see fit
          </small>
        </div>
        <FormulateInput
          v-if="!hideRepeatableGroups"
          type="group"
          name="variants"
          add-label="Add Variant"
          repeatable
          :minimum="1"
          #default="{index}"
          autocomplete="false"
        >
          <div class="row variant">
            <div class="col half">
              <FormulateInput
                type="text"
                name="name"
                label="Name"
                placeholder="Name"
                validation="bail|required|max:8"
                :maxlength="8"
                help="LIMIT: 8 Characters"
                autocomplete="false"
              />
            </div>
            <div class="col half">
              <FormulateInput
                type="money"
                name="price"
                label="Price"
                placeholder="Price"
                validation="required"
                @input="onPriceChange(arguments[0], formValues.variants[index])"
                autocomplete="false"
              />
            </div>
            <div class="col half">
              <FormulateInput
                type="number"
                name="quantity"
                label="Quantity"
                placeholder="Quantity"
                validation="required"
                autocomplete="false"
              />
            </div>
            <div class="col half">
              <FormulateInput
                  type="number"
                  name="addfee"
                  label="Add Fee"
                  placeholder="Add Fee"
                  autocomplete="false"
                  @input="onAddFeeChange(formValues.variants[index])"
              />
            </div>
            <div class="row">
              <div class="col">
                <FormulateInput
                  type="checkbox"
                  name="show_check_out_add_on"
                  label="Make available as an add-on/upsell at checkout."
                />
                <FormulateInput
                  type="checkbox"
                  name="do_not_show_in_event"
                  label="Do not display on event page."
                />
              </div>
            </div>
            <div class="row">
              <div class="col fee">
                <FormulateInput
                  type="number"
                  name="ccfee"
                  label="CC Fee"
                  placeholder="CC Fee"
                  disabled
                  tab-index="-1"
                  autocomplete="false"
                />
              </div>
              <div class="col fee">
                <FormulateInput
                  type="number"
                  name="tfee"
                  label="T Fee"
                  placeholder="T Fee"
                  disabled
                  tab-index="-1"
                  autocomplete="false"
                />
              </div>
              <div class="col fee">
                <FormulateInput
                  type="number"
                  name="taxes"
                  label="Taxes"
                  placeholder="Taxes"
                  disabled
                  tab-index="-1"
                  autocomplete="false"
                />
              </div>
              <div class="col fee">
                <FormulateInput
                  type="number"
                  name="take"
                  label="Planner Take"
                  placeholder="Planner Take"
                  disabled
                  tab-index="-1"
                  autocomplete="false"
                />
              </div>
              <div class="col fee">
                <FormulateInput
                  type="number"
                  name="total"
                  label="Buyer Total"
                  placeholder="Buyer Total"
                  disabled
                  tab-index="-1"
                  autocomplete="false"
                />
              </div>
            </div>
          </div>
        </FormulateInput>
      </div>

      <div class="save-button">
        <FormulateInput
          type="submit"
          :name="editingItem !== null ? 'Save Changes' : 'Save Product'"
          icon="plus"
        />
      </div>
    </div>
  </FormulateForm>
</template>

<script>
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faTimes);

export default {
  name: "ProductItemForm",
  components: {
    FontAwesomeIcon
  },
  props: {
    ccfee: String,
    tfee: Array,
    info: Object,
    editingItem: Object,
    buyerOrPlannerPaysFees: String
  },
  watch: {
    editingItem(value, oldValue) {
      if (value !== oldValue) {
        if (value !== null) {
          this.formValues = value;
          this.recalculatePrices();
        } else {
          this.clearForm();
          window.scroll({
            top: 1300,
            behavior: "smooth"
          });
        }
      }
    }
  },
  data() {
    return {
      hideRepeatableGroups: false,
      formValues: {
        title: "",
        image: null,
        absorb: false,
        variants: [
          {
            key: Date.now(),
            isNew: true,
            additional_options: false,
            event_additional_options: false
          }
        ],
        isNew: true,
        isChanged: false
      },
      errorMessage: ""
    };
  },
  computed: {
    taxRate() {
      return this.info.config.taxes
        .filter(t => t.applicableTo.includes("PRODUCTS"))
        .reduce((a, b) => a + Number(b.percentage) / 100, 0)
        .toFixed(2);
    }
  },
  methods: {
    arguments,
    clearForm() {
      this.formValues = {
        title: "",
        image: null,
        absorb: false,
        variants: [
          {
            key: Date.now(),
            isNew: true,
            additional_options: false,
            event_additional_options: false
          }
        ],
        isNew: true,
        isChanged: false
      };

      this.$refs.form.hideErrors();

      // workaround: this makes it so the repeatable group doesn't show errors after submission
      this.hideRepeatableGroups = true;
      this.$nextTick(() => {
        this.hideRepeatableGroups = false;
      });
    },
    handleError(errorMessage) {
      this.errorMessage = errorMessage;
    },
    async handleSubmit() {
      const isEditing = this.editingItem !== null;

      this.$emit("save-product", this.formValues);
      this.clearForm();

      if (isEditing) {
        this.$toast("Product updated!", { type: "success" });
      } else {
        this.$toast("Product added!", { type: "success" });
      }
    },
    uploadImage(img) {
      this.formValues.image = img;
      this.validateAt("image", this.formValues);
    },
    onAddFeeChange(variant){
      const fees = Number(variant.ccfee) + Number(variant.tfee);
      if (this.formValues.absorb) {
        this.$set(variant, "take", (Number(variant.price) + Number(variant.addfee) - fees).toFixed(2));
      } else {
        this.$set(variant, "take", (Number(variant.price) + Number(variant.addfee)).toFixed(2));
      }
      const total = (
          Number(variant.price) + (this.formValues.absorb ? 0 : fees) + Number(variant.addfee)
      ).toFixed(2);
      this.$set(variant, "total", total);
    },
    onPriceChange(price, variant) {
      this.$set(variant, "price", price);

      const taxes =
        (Number(price) +
          (this.buyerOrPlannerPaysFees === "buyerPaysFees"
            ? 0
            : Number(this.tfee))) *
        this.taxRate;

      this.$set(variant, "taxes", taxes.toFixed(2));

      this.$set(
        variant,
        "ccfee",
        (((Number(price) + taxes) * Number(this.ccfee)) / 100).toFixed(2)
      );
      // console.log(Number(this.tfee[0].price.$numberDecimal).toFixed(2));
      // Change tfee based on price
      for (let i = 0; i < this.tfee.length; i++) {
        let ele = this.tfee[i];
        if (Number(price) >= ele.min && Number(price) <= ele.max) {
          this.$set(
              variant,
              "tfee",
              Number(ele.Products ? ele.Products.$numberDecimal : ele.price.$numberDecimal).toFixed(2)
          );
        } else if (Number(price) === 0) {
          this.$set(variant, "tfee", "0.00");
        }
      }
      if (Number(price) >= 0.01 && Number(price) <= 9.99) {
        this.$set(
          variant,
          "tfee",
          Number(this.tfee[0].Products.$numberDecimal).toFixed(2)
        );
      } else if (Number(price) >= 10.0 && Number(price) <= 19.99) {
        this.$set(
          variant,
          "tfee",
          Number(this.tfee[1].Products.$numberDecimal).toFixed(2)
        );
      } else if (Number(price) >= 19.99) {
        this.$set(
          variant,
          "tfee",
          Number(this.tfee[2].Products.$numberDecimal).toFixed(2)
        );
      } else {
        this.$set(variant, "tfee", "0.00");
      }

      // if (Number(price) > 0) {
      //   this.$set(variant, "tfee", Number(this.tfee).toFixed(2));
      // } else {
      //   this.$set(variant, "tfee", "0.00");
      // }

      const fees = Number(variant.ccfee) + Number(variant.tfee);
      const total = (
        Number(variant.price) + (this.formValues.absorb ? 0 : fees) + Number(variant.addfee)
      ).toFixed(2);

      if (this.formValues.absorb) {
        this.$set(variant, "take", (Number(variant.price) + Number(variant.addfee) - fees).toFixed(2));
      } else {
        this.$set(variant, "take", (Number(variant.price) + Number(variant.addfee)).toFixed(2));
      }

      this.$set(variant, "total", total);
    },
    recalculatePrices() {
      for (const variant of this.formValues.variants) {
        let price = 0;

        if (variant.price.$numberDecimal) {
          price = variant.price.$numberDecimal;
        } else if (variant.price) {
          price = variant.price;
        } else if (!variant.price) {
          continue;
        }

        this.onPriceChange(price, variant);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.merch-item-form {
  .variants-title {
    margin-bottom: 16px;

    h4 {
      margin-bottom: 0;
    }
  }

  .variant {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    padding-right: 20px;
    margin-bottom: 20px;

    .input-wrapper {
      margin-bottom: 0;
    }

    .form-col {
      margin: 0.25em;
      width: 100%;

      &.name {
        width: calc(40% - 0.5em);
      }

      &.fee,
      &.price {
        width: calc(15% - 0.5em);
      }

      @media screen and (max-width: 800px) {
        &.name {
          width: calc(66.4% - 0.5em);
        }

        &.price {
          width: calc(33% - 0.5em);
        }

        &.fee {
          width: calc(33% - 0.5em);
        }
      }
    }
  }

  .add-variant {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .remove-variant {
    position: absolute;
    right: -5px;
    top: 42px;
    font-size: 24px;
    cursor: pointer;
  }

  .save-button {
    display: flex;
    width: 100%;
    margin-top: 20px;
    &::v-deep button {
      padding: 10px 0;
      width: 100%;
    }
  }

  .col {
    @media screen and (max-width: 800px) {
      &.quarter,
      &.half {
        width: 100% !important;
      }
    }
  }
}
</style>
