<template>
  <div class="popup-wrapper">
    <div class="popup" :id="'myPopup' + indexToUse">
      <!-- <font-awesome-icon v-if="popUpIcon != '' || popUpIcon != undefined" :icon='popUpIcon' class='popup-btn'/> -->
      <img src="/img/smallIcon.png" alt="Pop up Icon" class="popup-btn" />
      <p v-if="popUpText != '' || popUpText != undefined" :popUpText="popUpText">{{ popUpText }}</p>
      <span class="popuptext" :popUpMsg="popUpMsg">
        <p class="pop-up-msg">{{ popUpMsg }}</p>
      </span>
    </div>
  </div>
</template>

<style lang="less" scoped>
.popup-wrapper {
  display: inline-block;

  .popup-btn {
    width: 22px;
    height: 22px;
  }
  .popup {
    position: relative;
    display: inline-block;
    top: 5px;
    // cursor: pointer;

    .popuptext {
      visibility: hidden;
      //width: 160px;
      background-color: #555;
      color: #fff;
      text-align: left;
      border-radius: 6px;
      padding: 2px;
      position: absolute;
      z-index: 1000;
      bottom: 125%;
      left: 50%;
      margin-left: -80px;
      -webkit-animation: fadeIn 1s;
      animation: fadeIn 1s;
      background-image: linear-gradient(160deg, #4eb95e, #4a639e);
      .pop-up-msg {
        //width: 100%;
        //height: 100%;
        background-color: #222222;
        margin: 0px;
        border-radius: 5px;
        font-size: 14px !important;
        padding: 10px;
        overflow: hidden;
        width: 250px;
      }
    }
    .popuptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 29%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }
  }
  //end popup
  .popup .show {
    visibility: visible;
  }
  .popup:hover {
    cursor: pointer;
    .popuptext {
      visibility: visible;
    }
  }

  /* Add animation (fade in the popup) */
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
</style>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faCalendarAlt,
  faClock,
  faTimes,
  faPen,
  faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faAngleDown);
library.add(faCalendarAlt);
library.add(faClock);
library.add(faTimes);
library.add(faPen);
library.add(faQuestionCircle);

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    popUpMsg: String,
    popUpIcon: String,
    popUpText: String,
    indexToUse: Number
  },
  data() {
    return {
      popUpActive: false,
      popUpIconImg: "/public/img/smallIcon.png"
    };
  }
};
</script>
