<template>
  <div class="product-search">
    <div v-if="product !== null" class="selected">
      <div class="selected-product">
        <img
          :src="imageUrl + product.image_url"
          alt=""
          width="60px"
          height="60px"
        />
        <div class="title">
          <strong>
            <span>{{ product.title }}</span>
          </strong>
          <span>{{ product.variants.length }} variant(s)</span>
        </div>
        <div class="cancel">
          <diyobo-button txt="Cancel" type="primary" @click="onCancel" />
        </div>
      </div>
    </div>
    <div v-else class="search">
      <span>Search Previous Products</span>
      <diyobo-input
        class="search-bar"
        type="text"
        placeholder="Product Name"
        :val="search"
        v-model="search"
      />
      <div class="results">
        <div
          v-for="result in results"
          class="result"
          :key="result.id"
          @click="onSelect(result)"
        >
          <img
            :src="imageUrl + result.image_url"
            alt=""
            width="40px"
            height="40px"
          />
          <span class="title">{{ result.title }}</span>
        </div>
        <div v-if="results && results.length === 0" class="result">
          No Results Found
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "debounce";
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";

export default {
  name: "ProductSearch",
  components: {
    DiyoboButton,
    DiyoboInput
  },
  props: {
    items: Array
  },
  data() {
    return {
      search: "",
      results: null,
      product: null,
      imageUrl: process.env.VUE_APP_IMAGE_URL
    };
  },
  computed: {
    currentProductIds() {
      if (!this.items) {
        return [];
      }

      return this.items.map(p => p.id);
    }
  },
  watch: {
    search: debounce(function(value) {
      if (value === "") {
        this.results = null;
        return;
      }

      this.$axios
        .get("/products/search", {
          params: {
            search: value
          }
        })
        .then(response => {
          this.results = response.data.products.filter(
            p => !this.currentProductIds.includes(p.id)
          );
        });
    }, 250)
  },
  methods: {
    onSelect(product) {
      this.product = product;
      this.search = "";
      this.$emit("input", {
        ...product,
        variants: product.variants.map(v => ({ ...v }))
      });
    },
    onCancel() {
      this.product = null;
      this.$emit("input", null);
    }
  }
};
</script>

<style lang="less" scoped>
.product-search {
  .selected {
    margin-bottom: 40px;

    .selected-product {
      display: flex;
      align-items: center;

      img {
        min-width: 60px;
        height: 60px;
        object-fit: cover;
      }

      .title {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 20px;
      }

      .cancel {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .search {
    margin: 16px 0;

    .search-bar {
      margin: 16px 0 0;
    }
    .input-wrapper {
      margin-bottom: 0;
    }

    .results {
      padding: 15px;

      .result {
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px solid var(--border);

        img {
          min-width: 40px;
          height: 40px;
          object-fit: cover;
        }

        .title {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
