<template>
  <div class="added-tier">
    <tier-info-modal
      v-if="tierPopupActive"
      :info="tierPopup"
      :index="tierPopupIndex"
      :excludedDates="excludedDates"
      :timezone="timezone"
      v-on:close="closeTierDates"
      v-on:edit-tier="editPopupTier"
    />
    <div class="tier-wrapper" v-if="tier">
      <draggable
        v-model="rows"
        :list="tiers"
        :options="{ handle: '.inner-wrapper', filter: '.tier-num-wrapper' }"
        class="row wrap fill-height align-center sortable-list no-bullets draggable"
        tag="ul"
        v-bind="dragOptions"
        @end="removeGrab"
      >
        <li class="outer-wrapper" v-for="(tier1, index) in tiers" :key="index">
          <div class="tier-num-container">
            <div class="tier-num-wrapper">
              <div class="tier-num" :id="tier1.id">
                <p :id="tier1.id">{{ index + 1 }}</p>
              </div>
            </div>
          </div>
          <div
            class="inner-wrapper"
            v-on:mousedown="applyGrab"
            v-on:mouseup="removeGrab"
          >
            <div class="grab-mask"></div>
            <div
              class="times-icon"
              v-if="!anyTicketsSold(index)"
              v-on:click="removeTier(tier1, index)"
            >
              <font-awesome-icon icon="times" />
            </div>

            <div class="tier-title">
              <div class="tier-title">
                {{ tier1.start }}

                <span v-if="tier1.name.length >= 20" class="small-tier-name">
                  {{ tier1.name }}
                </span>
                <span v-if="tier1.name.length < 20" class="normal-tier-name">
                  {{ tier1.name }}
                </span>
              </div>
            </div>
            <div class="tier-info">
              <div class="sale-date">
                <p class="sale-title">On Sale:</p>
                <div class="sale-section first" v-if="!tier1.saleDates">
                  <p class="sale-info" v-if="tier1.start.$date">
                    {{ unixTierDate(tier1.sale_start) }} -
                    {{ unixTierDate(tier1.sale_end) }}
                  </p>
                  <p class="sale-info" v-if="!tier1.start.$date">
                    {{ tierDate(tier1.sale_start) }} -
                    {{ tierDate(tier1.sale_end) }}
                  </p>
                  <p class="sale-info">
                    {{ tierTime(tier1.sale_start) }} -
                    {{ tierTime(tier1.sale_end) }}
                  </p>

                  <p class="sale-tickets">
                    # of Tickets:
                    {{ tier1.quantity || tier1.totalTicketQuantity }}
                  </p>
                </div>
                <div
                  class="sale-section second"
                  v-if="
                    tier1.saleDates &&
                      tier1.saleDates.length == 1 &&
                      !tier1.saleDates[0].sale_start
                  "
                >
                  <!-- if we dont have either of the start date values, then we just say there are no dates selected -->
                  <template v-if="!tier1.saleDates[0].startDate">
                    <p class="sale-info">No Dates Selected</p>
                  </template>
                  <template v-else>
                    <p class="sale-info">
                      {{ tierDate(tier1.saleDates[0].startDate) }} -
                      {{ tierDate(tier1.saleDates[0].endDate) }}
                    </p>
                    <p class="sale-info">
                      {{ tierTime(tier1.saleDates[0].startDate) }} -
                      {{ tierTime(tier1.saleDates[0].endDate) }}
                    </p>
                  </template>
                  <p class="sale-tickets">
                    # of Tickets:
                    {{
                      tier1.type === "General Tier"
                        ? tier1.saleDates[0].qty
                        : tier1.totalTicketQuantity
                    }}
                  </p>
                </div>
                <div
                  class="sale-section third"
                  v-if="
                    tier1.saleDates &&
                      tier1.saleDates.length == 1 &&
                      tier1.saleDates[0].sale_start
                  "
                >
                  <p class="sale-info">
                    {{ unixTierDate(tier1.saleDates[0].sale_start) }} -
                    {{ unixTierDate(tier1.saleDates[0].sale_end) }}
                  </p>
                  <p class="sale-info">
                    {{ tierTime(tier1.saleDates[0].sale_start) }} -
                    {{ tierTime(tier1.saleDates[0].sale_end) }}
                  </p>
                  <p class="sale-tickets">
                    # of Tickets:
                    {{
                      tier1.type === "General Tier"
                        ? tier1.saleDates[0].qty
                        : tier1.totalTicketQuantity
                    }}
                  </p>
                </div>
                <div
                  class="sale-section"
                  v-if="tier1.saleDates && tier1.saleDates.length > 1"
                >
                  <tier-sale-date
                    :info="tier1.saleDates"
                    :tier="tier1"
                    :indexOfTier="index"
                    :key="index"
                  />
                </div>
              </div>
              <div class="price">
                <p v-if="tier1.addfee != null">
                  Price: ${{
                    Number(tier1.price).toFixed(2)
                  }}
                  &nbsp;&nbsp;&nbsp; Add Fee: ${{
                    Number(tier1.addfee).toFixed(2)
                  }}
                </p>
                <p v-if="tier1.addfee == null">
                  Price: ${{ Number(tier1.price).toFixed(2) }}
                </p>
              </div>
              <div class="added-tier-btn-ctn">
                <button
                  v-if="
                    !tier1.saleDates[0].sale_start &&
                      !tier1.saleDates[0].startDate
                  "
                  class="show-dates-btn"
                  v-on:click="editPopupTier(tier1, index)"
                >
                  Update Tier
                </button>
                <button
                  v-else
                  class="show-dates-btn"
                  v-on:click="showTierDates(index)"
                >
                  Show Info
                </button>
                <!-- <button class="edit-btn" v-on:click="$emit('editTier', tier1)">Edit Date</button> -->
              </div>
            </div>
          </div>
        </li>
        <!-- </transition-group> -->
      </draggable>
    </div>
  </div>
</template>

<style lang="less" scoped>
.normal-tier-name,
.small-tier-name {
  margin-top: 2rem;
}

.small-tier-name {
  font-size: 12px;
}
.added-tier {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  width: 100%;
  .no-bullets {
    list-style: none;
    padding: 0;
    span {
      display: flex;
      flex-flow: row wrap;
    }
  }
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    // background: #c8ebfb;
  }
  .list-group {
    min-height: 20px;
  }
  .list-group-item {
    cursor: move;
  }
  .list-group-item i {
    cursor: pointer;
  }
  //end transititions

  .tier-wrapper {
    .row {
      justify-content: center !important;
    }
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;

    .tier-num-container {
      width: 100%;
      display: flex;
      flex-flow: row wrap;

      .tier-num-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 55px;
        cursor: grab;

        .tier-num {
          width: 35px;
          color: white;
          height: 35px;
          border-radius: 20px;
          margin: 10px auto 8px auto;
          background-color: var(--primary-green);

          p {
            text-align: center;
            margin-top: 30%;
            line-height: 1.25;
          }
        }
      }
    }

    .outer-wrapper {
      position: relative;
      max-width: 279px;
      margin-right: 10px;
      outline: none;
      .inner-wrapper {
        position: relative;
        display: flex;
        // margin-right: 20px;
        margin-bottom: 20px;
        margin-top: 20px;
        flex-wrap: wrap;
        justify-content: center;
        // padding: 0 10px;
        max-width: 280px;
        width: 100%;
        height: 300px;
        max-height: 300px;
        -webkit-box-shadow: 0px 0px 19px 1px rgb(24 26 32);
        -moz-box-shadow: 0px 0px 19px 1px rgb(24 26 32);
        box-shadow: var(--dashboard-shadow);
        background-image: var(--dashboard-tiles);
        border-radius: 10px;
        border: 1px solid var(--dashboard-border);
        // &:-moz-drag-over > .shift-left {
        //   animation: shiftLeft 1s;
        //   background: ivory;
        // }
      }

      .grab-mask {
        z-index: 9;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: grab;
      }
      .grabbing-element {
        cursor: grabbing;
      }

      .moving-element {
        cursor: grabbing;
        animation: 0.5s infinite alternate tilting;
      }

      @keyframes tilting {
        from {
          transform: rotate(10deg);
        }
        to {
          transform: rotate(-10deg);
        }
      }

      .shift-left {
        animation: shiftLeft 1s forwards;
      }

      .shift-right {
        animation: shiftRight 1s forwards;
      }

      @keyframes shiftLeft {
        from {
          margin-left: inherit;
        }
        to {
          margin-left: 250px;
        }
      }

      @keyframes shiftRight {
        from {
          margin-left: inherit;
        }
        to {
          margin-right: 250px;
        }
      }
      .tier-title {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .tier-title {
          width: 100%;
          font-weight: bold;
          color: var(--primary-green);

          p {
            font-size: 18px !important;
          }
        }
      }
      //end tier-title
      .tier-info {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .sale-date {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;

          .sale-title {
            display: flex;
            justify-content: center;
            width: 100%;
          }

          .sale-section {
            margin-bottom: 10px;
            width: 100%;

            .sale-info,
            .sale-tickets {
              display: flex;
              justify-content: center;
              width: 100%;
              margin: 0px;
            }
          }
        }

        .price {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;

          .price-inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            div {
              width: 100%;
              text-align: center;
            }
          }
        }
        .added-tier-btn-ctn {
          margin: 15px;
          display: flex;
          flex-flow: column;
        }
        .edit-btn,
        .show-dates-btn {
          background-color: var(--primary-green);
          color: white;
          font-size: 15px;
          border-radius: 5px;
          border: none;
          width: 150px;
          height: 30px;
          padding: 5px;
          cursor: pointer;
          z-index: 10;
        }
        .edit-btn {
          margin-top: 10px;
        }
        .show-dates-btn {
          margin-bottom: 10px;
        }
      }
      //end tier-info
      .times-icon {
        font-size: 20px;
        position: absolute;
        top: 3px;
        right: 0px;
        cursor: pointer;
        z-index: 10;
        width: 30px;
        color: var(--text);
        height: 30px;
        text-align: center;
      }
      //end times-icon
      .sale-info-buttons {
        display: flex;
        justify-content: center;
        text-align: center;
        .sale-button {
          //margin-top: 7px;
          color: #4cac55;
          font-size: 34px;
        }
      }
    }

    .left-tier-mask {
      width: 50%;
      height: 100%;
      cursor: grab;
      //z-index: -1;
      position: absolute;
      display: inline-block;
      //top: 0;
      left: 0;
      z-index: 9;
      &:-moz-drag-enter {
        animation: shiftRight 1s;
      }
    }
    .right-tier-mask {
      display: inline-block;
      width: 50%;
      height: 100%;
      cursor: grab;
      //z-index: -1;
      position: absolute;
      //top: 0;
      left: 50%;
      z-index: 9;
      &:-moz-drag-over {
        margin-right: 50%;
      }
    }
  }
}
</style>

<script>
import draggable from "vuedraggable";
import TierSaleDate from "../TierSaleDate.vue";
import TierInfoModal from "../modals/TierInfoModal.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faCalendarAlt,
  faClock,
  faTimes,
  faCaretLeft,
  faCaretRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dayjs from "dayjs";
import UTC from "dayjs/plugin/utc";
import Timezones from "dayjs/plugin/timezone";

dayjs.extend(UTC);
dayjs.extend(Timezones);

library.add(faAngleDown);
library.add(faCalendarAlt);
library.add(faClock);
library.add(faTimes);
library.add(faCaretLeft);
library.add(faCaretRight);

export default {
  name: "addedTier",
  components: {
    DiyoboInput,
    FontAwesomeIcon,
    TierSaleDate,
    TierInfoModal,
    draggable
  },
  props: {
    tier: Boolean,
    date: Boolean,
    val: Array,
    bus: Object,
    excludedDates: Array,
    // index: Number,
    tiers: Array,
    timezone: String
  },
  data() {
    return {
      //bus: new Vue(),
      tierPopup: {},
      tierPopupIndex: -1,
      tierPopupActive: false,
      tierIndex: 0,
      saleDateIndexes: [],
      dragId: 0,
      drag: false
    };
  },
  computed: {
    rows() {
      let arrayToReturn = [];
      this.tiers.forEach((tier, index) => {
        arrayToReturn.push({
          index: index,
          items: [
            {
              title: tier.name
            }
          ]
        });
      });
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        forceFallback: true,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    removeTier(tier, index) {
      this.$emit("removeAddedTier", tier, index);
    },
    removeDate(index) {
      this.$emit("removeDate", index);
    },
    anyTicketsSold(index) {
      let tier = this.tiers[index];
      let sold = true;

      if (tier.type === "General Tier") {
        return tier.saleDates.some(s => s.start > 1);
      } else if (tier.type === "Date") {
        return tier.venueAccessDates.some(d => d.sold > 0);
      } else if (tier.type === "Date and Time") {
        return tier.venueAccessDates.some(d => d.times.some(t => t.sold > 0));
      }

      return sold;
    },
    unixTierDate(val) {
      if (typeof val == "object") {
        return dayjs
          .tz(dayjs(+val.$date.$numberLong).utc(), this.timezone)
          .format('"ddd, MMM D, YYYY"');
      } else {
        return dayjs
          .tz(dayjs(val).utc(), this.timezone)
          .format("ddd, MMM D, YYYY");
      }
    },
    tierDate(val) {
      if (typeof val == "string") {
        return dayjs
          .tz(dayjs(val).utc(), this.timezone)
          .format("ddd, MMM D, YYYY");
      } else {
        if (val)
          return dayjs
            .tz(dayjs(val).utc(), this.timezone)
            .format("ddd, MMM D, YYYY");
        // else return "baka-desu";
      }
    },
    tierTime(val) {
      if (typeof val == "object") {
        return dayjs
          .tz(dayjs(+val.$date.$numberLong).utc(), this.timezone)
          .format("hh:mm A");
      } else {
        return dayjs.tz(dayjs(val).utc(), this.timezone).format("hh:mm A");
      }
    },
    swapSaleInfo(indexOfSaleDate, modifier) {
      if (
        this.saleDateIndexes[indexOfSaleDate] + modifier <
        this.tiers[indexOfSaleDate].saleDates.length
      ) {
        if (this.saleDateIndexes[indexOfSaleDate] === 0)
          this.saleDateIndexes[indexOfSaleDate] += 1;
        else this.saleDateIndexes[indexOfSaleDate] += modifier;
      }
      let displayIndexElement = document.querySelector(
        "#sale-info-index-" + indexOfSaleDate
      );

      displayIndexElement.textContent =
        this.saleDateIndexes[indexOfSaleDate] + 1;
    },
    showTierDates(index) {
      let tier = this.tiers[index];
      this.tierPopup = tier;
      this.tierPopupIndex = index;
      this.tierPopupActive = true;
    },
    closeTierDates() {
      this.tierPopupActive = false;
      this.tierPopup = {};
    },
    editPopupTier(tier, index) {
      this.tierPopupActive = false;
      this.tierPopup = {};
      this.$emit("editTier", tier, index);
    },
    beginDrag(event) {
      //event.target.classList.add('moving-element')
      this.dragId = event.target.id.split("-")[2];
      //event.dataTransfer.setData("text/plain", event.target.id);
    },
    applyWiggle(event) {
      let parentEle;

      let allTiers = document.querySelectorAll(".inner-wrapper");
      for (let i = 0; i < allTiers.length; i++) {
        let elementToWiggle = allTiers[i];
        elementToWiggle.classList.add("moving-element");
      }
    },
    endWiggle(event) {
      let allMovingTiers = document.querySelectorAll(".moving-element");
      for (let i = 0; i < allMovingTiers.length; i++) {
        let stopWiggleElements = allMovingTiers[i];
        stopWiggleElements.classList.remove("moving-element");
      }
    },
    applyGrab(event) {
      event.target.classList.add("grabbing-element");
    },
    removeGrab(event) {
      let grabbedEle = document.querySelector(".grabbing-element");
      if (grabbedEle) {
        grabbedEle.classList.remove("grabbing-element");
      }
    }
  },
  mounted() {
    this.tiers.forEach((ele, index) => {
      this.saleDateIndexes.push(0);
    });
  }
};
</script>
