<template>
  <FormulateForm ref="form" v-model="formValues" @input="onInput">
    <h2>Submit Event</h2>
    <p class="b1">
      Welcome to INCREDEVENT (the "Service")! We are a merchant and trust account
      platform connecting event planners and ticket sellers with ticket buyers
      around the world. Through our platform users can create, find and purchase
      event tickets from planners and promoters ("planner", "promoter",
      "buyer").
    </p>
    <p class="b1">
      These
      <a href="/terms" target="_blank">
        Terms and Conditions
      </a>
      (
      <a href="/terms" target="_blank">"Terms"</a>
      ,
      <a href="/terms" target="_blank">"Terms and Conditions"</a>
      ,
      <a href="/terms" target="_blank">"Terms of Use"</a>
      ) govern your relationship with the
      <a href="https://incredevent.com" target="_blank">
        https://incredevent.com
      </a>
      website operated by INCREDEVENT ("us", "we", or "our"), and your
      purchasing agreement with the event planner. This includes all websites,
      domains, web pages, subdomains, and country level domain variants.
    </p>
    <p class="b1">
      Your access to and use of the Service is based on your acceptance of and
      compliance with these Terms. These
      <a href="/terms" target="_blank">Terms</a>
      apply to all visitors, users and others who access or use the Service. By
      accessing or using the Service you agree to be bound by these
      <a href="/terms" target="_blank">Terms</a>
      and accept all legal consequences. If you do not agree to these
      <a href="/terms" target="_blank">terms and conditions</a>
      , in whole or in part, please do not use the Service.
    </p>
    <div class="divider"></div>
    <!-- <div class="input-wrapper">
      <FormulateInput
        type="checkbox"
        name="hideFees"
        label="Do not show fees to the ticket buyer."
        label-class="not-required"
      />
    </div> -->
    <div class="input-wrapper">
      <!-- <FormulateInput
        type="checkbox"
        name="proofread"
        label="I would like a INCREDEVENT event expert to proof read my event for $10 (debited from ticket revenue)."
        label-class="not-required"
      /> -->
    </div>
    <div class="input-wrapper">
      <FormulateInput
        type="checkbox"
        name="hide"
        label="Hide my event from the INCREDEVENT Event List."
        label-class="not-required"
      />
    </div>
    <div class="input-wrapper">
      <FormulateInput
        type="checkbox"
        name="hide_in_brand"
        label="Hide my event from the Brand page."
        label-class="not-required"
      />
    </div>
    <div class="input-wrapper">
      <FormulateInput
        type="checkbox"
        name="agree"
        label="By submitting this event you agree to the Terms of Use (which you read and agreed to when you signed up).
                        And you're done! You can come back and edit the event any time."
        validation="required"
        label-class="required"
      />
    </div>
  </FormulateForm>
</template>

<script>
import debounce from "debounce";

export default {
  name: "Submit",
  props: {
    info: Object,
    errors: Object
  },
  data() {
    return {
      formValues: {
        hideFees: this.info.submit.hideFees || false,
        proofread: this.info.submit.proofread || false,
        hide: this.info.submit.hide || false,
        hide_in_brand: this.info.submit.hide_in_brand || false,
        agree: this.info.submit.agree || false
      }
    };
  },
  methods: {
    onInput: debounce(function() {
      this.$emit(
        "data",
        "submit",
        this.formValues,
        !this.$refs.form || this.$refs.form.hasErrors
      );
    }, 300)
  },
  mounted() {
    if (this.errors.submit) {
      this.$refs.form.showErrors();
    } else {
      // we specifically want to ensure that they have agreed to the TOS before allowing them to submit
      this.$emit("data", "submit", this.formValues, !this.formValues.agree);
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$emit("data", "submit", this.formValues, this.$refs.form.hasErrors);
    next();
  }
};
</script>
