<template>
  <FormulateForm
    ref="form"
    v-model="formValues"
    @input="onInput"
    autocomplete="false"
  >
    <!-- TODO: This option is now located in the CONFIG of CEC -->
    <!-- <FormulateInput
      type="checkbox"
      label="My event is guest list only. I will setup my guest list options after I've created my event, which can be
      found under 'Select Event Options' on my dashboard."
      label-class="not-required"
      v-model="formValues.isGuestListOnly"
    /> -->
    <template v-if="!formValues.isGuestListOnly">
      <!-- <TabTote
        style="margin-bottom: 24px"
        :tabs="tabs"
        v-model="tab"
        @click="tab = $event"
      /> -->
      <div v-show="tab === 0" class="ticket-tiers">
        <TicketTierForm
          id="ticket-tier-form"
          :info="info"
          :editing-item="editingItem"
          :is-multi-date="isMultiDate"
          :showSeatsIoField="showSeatsIoField"
          @save-tier="onTierSave"
          autocomplete="false"
        />
        <div class="added-tier-wrapper">
          <div class="added-tier-title">
            <h3>Sort Ticket Tiers</h3>
            <p class="b1">
              If you would like to re-order your ticket tiers please drag and
              drop the tiers to suit your needs. How easy is that? 👊
            </p>
            <p class="b1" v-if="formValues.addedTiers.length <= 0">
              Go create some tiers!
            </p>
          </div>
          <added-ticket-tier
            :tier="true"
            :tiers="formValues.addedTiers"
            :is-multi-date="isMultiDate"
            :bus="{}"
            :timezone="info.config.timezone"
            v-on:removeAddedTier="onDelete"
            v-on:editTier="onEdit"
          />
        </div>
      </div>
      <SocialDiscount
        v-show="tab === 1"
        :all-data.sync="formValues.socialDiscount"
        :tiers-added="formValues.addedTiers"
      />
    </template>
    <template v-else>
      <FormulateInput
        type="select"
        name="type"
        label="Select Event Type"
        placeholder="Select Event Type"
        validation="required"
        :options="types"
      />
    </template>
  </FormulateForm>
</template>

<script>
import TabTote from "@/components/TabTote.vue";
import TierSet from "../TierSet.vue";
import TicketTierForm from "../tickets/TicketTierForm.vue";
import AddedTicketTier from "../tickets/AddedTicketTiers";
import SocialDiscount from "../create/SocialDiscount.vue";
import debounce from "debounce";

export default {
  name: "TicketTiers",
  components: {
    SocialDiscount,
    TicketTierForm,
    AddedTicketTier,
    TabTote,
    TierSet
  },
  props: {
    info: Object,
    errors: Object
  },
  data() {
    return {
      tabs: ["Ticket Tiers", "Social Discounts"],
      tab: 0,
      editingItem: null,
      editingIndex: -69,
      types: [
        { label: "Live/In Person", value: "IN_PERSON" },
        { label: "Virtual Attendance", value: "VIRTUAL" },
        { label: "Hybrid", value: "HYBRID" }
      ],
      formValues: {
        ticketTiers: this.info.tiers.ticketTiers,
        addedTiers: this.info.tiers.addedTiers,
        isGuestListOnly: this.info.tiers.isGuestListOnly,
        // collectAttendeeInfo: this.info.tiers.collectAttendeeInfo,
        type: this.info.tiers.type,
        ticketType: this.info.tiers.ticketType,
        socialDiscount: {
          enable: false,
          tier: "",
          discountType: "$ Discount",
          discount: "",
          stackable: false,
          overridePromo: false,
          fb: true,
          tw: true,
          li: true,
          socialShareText: ""
        }
      }
    };
  },
  computed: {
    hasErrors() {
      const formErrors = this.$refs.form && this.$refs.form.hasErrors;
      const noTiers =
        !this.formValues.isGuestListOnly &&
        this.formValues.addedTiers.length === 0;

      return formErrors || noTiers;
    },
    isMultiDate() {
      return this.info.dates.frequency !== "SINGLE_DATE";
    },
    showSeatsIoField() {
      return this.info.config.eventType == "RESERVED_SEATS";
    }
  },
  methods: {
    // collectingAttendeeInfo() {
    //   this.formValues.collectAttendeeInfo = !this.formValues
    //     .collectAttendeeInfo;
    //   this.$emit(
    //     "data",
    //     "tiers",
    //     this.formValues.collectAttendeeInfo,
    //     this.hasErrors ? undefined : false
    //   );
    // },
    onTierSave(tier) {
      if (this.editingIndex > -1) {
        this.formValues.addedTiers.splice(this.editingIndex, 1, tier);
        this.editingItem = null;
        this.editingIndex = -1;
      } else {
        this.formValues.addedTiers.push(tier);
      }
    },
    onEdit(tier, index) {
      this.editingItem = { ...tier };
      this.editingIndex = index;

      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onDelete(tier, index) {
      this.formValues.addedTiers.splice(index, 1);
    },
    onInput: debounce(function() {
      this.$emit(
        "data",
        "tiers",
        this.formValues,
        this.hasErrors ? undefined : false
      );
    }, 300)
  },
  mounted() {
    if (this.errors.tiers) {
      this.$refs.form.showErrors();
    }

    if (this.info.tiers.socialDiscount) {
      this.formValues.socialDiscount = this.info.tiers.socialDiscount;
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$emit("data", "tiers", this.formValues, this.hasErrors);
    next();
  }
};
</script>
