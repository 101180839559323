<template>
  <FormulateForm ref="form" v-model="formValues" autocomplete="false">
    <h2>Products</h2>
    <div class="row create-wrapper">
      <h3 class="row">Add Previous Product</h3>
      <div class="col full">
        <ProductSearch
          ref="search"
          :items="formValues.products"
          @input="onSelectPreviousProduct"
        />
      </div>
      <template v-if="editingIndex !== -69">
        <div class="splitter row">or</div>
        <h3 class="add-new-product row">Add New Product</h3>
      </template>
      <ProductItemForm
        v-if="ccfee"
        class="product-item-form"
        :buyerOrPlannerPaysFees="buyerOrPlannerPaysFees"
        :ccfee="ccfee"
        :tfee="tfee"
        :info="info"
        :editing-item="editingItem"
        @save-product="onProductSaved"
      />
    </div>
    <div>
      <h3>Sort Product List</h3>
      <p class="b1">
        If you would like to re-order your products please drag and drop the
        products to suit your needs
      </p>
      <draggable
        class="merch-items"
        v-model="draggableProductList"
        :list="formValues.products"
        v-bind="dragOptions"
      >
        <ProductItem
          v-for="(product, index) in formValues.products"
          :item="product"
          :key="product.key"
          :index="index"
          @edit="onEdit(product, index)"
          @delete="onDelete(product, index)"
        />
      </draggable>
    </div>
  </FormulateForm>
</template>

<script>
import ProductItemForm from "../products/ProductItemForm.vue";
import ProductItem from "../products/ProductItem.vue";
import ProductSearch from "../products/ProductSearch.vue";
import debounce from "debounce";
import draggable from "vuedraggable";

export default {
  name: "Products",
  components: {
    ProductItemForm,
    ProductItem,
    ProductSearch,
    draggable
  },
  props: {
    info: Object,
    errors: Object
  },
  data() {
    return {
      buyerOrPlannerPaysFees: "",
      ccfee: "",
      tfee: "",
      editingItem: null,
      editingIndex: -1,
      search: "",
      formValues: {
        products: this.info.products.products
      }
    };
  },
  computed: {
    draggableProductList() {},
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        forceFallback: true,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    onProductSaved(product) {
      if (product.isNew && !product.isChanged) {
        this.formValues.products.push(product);
      } else if (this.editingIndex === -69) {
        this.formValues.products.push(product);
        this.$refs.search.onCancel();
        this.editingItem = null;
        this.editingIndex = -1;
      } else {
        this.formValues.products.splice(this.editingIndex, 1, product);
        this.editingItem = null;
        this.editingIndex = -1;
      }
    },
    onEdit(product, index) {
      product.isChanged = true;

      this.editingItem = {
        ...product,
        variants: product.variants.map(v => ({ ...v }))
      };
      this.editingIndex = index;

      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onDelete(product, index) {
      this.formValues.products.splice(index, 1);
    },
    onSelectPreviousProduct(product) {
      if (product === null) {
        this.editingItem = null;
        this.editingIndex = -1;
      } else {
        product.image = {
          image: process.env.VUE_APP_IMAGE_URL + product.image_url,
          imageName: product.image_name
        };
        product.isPrevious = true;

        this.editingItem = product;
        this.editingIndex = -69;
      }
    },
    onInput: debounce(function() {
      this.$emit(
        "data",
        "products",
        this.formValues,
        !this.$refs.form || this.$refs.form.hasErrors ? undefined : false
      );
    }, 300)
  },
  async created() {
    const { data } = await this.$axios.post("/planners/retrieve-tier-fees");
    this.buyerOrPlannerPaysFees = this.info.config.buyerOrPlannerPaysFees;

    // if (!data) {
    //   return;
    // }

    // something is wrong here.
    this.ccfee = data.fee_Obj.feeData.ccFee.$numberDecimal;
    this.tfee = data.fee_Obj.feeData.merchFee;
  },
  mounted() {
    if (this.errors.products) {
      this.$refs.form.showErrors();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$emit("data", "products", this.formValues, false);
    next();
  }
};
</script>

<style lang="less" scoped>
.create-wrapper {
  //padding: 20px;
  //width: calc(100% - 40px) !important;
  //border: 1px solid var(--dashboard-border);
  //border-radius: 5px;
  //box-shadow: var(--table-shadow);

  .product-item-form {
    margin: 16px 0;
  }

  .splitter {
    display: flex;
    justify-content: center !important;
    position: relative;
    font-weight: bold;
    padding: 10px;

    &::before {
      content: " ";
      display: block;
      position: absolute;
      top: 50%;
      right: 58%;
      width: 25%;
      border-bottom: 2px solid @colors[primary-green];
    }

    &::after {
      content: " ";
      display: block;
      position: absolute;
      top: 50%;
      left: 58%;
      width: 25%;
      border-bottom: 2px solid @colors[primary-green];
    }
  }
}

.merch-items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 414px) {
    flex-direction: column;
    align-items: center;
  }
}
</style>
