var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{ref:"form",attrs:{"autocomplete":"false"},on:{"input":_vm.onInput},model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}},[_c('h2',[_vm._v("Event Details")]),_c('FormulateInput',{attrs:{"type":"wysiwyg","name":"description","label":"Description","placeholder":"Description","validation":"required","autocomplete":"false"}}),_c('div',{staticClass:"col full"},[_c('FormulateInput',{attrs:{"type":"link","name":"videoLink","label":"Video Link","placeholder":"Video Link","autocomplete":"off","validation":"youtube","validation-rules":{
        youtube: function (ref) {
              var value = ref.value;

              return _vm.youtubeCheck(value);
}
      },"validation-messages":{
        youtube: 'Invalid YouTube/Vimeo link provided.'
      }}})],1),_c('div',{staticClass:"col full"},[_c('FormulateInput',{attrs:{"type":"link","name":"website","label":"Website Link","placeholder":"Website Link","autocomplete":"off","validation":"website-check","validation-rules":{
        'website-check': function (ref) {
              var value = ref.value;

              return _vm.websiteCheck(value);
}
      },"validation-messages":{
        'website-check': 'Invalid website provided.'
      }}})],1),_c('div',{staticClass:"col full"},[_c('FormulateInput',{attrs:{"type":"text","name":"sponsor","label":"Presenting Sponsor","placeholder":"Presenting Sponsor","autocomplete":"false"}})],1),_c('div',{staticClass:"col full"},[_c('FormulateInput',{attrs:{"type":"link","name":"sponsorLink","label":"Sponsor Link","placeholder":"Sponsor Link","autocomplete":"off","validation":"website-check","validation-rules":{
        'website-check': function (ref) {
              var value = ref.value;

              return _vm.websiteCheck(value);
}
      },"validation-messages":{
        'website-check': 'Invalid website provided.'
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }