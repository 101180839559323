<template>
  <FormulateForm
    ref="form"
    keep-model-data
    :debounce="100"
    v-model="formValues"
    @input="onInput"
    autocomplete="false"
  >
    <h2>Event Dates &amp; Times</h2>
    <div class="row">
      <div class="col half">
        <FormulateInput
          type="select"
          name="frequency"
          label="Set Frequency"
          placeholder="Set Frequency"
          validation="required"
          :options="frequencies"
          :disabled="isEditEvent || areChangesLocked"
        />
      </div>

      <div class="col half">
        <FormulateInput
          v-if="formValues.frequency === 'MONTHLY'"
          type="select"
          name="monthlyType"
          label="Date Type"
          placeholder="Select Date Type"
          validation="required"
          :options="monthlyOptions"
          :disabled="areChangesLocked"
        />
      </div>
      <div
        v-if="formValues.frequency === 'MULTI_DATE'"
        class="col full"
        style="margin-bottom: 16px"
      >
        Below you will set each date within a date range. So, if your event
        starts on Oct 1 and ends Oct 31, and you have 5 shows in between those
        dates, each of the 5 shows would have a start and end date which you
        list below. Just click on "Add Date" to add more.
      </div>
      <template v-if="formValues.frequency">
        <div v-if="usesDaySelectors" class="row">
          <template v-if="usesDayAndWeekSelectors">
            <div class="col half">
              <FormulateInput
                type="date"
                name="startDate"
                label="Start Date"
                placeholder="MM/DD/YYYY"
                validation="required"
                help="The day of the first occurrence of this event."
                :min-date="new Date()"
                :timezone="info.config.timezone"
                :disabled="areChangesLocked"
                autocomplete="false"
              />
            </div>
            <div class="col half">
              <FormulateInput
                type="date"
                name="endDate"
                label="End Date"
                placeholder="MM/DD/YYYY"
                validation="required"
                help="The day of the last occurrence of this event."
                :min-date="formValues.startDate || new Date()"
                :timezone="info.config.timezone"
                :disabled="areChangesLocked"
                autocomplete="false"
              />
              <p>
                <b>
                  Note: If your event carries over into the next date, please make
                  sure the date reflects that.
                </b>
              </p>
            </div>
            <div class="col full">
              <FormulateInput
                class="day-selector"
                :type="daySelectionType"
                name="days"
                validation="min:1,length|required"
                :label="
                  daySelectionType === 'radio' ? 'Select Day' : 'Select Day(s)'
                "
                :options="days"
                :disabled="areChangesLocked"
                autocomplete="false"
              />
            </div>
            <div v-if="usesWeekSelectors" class="col full">
              <FormulateInput
                class="day-selector"
                :type="weekSelectionType"
                name="weeks"
                validation="min:1,length|required"
                :label="
                  weekSelectionType === 'radio'
                    ? 'Select Week'
                    : 'Select Week(s) (Select which weeks this event will run each month)'
                "
                :options="weeks"
                :disabled="areChangesLocked"
                autocomplete="false"
              />
            </div>
          </template>
          <template v-else-if="usesDayNumbers">
            <div class="col full">
              <FormulateInput
                type="select"
                name="monthlyDay"
                label="Day of Month"
                placeholder="Select Day of Month"
                validation="required"
                :options="dayNumbers"
              />
            </div>
          </template>
          <div class="col third">
            <FormulateInput
              type="time"
              name="startTime"
              label="Start Time"
              placeholder="##:## PM"
              validation="required"
              :timezone="info.config.timezone"
              :disabled="areChangesLocked"
              autocomplete="false"
            />
          </div>
          <div class="col third">
            <FormulateInput
              type="select"
              name="endOffset"
              label="End Day"
              placeholder="Select End Day"
              validation="required"
              help="Example: 8pm - 2am is Next Day"
              :options="endOffsets"
              :disabled="areChangesLocked"
            />
          </div>
          <div class="col third">
            <FormulateInput
              type="time"
              name="endTime"
              label="End Time"
              placeholder="##:## PM"
              validation="required"
              :timezone="info.config.timezone"
              :disabled="areChangesLocked"
              autocomplete="false"
            />
          </div>
        </div>

        <FormulateInput
          v-else
          type="group"
          name="dates"
          add-label="Add Date"
          :repeatable="repeatable"
          :minimum="1"
          #default="{index}"
        >
          <div class="row">
            <div class="col half">
              <FormulateInput
                type="dateTime"
                name="startDate"
                label="Start Date"
                placeholder="MM/DD/YYYY ##:## PM"
                validation="required"
                :date-for-page="startDateForPage(index)"
                :min-date="new Date()"
                :timezone="info.config.timezone"
                :disabled="areChangesLocked"
                autocomplete="false"
              />
            </div>
            <div class="col half">
              <FormulateInput
                type="dateTime"
                name="endDate"
                label="End Date"
                placeholder="MM/DD/YYYY ##:## PM"
                validation="required"
                :min-date="minEndDate(index)"
                :timezone="info.config.timezone"
                :disabled="areChangesLocked"
                autocomplete="false"
              />
            </div>
          </div>
        </FormulateInput>
        <!-- 
        <FormulateInput
          v-else
          type="group"
          name="dates"
          add-label="Add Date"
          :repeatable="repeatable"
          :minimum="1"
          #default="{index}"
        >
          <div class="row">
            <div class="col half">
              <FormulateInput
                type="dateTime"
                name="start"
                label="Start Date"
                placeholder="MM/DD/YYYY ##:## PM"
                validation="required"
                :date-for-page="startDateForPage(index)"
                :min-date="new Date()"
                :timezone="info.config.timezone"
                :disabled="areChangesLocked"
                autocomplete="false"
              />
            </div>
            <div class="col half">
              <FormulateInput
                type="dateTime"
                name="end"
                label="End Date"
                placeholder="MM/DD/YYYY ##:## PM"
                validation="required"
                :min-date="minEndDate(index)"
                :timezone="info.config.timezone"
                :disabled="areChangesLocked"
                autocomplete="false"
              />
            </div>
          </div>
        </FormulateInput> -->
        <div
          v-if="
            $store.state.user.email === 'dhruvshah2000@hotmail.com' // For One of The Devs
          "
          class="row bottom-space"
        >
          <h4>Event Dates</h4>
          <div
            v-for="date in formValues.dates"
            class="col full"
            :key="date.startDate"
          >
            <span>{{ formatDate(date.startDate) }}</span>
            -
            <span>{{ formatDate(date.endDate) }}</span>
          </div>
        </div>
      </template>
    </div>
    <div class="row">
      <!--      <div class="col half">-->
      <FormulateInput
        type="checkbox"
        name="enable_pre_sale"
        label="Enable Presale Form: When selected the presale form will display until your ticket tiers go live."
        autocomplete="false"
      />
      <FormulateInput
        type="checkbox"
        name="hide_event_dates"
        label="Hide event dates."
        autocomplete="false"
      />
      <FormulateInput
        type="checkbox"
        name="replay"
        label="This is a replay event (not date specific)."
        autocomplete="false"
      />
      <!--      </div>-->
    </div>
  </FormulateForm>
</template>

<script>
import debounce from "debounce";
import dayjs from "dayjs";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import UTC from "dayjs/plugin/utc";
import Timezones from "dayjs/plugin/timezone";

dayjs.extend(AdvancedFormat);
dayjs.extend(UTC);
dayjs.extend(Timezones);

export default {
  name: "Date",
  props: {
    info: Object,
    errors: Object,
    isEditEvent: Boolean
  },
  data() {
    return {
      frequencies: [
        { label: "Single Date", value: "SINGLE_DATE" },
        { label: "Multi-Date", value: "MULTI_DATE" },
        { label: "Daily", value: "DAILY" },
        { label: "Weekly", value: "WEEKLY" },
        { label: "Monthly", value: "MONTHLY" }
      ],
      days: [
        { label: "Sunday", value: 0 },
        { label: "Monday", value: 1 },
        { label: "Tuesday", value: 2 },
        { label: "Wednesday", value: 3 },
        { label: "Thursday", value: 4 },
        { label: "Friday", value: 5 },
        { label: "Saturday", value: 6 }
      ],
      dayNumbers: new Array(31).fill(true).map((_, i) => ({
        label: dayjs()
          .month(0)
          .date(i + 1)
          .format("Do"),
        value: i + 1
      })),
      weeks: [
        { label: "1st", value: 0 },
        { label: "2nd", value: 1 },
        { label: "3rd", value: 2 },
        { label: "4th", value: 3 },
        { label: "5th", value: 4 }
      ],
      endOffsets: [
        { label: "Same Day", value: 0 },
        { label: "Next Day", value: 1 }
        // { label: "2nd Day", value: 2 },
        // { label: "3rd Day", value: 3 },
        // { label: "4th Day", value: 4 },
        // { label: "5th Day", value: 5 },
        // { label: "6th Day", value: 6 }
      ],
      monthlyOptions: [
        { label: "Day of Month", value: "DAY" },
        { label: "Day of Week", value: "WEEK" }
      ],
      formValues: {
        frequency: this.info.dates.frequency || "SINGLE_DATE",
        dates: this.info.dates.dates,
        days: this.info.dates.days,
        weeks: this.info.dates.weeks,
        startDate: this.info.dates.startDate,
        startTime: this.info.dates.startTime,
        endDate: this.info.dates.endDate,
        endTime: this.info.dates.endTime,
        endOffset: this.info.dates.endOffset,
        monthlyType: this.info.dates.monthlyType,
        monthlyDay: this.info.dates.monthlyDay,
        enable_pre_sale: this.info.dates.enable_pre_sale,
        hide_event_dates: this.info.dates.hide_event_dates,
        replay: this.info.dates.replay
      }
    };
  },
  computed: {
    repeatable() {
      return this.formValues.frequency === "MULTI_DATE";
    },
    usesDaySelectors() {
      return ["DAILY", "WEEKLY", "MONTHLY"].includes(this.formValues.frequency);
    },
    usesWeekSelectors() {
      return ["WEEKLY", "MONTHLY"].includes(this.formValues.frequency);
    },
    usesDayAndWeekSelectors() {
      return (
        this.formValues.frequency !== "MONTHLY" ||
        this.formValues.monthlyType === "WEEK"
      );
    },
    usesDayNumbers() {
      return (
        this.formValues.frequency === "MONTHLY" &&
        this.formValues.monthlyType === "DAY"
      );
    },
    daySelectionType() {
      return this.formValues.frequency === "DAILY" ? "checkbox" : "radio";
    },
    weekSelectionType() {
      return this.formValues.frequency === "WEEKLY" ? "checkbox" : "radio";
    },
    isMultiDate() {
      return this.formValues.frequency !== "SINGLE_DATE";
    },
    areChangesLocked() {
      return false;
      return this.info.tiers.addedTiers.some(t => t.type !== "General Tier");
    },
    // doing it on input would cause an infinite loop when updating the dates
    dateGenerationTrigger() {
      const values = this.formValues;
      return [
        values.frequency,
        values.days,
        values.weeks,
        values.monthlyDay,
        values.endOffset,
        values.endTime,
        values.startTime,
        values.startDate,
        values.endDate
      ].filter(Boolean);
    }
  },
  watch: {
    "formValues.frequency"(newVal, oldVal) {
      // this.formValues.days = null;

      if (oldVal === "SINGLE_DATE") {
      }

      if (newVal === "SINGLE_DATE") {
        this.formValues.dates = this.formValues.dates.slice(0, 1);
      }
    },
    dateGenerationTrigger() {
      if (this.$refs.form && !this.$refs.form.isLoading) {
        // workaround: manually calling hasValidationErrors due to it being async, which causes form.hasError not to
        // change in time for this check
        this.$refs.form.hasValidationErrors().then(hasErrors => {
          if (hasErrors) {
            this.formValues.dates.filter(d => d.id);
            return;
          }

          if (this.usesDaySelectors) {
            this.generateDates();
          }
        });
      }
    }
  },
  methods: {
    onInput: debounce(function() {
      this.$emit(
        "data",
        "dates",
        this.formValues,
        !this.$refs.form || this.$refs.form.hasErrors ? undefined : false
      );
    }, 300),
    generateDates() {
      if (
        !this.formValues.startDate ||
        !this.formValues.startTime ||
        !this.formValues.endDate ||
        !this.formValues.endTime
      ) {
        console.log("missing dates");
        return;
      }

      const startTime = dayjs("" + this.formValues.startTime);
      const endTime = dayjs("" + this.formValues.endTime);

      let eventStartDate = dayjs("" + this.formValues.startDate)
        .hour(startTime.hour())
        .minute(startTime.minute())
        .startOf("minute");

      let eventEndDate = dayjs("" + this.formValues.endDate)
        .hour(endTime.hour())
        .minute(endTime.minute())
        .startOf("minute");

      let startDate = eventStartDate.clone();
      let endDate = eventEndDate.clone();

      this.formValues.dates = this.formValues.dates.filter(d => d._id);

      let usedStartDates = [];

      if (this.formValues.dates.length > 0) {
        usedStartDates = this.formValues.dates.map(d =>
          d.startDate.toISOString()
        );
      }

      const days = eventEndDate.diff(eventStartDate, "days");
      const months = eventEndDate
        .endOf("month")
        .diff(eventStartDate.startOf("month"), "months");

      switch (this.formValues.frequency) {
        case "DAILY":
          if (!this.formValues.endOffset || !this.formValues.days) {
            console.log("missing days");
            return;
          }

          for (let i = 0; i < days; i++) {
            startDate = startDate
              .add(1, "day")
              .hour(startTime.hour())
              .minute(startTime.minute());

            endDate = startDate
              .add(parseInt(this.formValues.endOffset), "days")
              .hour(endTime.hour())
              .minute(endTime.minute());

            if (
              this.formValues.days.includes(startDate.day().toString()) &&
              !usedStartDates.includes(startDate.toISOString())
            ) {
              this.formValues.dates.push({
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString()
              });
            }
          }

          break;
        case "WEEKLY":
          if (
            !this.formValues.days ||
            !this.formValues.weeks ||
            !this.formValues.endOffset
          ) {
            console.log("missing weeks");
            return;
          }

          startDate = eventStartDate
            .startOf("month")
            .day(this.formValues.days)
            .hour(startTime.hour())
            .minute(startTime.minute());

          const dates = {
            [startDate.year()]: {
              [startDate.month()]: [startDate]
            }
          };

          // grab all the weeks that come before the end date
          while (
            (startDate = startDate.add(7, "days")).isBefore(eventEndDate)
          ) {
            if (!dates[startDate.year()]) {
              dates[startDate.year()] = {};
            }

            if (!dates[startDate.year()][startDate.month()]) {
              dates[startDate.year()][startDate.month()] = [];
            }

            dates[startDate.year()][startDate.month()].push(startDate);
          }

          for (const [year, months] of Object.entries(dates)) {
            for (const [month, dates] of Object.entries(months)) {
              for (let i = 0; i < dates.length; i++) {
                if (
                  !this.formValues.weeks.includes(i.toString()) ||
                  dates[i].isBefore(eventStartDate)
                ) {
                  continue;
                }

                endDate = dates[i]
                  .add(parseInt(this.formValues.endOffset), "days")
                  .hour(endTime.hour())
                  .minute(endTime.minute());

                if (!usedStartDates.includes(dates[i].toISOString())) {
                  this.formValues.dates.push({
                    startDate: dates[i].toISOString(),
                    endDate: endDate.toISOString()
                  });
                }
              }
            }
          }

          break;
        case "MONTHLY":
          if (this.formValues.monthlyType === "DAY") {
            if (!this.formValues.endOffset) {
              console.log("missing day monthly");
              return;
            }

            startDate = startDate
              .date(this.formValues.monthlyDay)
              .hour(startTime.hour())
              .minute(startTime.minute());

            for (let i = 0; i < months; i++) {
              startDate = startDate.add(1, "month");
              endDate = startDate
                .add(this.formValues.endOffset, "days")
                .hour(endTime.hour())
                .minute(endTime.minute());

              if (!usedStartDates.includes(startDate.toISOString())) {
                this.formValues.dates.push({
                  startDate: startDate.toISOString(),
                  endDate: endDate.toISOString()
                });
              }
            }
          } else {
            if (
              !this.formValues.days ||
              !this.formValues.weeks ||
              !this.formValues.endOffset
            ) {
              console.log("missing week monthly");
              return;
            }

            startDate = eventStartDate
              .startOf("month")
              .day(this.formValues.days);

            const dates = {
              [startDate.year()]: {
                [startDate.month()]: [startDate]
              }
            };

            // grab all the weeks that come before the end date
            while (
              (startDate = startDate.add(7, "days")).isBefore(eventEndDate)
            ) {
              if (!dates[startDate.year()]) {
                dates[startDate.year()] = {};
              }

              if (!dates[startDate.year()][startDate.month()]) {
                dates[startDate.year()][startDate.month()] = [];
              }

              dates[startDate.year()][startDate.month()].push(startDate);
            }

            for (const [year, months] of Object.entries(dates)) {
              for (const [month, dates] of Object.entries(months)) {
                for (let i = 0; i < dates.length; i++) {
                  if (
                    !this.formValues.weeks.includes(i.toString()) ||
                    dates[i].isBefore(eventStartDate)
                  ) {
                    continue;
                  }

                  endDate = dates[i]
                    .add(this.formValues.endOffset, "days")
                    .hour(endTime.hour())
                    .minute(endTime.minute());

                  if (!usedStartDates.includes(dates[i].toISOString())) {
                    this.formValues.dates.push({
                      startDate: dates[i].toISOString(),
                      endDate: endDate.toISOString()
                    });
                  }
                }
              }
            }
          }

          break;
      }
    },
    formatDate(date) {
      return dayjs
        .tz(dayjs(date).utc(), this.info.config.timezone)
        .format("ddd, MMM DD, YYYY, hh:mm A");
    },
    startDateForPage(index) {
      if (index > 0 && this.formValues.dates[index - 1].startDate) {
        return this.formValues.dates[index - 1].startDate;
      } else {
        return new Date();
      }
    },
    minEndDate(index) {
      if (
        this.formValues.dates[index] &&
        this.formValues.dates[index].startDate
      ) {
        return this.formValues.dates[index].startDate;
      } else {
        return new Date();
      }
    }
  },
  mounted() {
    if (this.errors.dates) {
      this.$refs.form.showErrors();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$emit("data", "dates", this.formValues, this.$refs.form.hasErrors);

    next();
  }
};
</script>

<style lang="less" scoped>
// NOTE FOR THE FUTURE:
// We probably want these styles to always apply for groups

.pre-sale {
  display: flex;
  align-items: center;
}
.day-selector &::v-deep {
  display: flex;
  flex-direction: column;

  .formulate-input-group {
    display: flex;
    gap: 16px;

    .formulate-input {
      width: auto;
    }
  }
}
.bottom-space {
  margin-bottom: 20px !important;
}
</style>

<style>
.vc-popover-content-wrapper {
  z-index: 1000 !important;
}
</style>
