<template>
  <div class="uploader-wrapper">
    <div v-if="popUpLabel" class="title-ctn">
      <label
        v-if="popUpLabel"
        class="inline-label b1"
        :class="{ error: error }"
      >
        {{ popUpLabel }}
      </label>
      <!-- <pop-ups-for-icons
        v-if="popUpContent"
        :indexToUse="componentCount"
        :popUpIcon="'question-circle'"
        :popUpText="''"
        :popUpMsg="popUpContent"
      /> -->
    </div>
    <div v-if="popUpLabel" class="title-spacer"></div>
    <div
      class="hero-img-wrapper"
      v-if="type == 'hero' || type == 'primary' || type == 'secondary'"
    >
      <div class="hero-img edit-event-wrapper">
        <div class="title">
          <h3>Cover Photo</h3>
          <p class="error-msg" id="error-msg">
            File type not supported, please use one of the following. JPG, JPEG,
            PNG
          </p>
        </div>
        <div
          class="hero-file-upload-wrapper"
          :id="`hero-file-upload-wrapper-${indexToUse}`"
          @click="showUploader = true"
        >
          <img :src="images.hero" />
          <div
            class="hero-file-upload-container"
            :id="`hero-container-${indexToUse}`"
          >
            <font-awesome-icon class="hero-img-icon" icon="images" />
            <input
              type="file"
              name="file"
              :id="`hero-file-${indexToUse}`"
              class="hero-img-upload"
            />
            <label for="file" v-on:click="showUploader = true">
              Click Here
            </label>
          </div>
        </div>
      </div>
      <!-- <uploader
        v-model="showUploader"
        :width="1100"
        :height="618"
        no-circle
        langType="en"
        :langExt="{
          hint: 'Click Here (min size: 1100 x 618)'
        }"
        @crop-success="onHeroImageSelected(arguments[0])"
      /> -->
      <uploader
        v-model="showUploader"
        :width="requiredWidth"
        :height="requiredHeight"
        no-circle
        langType="en"
        :langExt="langExtDisplay"
        @crop-success="onHeroImageSelected(arguments[0])"
      />
    </div>
    <div class="title" v-if="type != 'venue'">
      <h3>Additional Images</h3>
      <p class="add-error-msg" id="add-error-msg">
        File type not supported, please use one of the following. JPG, JPEG, PNG
      </p>
    </div>
    <div v-if="type == `primary`" class="small-img-wrapper">
      <div class="small-img">
        <div class="file-upload-wrapper">
          <img id="small-image-1" class="small-image" />
          <div class="file-upload-container" id="file-container-1">
            <font-awesome-icon
              v-on:click="
                smallImg(
                  $event,
                  `file-container-1`,
                  `small-file-1`,
                  `small-image-1`,
                  'small-remove-icon-1'
                )
              "
              class="img-icon"
              icon="images"
            />
            <input
              type="file"
              name="file"
              accept="image/*"
              id="small-file-1"
              class="img-upload"
            />
            <label
              for="file"
              v-on:click="
                smallImg(
                  $event,
                  `file-container-1`,
                  `small-file-1`,
                  `small-image-1`,
                  'small-remove-icon-1'
                )
              "
            >
              Click Here
            </label>
          </div>
          <font-awesome-icon
            v-on:click="
              removeImg(
                'small-image-1',
                'file-container-1',
                'small-remove-icon-1'
              )
            "
            class="remove-icon"
            id="small-remove-icon-1"
            icon="times"
          />
        </div>
      </div>
      <div class="small-img">
        <div class="file-upload-wrapper">
          <img id="small-image-2" class="small-image" src />
          <div class="file-upload-container" id="file-container-2">
            <font-awesome-icon
              v-on:click="
                smallImg(
                  $event,
                  `file-container-2`,
                  `small-file-2`,
                  `small-image-2`,
                  'small-remove-icon-2'
                )
              "
              class="img-icon"
              icon="images"
            />
            <input
              type="file"
              name="file"
              accept="image/*"
              id="small-file-2"
              class="img-upload"
            />
            <label
              for="file"
              v-on:click="
                smallImg(
                  $event,
                  `file-container-2`,
                  `small-file-2`,
                  `small-image-2`,
                  'small-remove-icon-2'
                )
              "
            >
              Click Here
            </label>
          </div>
          <font-awesome-icon
            v-on:click="
              removeImg(
                'small-image-2',
                'file-container-2',
                'small-remove-icon-2'
              )
            "
            class="remove-icon"
            id="small-remove-icon-2"
            icon="times"
          />
        </div>
      </div>
      <div class="small-img">
        <div class="file-upload-wrapper">
          <img id="small-image-3" class="small-image" src />
          <div class="file-upload-container" id="file-container-3">
            <font-awesome-icon
              v-on:click="
                smallImg(
                  $event,
                  `file-container-3`,
                  `small-file-3`,
                  `small-image-3`,
                  'small-remove-icon-3'
                )
              "
              class="img-icon"
              icon="images"
            />
            <input
              type="file"
              name="file"
              id="small-file-3"
              class="img-upload"
              accept="image/*"
            />
            <label
              for="file"
              v-on:click="
                smallImg(
                  $event,
                  `file-container-3`,
                  `small-file-3`,
                  `small-image-3`,
                  'small-remove-icon-3'
                )
              "
            >
              Click Here
            </label>
          </div>
          <font-awesome-icon
            v-on:click="
              removeImg(
                'small-image-3',
                'file-container-3',
                'small-remove-icon-3'
              )
            "
            class="remove-icon"
            id="small-remove-icon-3"
            icon="times"
          />
        </div>
      </div>
      <div class="small-img">
        <div class="file-upload-wrapper">
          <img id="small-image-4" class="small-image" src />
          <div class="file-upload-container" id="file-container-4">
            <font-awesome-icon
              v-on:click="
                smallImg(
                  $event,
                  `file-container-4`,
                  `small-file-4`,
                  `small-image-4`,
                  'small-remove-icon-4'
                )
              "
              class="img-icon"
              icon="images"
            />
            <input
              type="file"
              name="file"
              id="small-file-4"
              class="img-upload"
              accept="image/*"
            />
            <label
              for="file"
              v-on:click="
                smallImg(
                  $event,
                  `file-container-4`,
                  `small-file-4`,
                  `small-image-4`,
                  'small-remove-icon-4'
                )
              "
            >
              Click Here
            </label>
          </div>
          <font-awesome-icon
            v-on:click="
              removeImg(
                'small-image-4',
                'file-container-4',
                'small-remove-icon-4'
              )
            "
            class="remove-icon"
            id="small-remove-icon-4"
            icon="times"
          />
        </div>
      </div>
      <div class="small-img">
        <div class="file-upload-wrapper">
          <img id="small-image-5" class="small-image" src />
          <div class="file-upload-container" id="file-container-5">
            <font-awesome-icon
              v-on:click="
                smallImg(
                  $event,
                  `file-container-5`,
                  `small-file-5`,
                  `small-image-5`,
                  'small-remove-icon-5'
                )
              "
              class="img-icon"
              icon="images"
            />
            <input
              type="file"
              name="file"
              id="small-file-5"
              class="img-upload"
              accept="image/*"
            />
            <label
              for="file"
              v-on:click="
                smallImg(
                  $event,
                  `file-container-5`,
                  `small-file-5`,
                  `small-image-5`,
                  'small-remove-icon-5'
                )
              "
            >
              Click Here
            </label>
          </div>
          <font-awesome-icon
            v-on:click="
              removeImg(
                'small-image-5',
                'file-container-5',
                'small-remove-icon-5'
              )
            "
            class="remove-icon"
            id="small-remove-icon-5"
            icon="times"
          />
        </div>
      </div>
      <div class="small-img">
        <div class="file-upload-wrapper">
          <img id="small-image-6" class="small-image" src />
          <div class="file-upload-container" id="file-container-6">
            <font-awesome-icon
              v-on:click="
                smallImg(
                  $event,
                  `file-container-6`,
                  `small-file-6`,
                  `small-image-6`,
                  'small-remove-icon-6'
                )
              "
              class="img-icon"
              icon="images"
            />
            <input
              type="file"
              name="file"
              id="small-file-6"
              class="img-upload"
              accept="image/*"
            />
            <label
              for="file"
              v-on:click="
                smallImg(
                  $event,
                  `file-container-6`,
                  `small-file-6`,
                  `small-image-6`,
                  'small-remove-icon-6'
                )
              "
            >
              Click Here
            </label>
          </div>
          <font-awesome-icon
            v-on:click="
              removeImg(
                'small-image-6',
                'file-container-6',
                'small-remove-icon-6'
              )
            "
            class="remove-icon"
            id="small-remove-icon-6"
            icon="times"
          />
        </div>
      </div>
    </div>
    <div v-if="type == 'venue'" class="venue-img-wrapper">
      <div class="hero-img edit-event-wrapper">
        <div class="title">
          <h3>Cover Photo *</h3>
          <p class="error-msg" id="error-msg">
            File type not supported, please use one of the following. JPG, JPEG,
            PNG
          </p>
        </div>
        <div
          class="hero-file-upload-wrapper"
          :id="`hero-file-upload-wrapper-${indexToUse}`"
          @click="showUploader = true"
        >
          <img :src="images.hero" />
          <div
            class="hero-file-upload-container"
            :id="`hero-container-${indexToUse}`"
          >
            <font-awesome-icon class="hero-img-icon" icon="images" />
            <input
              type="file"
              name="file"
              :id="`hero-file-${indexToUse}`"
              class="hero-img-upload"
            />
            <label for="file" v-on:click="showUploader = true">
              Click Here
            </label>
          </div>
        </div>
      </div>
      <uploader
        v-model="showUploader"
        :width="1100"
        :height="300"
        no-circle
        langType="en"
        :langExt="{
          hint: 'Click Here (min size: 1100 x 300)'
        }"
        @crop-success="onHeroImageSelected(arguments[0])"
      />
    </div>
  </div>
</template>

<style lang="less" scoped>
.uploader-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: flex-end;

  .title-ctn {
    position: absolute;
    left: 0px;
    top: 5%;
    .inline-label {
      font-size: 0.98em;
      text-transform: capitalize;
      display: inline-block;
      margin-right: 5px;
    }
    .inline-label.error {
      color: #e60022 !important;
    }
  }
  .title-spacer {
    width: 100%;
    height: 66px;
  }
  // @media screen and (max-width: 695px) {
  //     height: 750px;
  // }

  // @media screen and (max-width: 400px) {
  //     height: 900px;
  // }
  .title {
    width: 100%;
    margin-top: 65px;

    .error-msg {
      color: rgba(230, 0, 34, 0.5);
      position: absolute;
      top: 150px;
      display: none;
    }

    .add-error-msg {
      color: rgba(230, 0, 34, 0.5);
      display: none;
    }
  }

  .hero-img-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    aspect-ratio: 16/9;
    margin-bottom: 40px;
    user-select: none;

    .hero-img {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;

      .error-msg {
        text-align: left;
      }

      .title {
        margin: 0px;
      }

      .hero-file-upload-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        aspect-ratio: 16/9;
        position: relative;
        //border: rgba(91, 91, 91, .2) dashed 2px;
        border: #807b7b dashed 2px;
        transition: 500ms;
        overflow: hidden;
        cursor: pointer;

        &:hover {
          border: #44b678 dashed 2px;
        }

        img {
          height: -webkit-fill-available;
          width: 100%;
          z-index: 1;
        }

        .image-canvas {
          position: absolute;
          display: none;
          width: 100%;
          height: 100%;
        }

        .hero-image {
          display: none;
          width: 100%;
          height: 100%;
          // object-fit: cover;
        }
        // end hero-image

        .hero-image-edit {
          display: none;
          position: absolute;
          right: 10px;
          bottom: 10px;
          background-color: #5b5b5b;
          padding: 0.5em 0;
          pointer-events: none;
          user-select: none;
          -webkit-user-select: none; /* webkit (safari, chrome) browsers */
          -moz-user-select: none; /* mozilla browsers */
          -khtml-user-select: none; /* webkit (konqueror) browsers */
          -ms-user-select: none; /* IE10+ */

          & > * {
            padding: 0 0.5em;
            pointer-events: auto;

            &:hover,
            :disabled {
              color: rgb(156, 156, 156);
              cursor: pointer;
            }
          }

          .zoom-percent {
            font-size: 0.77em;
            pointer-events: none;
          }
        }

        .hero-file-upload-container {
          position: absolute;
          display: flex;
          justify-content: center;
          margin: auto;
          width: 100%;
          cursor: pointer;

          .hero-img-upload {
            display: none;
          }
          // end hero-img-upload

          .hero-img-upload + label {
            display: flex;
            justify-content: center;
            color: #5b5b5b;
            align-items: center;
            font-size: 12px;
            width: 75%;
            height: 340px;
            cursor: inherit;
          }
          // end hero-img-upload + label

          .hero-img-icon {
            position: absolute;
            top: 43%;
            color: #5b5b5b;
            z-index: -1;
            cursor: inherit;
          }
          // end hero-img-icon
        }
        // end hero-file-upload-container
      }
      // end hero-file-upload-wrapper
    }

    &::v-deep .vue-image-crop-upload {
      .vicp-wrap {
        width: 80%;
        max-width: 600px;
        background-color: var(--event-background);
        border-radius: 8px;
        padding: 20px;
        height: fit-content;

        .vicp-crop {
          display: flex;
          flex-wrap: wrap;
          width: 96%;
          justify-content: space-between;
          // align-items: center;
        }
        .vicp-operate a {
          width: initial;
          padding: 0 15px;
        }
        .vicp-preview {
          .vicp-preview-item {
            span {
              bottom: 0;
            }
          }
        }
        .vicp-crop-left,
        .vicp-crop-right {
          float: initial;
        }
        .vicp-drop-area {
          background-color: @colors[content-background];
          padding-left: 0;
          padding-right: 0;
          border-radius: 8px;

          .vicp-icon1 {
            filter: var(--uploader-filter);
          }

          .vicp-hint {
            color: @colors[text];
          }
        }
      }
      @media screen and (max-width: 600px) {
        &::v-deep .vue-image-crop-upload {
          .vicp-wrap {
            .vicp-crop-left,
            .vicp-crop-right {
              float: initial;
            }
          }
        }
      }
    }
  }
  // end hero-img-wrapper
  .venue-img-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    // height: 340px;
    // margin-bottom: 40px;
    user-select: none;

    .hero-img {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;

      .error-msg {
        text-align: left;
      }

      .title {
        margin: 0px;
      }

      .hero-file-upload-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 340px;
        width: 100%;
        position: relative;
        //border: rgba(91, 91, 91, .2) dashed 2px;
        border: #807b7b dashed 2px;

        align-items: center;
        transition: 500ms;
        overflow: hidden;
        cursor: pointer;

        &:hover {
          border: #44b678 dashed 2px;
        }

        img {
          width: 100%;
          z-index: 1;
        }

        .image-canvas {
          position: absolute;
          display: none;
          width: 100%;
          height: 100%;
        }

        .hero-image {
          display: none;
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
        // end hero-image

        .hero-image-edit {
          display: none;
          position: absolute;
          right: 10px;
          bottom: 10px;
          background-color: #5b5b5b;
          padding: 0.5em 0;
          pointer-events: none;
          user-select: none;
          -webkit-user-select: none; /* webkit (safari, chrome) browsers */
          -moz-user-select: none; /* mozilla browsers */
          -khtml-user-select: none; /* webkit (konqueror) browsers */
          -ms-user-select: none; /* IE10+ */

          & > * {
            padding: 0 0.5em;
            pointer-events: auto;

            &:hover,
            :disabled {
              color: rgb(156, 156, 156);
              cursor: pointer;
            }
          }

          .zoom-percent {
            font-size: 0.77em;
            pointer-events: none;
          }
        }

        .hero-file-upload-container {
          position: absolute;
          display: flex;
          justify-content: center;
          margin: auto;
          width: 100%;
          cursor: pointer;

          .hero-img-upload {
            display: none;
          }
          // end hero-img-upload

          .hero-img-upload + label {
            display: flex;
            justify-content: center;
            color: #5b5b5b;
            align-items: center;
            font-size: 12px;
            width: 75%;
            height: 340px;
            cursor: inherit;
          }
          // end hero-img-upload + label

          .hero-img-icon {
            position: absolute;
            top: 43%;
            color: #5b5b5b;
            z-index: -1;
            cursor: inherit;
          }
          // end hero-img-icon
        }
        // end hero-file-upload-container
      }
      // end hero-file-upload-wrapper
    }

    &::v-deep .vue-image-crop-upload {
      .vicp-wrap {
        width: 80%;
        max-width: 600px;
        background-color: var(--event-background);
        border-radius: 8px;
        padding: 20px;
        height: fit-content;

        .vicp-crop {
          display: flex;
          flex-wrap: wrap;
          width: 96%;
          justify-content: space-between;
          // align-items: center;
        }
        .vicp-operate a {
          width: initial;
          padding: 0 15px;
        }
        .vicp-preview {
          .vicp-preview-item {
            span {
              bottom: 0;
            }
          }
        }
        .vicp-crop-left,
        .vicp-crop-right {
          float: initial;
        }
        .vicp-drop-area {
          background-color: @colors[content-background];
          border-radius: 8px;

          .vicp-icon1 {
            filter: var(--uploader-filter);
          }

          .vicp-hint {
            color: @colors[text];
          }
        }
      }
      @media screen and (max-width: 600px) {
        &::v-deep .vue-image-crop-upload {
          .vicp-wrap {
            .vicp-crop-left,
            .vicp-crop-right {
              float: initial;
            }
          }
        }
      }
    }

    .hero-img.edit-event-wrapper {
      width: 833px;
    }
    // end hero-img
  }
  // .venue-img-wrapper {
  //   display: flex;
  //   justify-content: center;
  //   width: 100%;
  //   height: 340px;
  //   user-select: none;
  //   margin-bottom: 1.5em;

  //   .venue-img {
  //     display: flex;
  //     flex-wrap: wrap;
  //     align-items: center;
  //     width: 100%;

  //     .error-msg {
  //       color: rgba(230, 0, 34, 0.5);
  //       display: none;
  //     }

  //     .venue-file-upload-wrapper {
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       height: 100%;
  //       width: 100%;
  //       position: relative;
  //       border-radius: 5px;
  //       //border: rgba(91, 91, 91, .2) dashed 2px;
  //       border: #393e47 dashed 2px;
  //       align-items: center;
  //       transition: 500ms;
  //       background: #202025;

  //       &:hover {
  //         //border: rgba(91, 91, 91, .5) dashed 2px;
  //         border: #5c5c6a dashed 2px;
  //       }

  //       .venue-image-canvas {
  //         position: absolute;
  //         display: none;
  //         width: 100%;
  //         height: 100%;
  //       }

  //       .venue-image {
  //         display: none;
  //         width: 100%;
  //         height: 100%;
  //         object-fit: cover;
  //       }
  //       // end hero-image

  //       .venue-image-edit {
  //         display: none;
  //         position: absolute;
  //         right: 10px;
  //         bottom: 10px;
  //         background-color: #5b5b5b;
  //         padding: 0.5em 0;
  //         pointer-events: none;
  //         user-select: none;
  //         -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  //         -moz-user-select: none; /* mozilla browsers */
  //         -khtml-user-select: none; /* webkit (konqueror) browsers */
  //         -ms-user-select: none; /* IE10+ */

  //         & > * {
  //           padding: 0 0.5em;
  //           pointer-events: auto;

  //           &:hover,
  //           :disabled {
  //             color: rgb(156, 156, 156);
  //             cursor: pointer;
  //           }
  //         }

  //         .zoom-percent {
  //           font-size: 0.77em;
  //           pointer-events: none;
  //         }
  //       }

  //       .venue-file-upload-container {
  //         position: absolute;
  //         display: flex;
  //         justify-content: center;
  //         margin: auto;
  //         width: 100%;
  //         cursor: pointer;

  //         .venue-img-upload {
  //           display: none;
  //         }
  //         // end hero-img-upload

  //         .venue-img-upload + label {
  //           display: flex;
  //           justify-content: center;
  //           color: #5b5b5b;
  //           align-items: center;
  //           font-size: 12px;
  //           width: 75%;
  //           height: 340px;
  //           cursor: inherit;
  //         }
  //         // end hero-img-upload + label

  //         .venue-img-icon {
  //           position: absolute;
  //           top: 43%;
  //           color: #5b5b5b;
  //           z-index: -1;
  //           cursor: inherit;
  //         }
  //         // end venue-img-icon
  //       }
  //       // end venue-file-upload-container
  //     }
  //     // end venue-file-upload-wrapper
  //   }
  //   // end venue-img
  // }
  //end venue-img-wrapper

  .small-img-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 125px;
    margin-bottom: 40px;
    // margin: 15px 0px;

    @media screen and (max-width: 695px) {
      height: 300px;
    }

    @media screen and (max-width: 400px) {
      height: 425px;
    }

    .small-img {
      width: 15%;
      height: 125px;
      border: rgba(91, 91, 91, 0.2) dashed 2px;
      border: #807b7b dashed 2px;
      transition: 500ms;

      @media screen and (max-width: 695px) {
        width: 30%;
      }

      @media screen and (max-width: 400px) {
        width: 47%;
      }

      &:hover {
        //border: rgba(91, 91, 91, .5) dashed 2px;
        border: #e3d2d2 dashed 2px;
      }

      .file-upload-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .remove-icon {
          display: none;
          cursor: pointer;
          position: absolute;
          bottom: -30px;
          //left: 58px;
        }

        .small-image {
          position: relative;
          display: none;
          width: 100%;
          height: 100%;
          object-fit: scale-down;
        }
        // end small-image

        .file-upload-container {
          position: absolute;
          display: flex;
          justify-content: center;
          margin: auto;
          width: 100%;
          height: 100%;
          cursor: pointer;

          .img-upload {
            display: none;
          }
          // end img-upload

          .img-upload + label {
            display: flex;
            justify-content: center;
            color: #5b5b5b;
            align-items: center;
            font-size: 12px;
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
          // end img-upload + label

          .img-icon {
            position: absolute;
            top: 30%;
            color: #5b5b5b;
          }
          // end img-icon
        }
        // end file-upload-container
      }
      @media screen and (max-width: 695px) {
        margin-bottom: 25px;
        .file-upload-wrapper {
          .remove-icon {
            bottom: -22px;
          }
        }
      }
      // end file-upload-wrapper
    }
    // end small-img
  }
  // end small-img-wrapper

  .submission-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    .button-wrapper {
      width: 100px;
      margin: auto;
    }
    // end button-wrapper
  }
  // end submission-wrapper
}
// end uploader wrapper
</style>

<script>
import Uploader from "vue-image-crop-upload/upload-2.vue";
import PopUpsForIcons from "../components/PopUpsForIcons.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faImages,
  faSearchPlus,
  faSearchMinus,
  faCheckCircle,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  inputPhoneNumberOnly,
  websiteCheck,
  youtubeCheck
} from "@/helpers/input-formats.js";
import { faWizardsOfTheCoast } from "@fortawesome/free-brands-svg-icons";

library.add(faImages);
library.add(faSearchPlus);
library.add(faSearchMinus);
library.add(faCheckCircle);
library.add(faTimes);

export default {
  name: "ImageUploader",
  components: {
    DiyoboInput,
    DiyoboButton,
    FontAwesomeIcon,
    PopUpsForIcons,
    Uploader
  },
  props: {
    requiredImageSize: Object,
    txt: String,
    type: String,
    label: String,
    info: Object,
    venueInfo: Object,
    bus: Object,
    noBtn: Boolean,
    oldEvent: Object,
    componentCount: Number,
    test: String,
    popUpContent: String,
    popUpLabel: String,
    error: Boolean
  },
  data() {
    return {
      zoom: "100%",
      images: {
        hero: this.info && this.info.images ? this.info.images.hero : null,
        heroFile: null,
        small: this.info && this.info.images ? this.info.images.small : [],
        smallFiles: []
      },
      testImg: "",
      imageDetails: {
        x: this.info && this.info.imageDetails ? this.info.imageDetails.x : "",
        y: this.info && this.info.imageDetails ? this.info.imageDetails.y : "",
        zoomLevel:
          this.info && this.info.imageDetails
            ? this.info.imageDetails.zoomLevel
            : "",
        croppedImg:
          this.info && this.info.imageDetails
            ? this.info.imageDetails.croppedImg
            : ""
      },
      uploadDataBool: false,
      setZoomLevel: false,
      imgTypes: ["png", "jpg", "jpeg"],
      showUploader: false,
      files: null
    };
  },
  computed: {
    requiredWidth() {
      return this.requiredImageSize ? this.requiredImageSize.width : 1100;
    },
    requiredHeight() {
      return this.requiredImageSize ? this.requiredImageSize.height : 618;
    },
    langExtDisplay() {
      return this.requiredImageSize
        ? {
            hint: `Click Here (min size: ${this.requiredImageSize.width} x ${this.requiredImageSize.height})`
          }
        : { hint: "Click Here (min size: 1100 x 618)" };
    },
    indexToUse() {
      if (!isNaN(this.componentCount)) {
        return this.componentCount;
      } else {
        return 0;
      }
    }
  },
  methods: {
    heroImg(
      e,
      fileContainer,
      fileInput,
      imgId,
      zoomPercent,
      canvasId,
      fileUploadWrapperId,
      zoomInId,
      zoomOutId,
      displayIcons
    ) {
      var heroImg = document.getElementById(fileInput);
      heroImg.click();
      let self = this;
      let canvas;
      document.getElementById(fileInput).onchange = function() {
        var reader = new FileReader();
        reader.onload = function(e) {
          var image = document.getElementById(imgId);
          let imgX = 0,
            imgY = 0;
          let oldMouseX, oldMouseY, dragging;
          let zoom = 1;
          image.src = e.target.result;
          let type;
          let valid = false;
          if (image.src.match("image.*")) {
            type = image.src.split("/")[1].split(";")[0];
          }

          for (let x = 0; x < self.imgTypes.length; x++) {
            if (self.imgTypes[x] == type) {
              valid = true;
            }
          }

          if (valid) {
            image.onload = function() {
              this.uploadDataBool = true;
              canvas = document.getElementById(canvasId);
              canvas.width = 1100;
              canvas.height = 395;
              let uploadWrapper = document.getElementById(fileUploadWrapperId);
              let ctx = canvas.getContext("2d");

              const imgRatio = image.naturalWidth / image.naturalHeight;
              let imgWidth = image.naturalWidth;
              let imgHeight = image.naturalHeight / imgRatio;
              // let imgWidth =
              //   image.naturalWidth >= canvas.width &&
              //   image.naturalHeight >= canvas.height
              //     ? image.naturalWidth
              //     : 1920;
              // let imgHeight =
              //   image.naturalHeight >= canvas.height && imgWidth != 1920
              //     ? image.naturalHeight
              //     : 1080;

              image.style.display = "none";
              canvas.style.display = "block";

              let zoomInButton = document.getElementById(zoomInId);
              zoomInButton.onclick = function(e) {
                if (zoom < 5) {
                  zoom += 0.1;
                  self.zoom = `${Math.round(zoom * 100)}%`;
                  defineBoundaries();
                  draw();
                }
              };

              let zoomOutButton = document.getElementById(zoomOutId);
              zoomOutButton.onclick = function(e) {
                if (zoom > 1) {
                  zoom -= 0.1;
                  self.zoom = `${Math.round(zoom * 100)}%`;
                  defineBoundaries();
                  draw();
                }
              };

              uploadWrapper.onmousedown = function(e) {
                dragging = true;
                oldMouseX = event.clientX;
                oldMouseY = event.clientY;
              };

              uploadWrapper.onmouseup = function(e) {
                dragging = false;
              };

              uploadWrapper.onmouseleave = function(e) {
                dragging = false;
              };

              let scaleFactor = canvas.width / imgWidth;

              let draw = function() {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(
                  image,
                  imgX,
                  imgY,
                  imgWidth * scaleFactor * zoom,
                  imgHeight * scaleFactor * zoom
                );
                self.saveUploaderData(imgX, imgY, zoom);
              };

              let minImgX,
                minImgY,
                maxImgY = 0;
              let defineBoundaries = function() {
                minImgX = (imgWidth * scaleFactor - canvas.width) * zoom;
                minImgX -= canvas.width * (zoom - 1);
                minImgY = (imgHeight * scaleFactor - canvas.height) * zoom;
                minImgY += canvas.height * (zoom - 1);
                minImgY = -minImgY;

                if (imgX > 0) imgX = 0;
                if (imgY > 0) imgY = 0;
                if (imgX < minImgX) imgX = minImgX;
                if (imgY < minImgY) imgY = minImgY;

                if (minImgY > 0) {
                  imgY = minImgY / 2;
                  maxImgY = minImgY;
                  minImgY = 0;
                }
              };

              uploadWrapper.onmousemove = function(e) {
                if (dragging && e.target.nodeName === "CANVAS") {
                  imgX -= ((oldMouseX - event.clientX) * (2.04 * zoom)) / zoom;
                  imgY -= ((oldMouseY - event.clientY) * (2.04 * zoom)) / zoom;
                  if (imgX > 0) imgX = 0;
                  if (imgY > maxImgY) imgY = maxImgY;
                  if (imgX < minImgX) imgX = minImgX;
                  if (imgY < minImgY) imgY = minImgY;
                  oldMouseX = event.clientX;
                  oldMouseY = event.clientY;

                  draw();
                }
              };

              window.onresize = function() {
                //draw();
              };
              //document.getElementById(self.hero_image_editId).style.display = 'flex'
              defineBoundaries();
              draw();
              self.images.hero = reader.result;
              // self.$emit("data", reader.result);

              self.testImg = reader.result;
            };

            document.getElementById(fileContainer).style.display = "none";
            document.getElementById(displayIcons).style.display = "flex";

            document.getElementById(fileUploadWrapperId).style.border =
              "#807b7b dashed 2px";
            let cont = document.getElementById(fileContainer);
            cont.children[0].style.color = "#5b5b5b";
            cont.children[2].style.color = "#5b5b5b";
            document.getElementById("error-msg").style.display = "none";
          } else {
            document.getElementById(fileUploadWrapperId).style.border =
              "#807b7b dashed 2px";
            let cont = document.getElementById(fileContainer);
            cont.children[0].style.color = "rgba(230, 0, 34, 0.5)";
            cont.children[2].style.color = "rgba(230, 0, 34, 0.5)";
            document.getElementById("error-msg").style.display = "flex";
          }
        };

        reader.readAsDataURL(this.files[0]);
      };
    },
    smallImg(e, fileContainer, fileInput, imgId, removeIcon) {
      if (e != null) {
        var heroImg = document.getElementById(fileInput);
        heroImg.click();
        let index = imgId.slice(-1) - 1;

        let self = this;
        document.getElementById(fileInput).onchange = function(inputEvent) {
          const fileSize = inputEvent.target.files[0].size;
          const fileMb = fileSize / 1024 ** 2;
          if (fileMb > 10) {
            alert("Please select an image file less than 10MB.");
            return;
          }
          var reader = new FileReader();
          reader.onload = function(e) {
            var image = document.getElementById(imgId);

            image.src = e.target.result;

            let type;
            let valid = false;
            if (image.src.match("image.*")) {
              type = image.src.split("/")[1].split(";")[0];
            }

            for (let x = 0; x < self.imgTypes.length; x++) {
              if (self.imgTypes[x] == type) {
                valid = true;
              }
            }

            if (valid) {
              image.onload = function() {
                this.style.display = "block";
                self.images.small[index] = reader.result;
                let ele = document.getElementById(removeIcon);
                console.dir(ele);
                if (ele) {
                  ele.style.display = "flex";
                }
              };

              document.getElementById(fileContainer).style.display = "none";

              let fileCont = document.getElementById(fileContainer);
              fileCont.children[0].style.color = "#5b5b5b";
              fileCont.children[2].style.color = "#5b5b5b";
              fileCont.parentElement.parentElement.style.border =
                "#807b7b dashed 2px";
              document.getElementById("add-error-msg").style.display = "none";
            } else {
              let fileCont = document.getElementById(fileContainer);
              fileCont.children[0].style.color = "rgba(230, 0, 34, 0.5)";
              fileCont.children[2].style.color = "rgba(230, 0, 34, 0.5)";
              fileCont.parentElement.parentElement.style.border =
                "#807b7b dashed 2px";
              document.getElementById("add-error-msg").style.display = "flex";
            }
          };

          self.images.smallFiles[index] = this.files[0];

          reader.readAsDataURL(this.files[0]);
        };
      }
    },
    removeImg(id, cont, removeIcon, imageCanvas, file) {
      this.uploadDataBool = false;
      document.getElementById(id).src = "";
      document.getElementById(id).style.display = "none";
      document.getElementById(cont).style.display = "flex";
      if (removeIcon) {
        let ele = (document.getElementById(removeIcon).style.display = "none");
        // console.dir(ele);
        // if(ele) {
        //   ele.classList.add("hidden");
        // }
      }
      if (imageCanvas) {
        document.getElementById(imageCanvas).style.display = "none";
        this.images.hero = "";
      }
      if (file) {
        document.getElementById(file).value = "";
      }

      let eleArr = id.split("-");
      let eleId;
      if (eleArr.length == 3) {
        eleId = eleArr[2];
      } else if (eleArr.length == 4) {
        eleId = eleArr[3];
      }
      let int = parseInt(eleId) - 1;

      this.images.small[int] = undefined;

      if (eleArr.length == 3) {
        // document.getElementById(`remove-icon-${eleId}`).style.display = "none";
        // document.getElementById(`file-container-${eleId}`).style.display =
        //   "flex";
      } else if (eleArr.length == 4) {
        document.getElementById(
          `secondary-remove-icon-${eleId}`
        ).style.display = "none";
        document.getElementById(
          `secondary-file-container-${eleId}`
        ).style.display = "flex";
      }
      // this.imageDetails.x = "";
      // this.imageDetails.y = "";
      // this.imageDetails.zoomLevel = "";
      // this.imageDetails.croppedImg = "";
      this.zoom = "100%";
    },

    saveUploaderData(x, y, zoomLevel) {
      this.imageDetails.x = x;
      this.imageDetails.y = y;
      this.imageDetails.zoomLevel = zoomLevel;
    },
    isImageThere(index) {
      if (this.images.small[index]) {
        return true;
      }
      return false;
    },
    injectImageData(imageObj) {
      this.images = imageObj;
      if (this.images.small) {
        let smallImgs = this.images.small;

        for (let i = 0; i < smallImgs.length; i++) {
          let img = smallImgs[i];
          if (img != undefined && img != null && img != "") {
            let imgEl = document.getElementById(`small-image-${i + 1}`);
            if (imgEl) {
              imgEl.src = img;
              imgEl.style.display = "block";
              document.getElementById(`file-container-${i + 1}`).style.display =
                "none";
              let icon = document.getElementById(`small-remove-icon-${i + 1}`);
              if (icon != undefined) icon.style.display = "flex";
            } else {
              imgEl = document.getElementById(`secondary-small-image-${i + 1}`);
              if (imgEl) {
                imgEl.src = img;
                imgEl.style.display = "block";
                document.getElementById(
                  `secondary-file-container-${i + 1}`
                ).style.display = "flex";
                document.getElementById(
                  `secondary-remove-icon-${i + 1}`
                ).style.display = "none";
              }
            }
          }
        }
      }
    },
    onHeroImageSelected(image) {
      fetch(image)
        .then(res => res.blob())
        .then(blob => {
          this.images.hero = image;
          this.images.heroFile = new File([blob], "hero.png", {
            type: "image/png"
          });
          this.$emit("data", this.images);
        });
    }
  },
  mounted() {
    let self = this;

    if (this.info.images && this.info.images.small) {
      this.images = this.info.images;
      this.imageDetails = this.info.imageDetails;
      if (this.info.images.small) {
        let smallImgs = this.images.small;

        for (let i = 0; i < smallImgs.length; i++) {
          let img = smallImgs[i];
          if (img != undefined && img != null && img != "") {
            let imgEl = document.getElementById(`small-image-${i + 1}`);
            if (imgEl) {
              imgEl.src = img;
              imgEl.style.display = "block";
              document.getElementById(`file-container-${i + 1}`).style.display =
                "none";
              let icon = document.getElementById(`small-remove-icon-${i + 1}`);
              if (icon != undefined) icon.style.display = "flex";
            }
          }
        }
      }
    }

    if (this.info.hero) {
      this.injectImageData(this.info);
    }
    //save the x and y coordinator of the cropped image and zoom, so we can save original image.
    if (this.bus) {
      this.bus.$on("image-upload-data", args => {
        let canvas;
        if (this.type == "primary" || this.type == "secondary") {
          canvas = document.querySelector(`#image-canvas-${self.indexToUse}`);
        } else {
          canvas = document.querySelector(
            `#venue-image-canvas-${self.indexToUse}`
          );
        }
        if (canvas) {
          if (this.images.hero)
            this.imageDetails.croppedImg = canvas.toDataURL();
        }
        if (this.type == "secondary") {
          this.$emit("data", {
            images: this.images,
            imageDetails: this.images.imageDetails
          });
        } else {
          let objToSend = {
            images: this.images,
            imageDetails: this.imageDetails
          };
          this.$emit("data", objToSend);
        }
      });
      this.bus.$on("logo-upload-data", args => {
        this.$emit("data", this.images);
      });
    }
  },
  watch: {
    info(val) {
      if (val) {
        if (val.imageDetails) {
          //this.imageDetails = val.imageDetails;
          self.zoom = "100%";
        }
        if (val.hero) {
          this.injectImageData(val);
        }
      }
    },
    venueInfo(val) {
      if (val.hero) {
        this.injectImageData(val);
      }
    }
  }
};
</script>
