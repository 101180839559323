<template>
  <div class="social-discount-wrapper">
    <div class="social-inner">
      <div class="form-row">
        <p>
          Social Discounts allow users to share your event for discounted ticket
          prices. Our automated systems confirm whether the link they provide to
          their post is live. For Twitter and LinkedIn, we validate the link to
          ensure the content is correct.
        </p>
        <p>
          However, in the case of Facebook, their systems only allow INCREDEVENT
          to verify that the link is live. This means we cannot verify the
          correct contents of the link. We recommend manually checking the
          Facebook share links before your event if you are concerned about
          incorrect use of this feature by your buyers.
        </p>
        <p>
          <b>Please Note:</b>
          Promo Codes and Social Discounts cannot be combined.
        </p>
        <!-- <h3 class="main-header">
          Control how widespread your event gets with social discounts!
        </h3> -->
        <!-- <span>Learn how this works</span> -->
        <diyobo-checkbox
          type="check"
          class="enable-discounts"
          :label="'Enable Social Discounts'"
          :required="true"
          v-model="allData.enable"
          :checked="allData.enable"
        />
        <div class="tier-options" v-if="allData.enable">
          <!-- <h3>Tier options:</h3> -->

          <div class="options">
            <div class="col">
              <diyobo-input
                type="dropdown"
                class="dropdown ticket-tier"
                label="Select which tiers you would like to apply social discounts:"
                placeholder="Select Ticket Tier"
                :options="_tiers"
                :required="true"
                v-model="allData.tier"
                :val="allData.tier"
              />
            </div>
          </div>
        </div>
        <div class="amount-options" v-if="allData.enable">
          <!-- <h3>Amount options:</h3> -->

          <div class="options row">
            <div class="col half">
              <div class="input-wrapper">
                <radio-group
                  :buttons="discountTypeButtons"
                  label="Select Discount Type"
                  :required="true"
                  v-model="allData.discountType"
                  :val="allData.discountType"
                  v-on:click="getDiscountType"
                />
              </div>
            </div>
          </div>
          <div class="options row">
            <div class="col half">
              <diyobo-input
                class="discount"
                type="money"
                label="Discount"
                :placeholder="discountPlaceholder"
                :required="true"
                v-model="allData.discount"
                :val="allData.discount"
                v-on:input="testFunc"
                :max="maxInt"
              />
            </div>
          </div>
        </div>
        <div class="customize-options" v-if="allData.enable">
          <!-- <h3>:</h3> -->
          <diyobo-input
            class="discount"
            type="textbox"
            label="Caption that will display with the link:"
            :placeholder="'Please add something here'"
            v-model="allData.socialShareText"
            :val="allData.socialShareText"
          />
          <!-- <wysiwyg
            :required="true"
            v-model="allData.socialShareText"
            :value="allData.socialShareText"
            :createEvent="true" -->
        </div>

        <div v-if="allData.tier && allData.enable" class="social-options">
          <h3>Additional Options</h3>
          <!-- <div class="col">
            <p>Do you want your social discounts stackable with promo code?</p>
            <diyobo-checkbox
              type="checkbox"
              :label="'Stackable discount?'"
              v-model="allData.stackable"
              :checked="allData.stackable"
            />
          </div> -->
          <div class="col stackable">
            <p>
              Do you want your social discounts stackable (more than one at a
              time)?
            </p>
            <diyobo-checkbox
              type="checkbox"
              :label="'Stackable discount?'"
              v-model="allData.stackable"
              :checked="allData.stackable"
            />
          </div>
          <!-- <div class="col">
            <p>Do you want your social discounts to override promo codes?</p>
            <diyobo-checkbox
              type="checkbox"
              :label="'Override promo codes?'"
              v-model="allData.overridePromo"
              :checked="allData.overridePromo"
            />
          </div> -->
          <!-- <h3>
           
          </h3> -->
          <!-- <p>
            Notice: Facebook authorizes us to confirm the link is live but not
            the contents. You should verify the link manually.
          </p> -->

          <div class="options row">
            <div class="col full">
              <p>
                Which social networks would you like to enable social sharing
                for?
              </p>
            </div>
            <div class="col full">
              <diyobo-checkbox
                type="checkbox"
                :label="'Facebook '"
                v-model="allData.fb"
                :checked="allData.fb"
              />

              <diyobo-checkbox
                type="checkbox"
                :label="'Twitter'"
                v-model="allData.tw"
                :checked="allData.tw"
              />
              <diyobo-checkbox
                type="checkbox"
                :label="'LinkedIn'"
                v-model="allData.li"
                :checked="allData.li"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <beta-notice />
  </div>
</template>

<script>
import DiyoboCheckbox from "@/components/DiyoboCheckbox";
import RadioGroup from "@/components/RadioGroup.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import wysiwyg from "../../components/WYSIWYG.vue";
import BetaNotice from "@/components/BetaNotice.vue";

export default {
  components: {
    DiyoboCheckbox,
    RadioGroup,
    DiyoboInput,
    wysiwyg,
    BetaNotice
  },
  props: {
    allData: Object,
    tiersAdded: Array
  },
  data() {
    return {
      on: false,
      fb: true,
      tw: true,
      li: true,
      discount: "",
      description: "",
      maxInt: 15,
      discountType: "",
      ticketTier: "",
      tiers: [
        {
          label: "All Ticket Tiers",
          value: "All Ticket Tiers"
        }
      ],
      discountTypeButtons: [
        {
          id: "money-discount",
          name: "discount-types",
          label: "$ Discount",
          checked: true
        },
        {
          id: "percent-discount",
          name: "discount-types",
          label: "% Discount"
        }
      ]
    };
  },
  mounted() {
    // to set the radio groups value when editing the event
    if (this.allData) {
      let index = this.discountTypeButtons.findIndex(
        e => e.label === this.allData.discountType
      );
      if (index) {
        this.discountTypeButtons.forEach(e => (e.checked = false));
        this.discountTypeButtons[index].checked = true;
      }
    }

    // get all the added added tiers || updated tiers when creating the event

    // if (this.tiersAdded.length > 0) {
    // }
  },
  computed: {
    discountPlaceholder() {
      if (this.allData.discountType == "% Discount") {
        return "Discount (%)";
      } else {
        return "Discount ($)";
      }
    },
    _tiers() {
      //add new tiers
      const tiers = [];
      tiers[0] = {
        label: "All Ticket Tiers",
        value: "All Ticket Tiers"
      };
      if (this.tiersAdded.length > 0) {
        this.tiersAdded.forEach(t => {
          tiers.push({
            label: t.name,
            value: t.name
          });
        });
      }

      return tiers;
    }
  },
  methods: {
    getDiscountType(discountName) {
      this.allData.discountType = discountName;
      if (discountName == "% Discount") {
        this.maxInt = 3;
      } else {
        this.maxInt = 15;
      }
    },
    testFunc(e) {
      if (parseInt(e) > 100 && this.allData.discountType == "% Discount") {
        this.allData.discount = "";
        this.allData.discount = 100;
      }
    }
  }
};
</script>

<style lang="less" scoped>
p {
  font-size: 16px;
  margin: 0;
  margin-bottom: 8px;
}
.enable-discounts,
.stackable {
  margin: 24px 0;
}

.main-header {
  margin-bottom: 5px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.col.half {
  width: calc(50% - 10px);
}
@media screen and (max-width: 749px) {
  .col.half {
    width: 100%;
  }
}

.social-inner {
  // overflow: hidden;
  position: relative;
  //padding: 4px 8px;
  //margin: 20px 0px;

  .form-row {
    // display: flex;
    // flex-wrap: wrap;
    //padding: 25px 16px 0px;

    // span {
    //   margin-bottom: 10px;
    // }
    .social-options {
      .options {
        display: flex;
        flex-wrap: wrap;
        width: 40%;
        justify-content: space-between;
      }
    }
    .amount-options {
      .options {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-around;
      }
    }
  }
}
</style>
