<template>
  <FormulateForm
    ref="form"
    v-model="formValues"
    @input="onInput"
    autocomplete="false"
  >
    <h2>Event Details</h2>
    <FormulateInput
      type="wysiwyg"
      name="description"
      label="Description"
      placeholder="Description"
      validation="required"
      autocomplete="false"
    />
    <div class="col full">
      <FormulateInput
        type="link"
        name="videoLink"
        label="Video Link"
        placeholder="Video Link"
        autocomplete="off"
        validation="youtube"
        :validation-rules="{
          youtube: ({ value }) => youtubeCheck(value)
        }"
        :validation-messages="{
          youtube: 'Invalid YouTube/Vimeo link provided.'
        }"
      />
    </div>
    <div class="col full">
      <FormulateInput
        type="link"
        name="website"
        label="Website Link"
        placeholder="Website Link"
        autocomplete="off"
        validation="website-check"
        :validation-rules="{
          'website-check': ({ value }) => websiteCheck(value)
        }"
        :validation-messages="{
          'website-check': 'Invalid website provided.'
        }"
      />
    </div>
    <div class="col full">
      <FormulateInput
        type="text"
        name="sponsor"
        label="Presenting Sponsor"
        placeholder="Presenting Sponsor"
        autocomplete="false"
      />
    </div>
    <div class="col full">
      <FormulateInput
        type="link"
        name="sponsorLink"
        label="Sponsor Link"
        placeholder="Sponsor Link"
        autocomplete="off"
        validation="website-check"
        :validation-rules="{
          'website-check': ({ value }) => websiteCheck(value)
        }"
        :validation-messages="{
          'website-check': 'Invalid website provided.'
        }"
      />
    </div>
  </FormulateForm>
</template>

<script>
import { websiteCheck, youtubeCheck } from "@/helpers/input-formats";
import debounce from "debounce";

export default {
  name: "Details",
  props: {
    info: Object,
    errors: Object
  },
  data() {
    return {
      formValues: {
        description: this.info.details.description,
        hasItineraries: this.info.details.hasItineraries,
        itineraries: this.info.details.itineraries,
        videoLink: youtubeCheck(this.info.details.videoLink)
          ? this.info.details.videoLink
          : "",
        website: websiteCheck(this.info.details.website)
          ? this.info.details.website
          : "",
        sponsor: this.info.details.sponsor,
        sponsorLink: websiteCheck(this.info.details.sponsorLink)
          ? this.info.details.sponsorLink
          : ""
      }
    };
  },
  methods: {
    websiteCheck,
    youtubeCheck,
    onInput: debounce(function() {
      this.$emit(
        "data",
        "details",
        this.formValues,
        !this.$refs.form || this.$refs.form.hasErrors ? undefined : false
      );
    }, 300)
  },
  mounted() {
    if (this.errors.details) {
      this.$refs.form.showErrors();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$emit("data", "details", this.formValues, this.$refs.form.hasErrors);
    next();
  }
};
</script>
