<template>
  <div class="ticket-tiers">
    <h1 class="content-heading">Ticket Tiers</h1>
    <div class="form-inner">
      <div class="checkbox-wrapper">
        <div class="input-wrapper">
          <diyobo-checkbox
            type="check"
            label="My event is guest list only. I will setup my guest list options after I've created my event, which can be found under 'Select Event Options' on my dashboard."
            v-model="guestlistOnly"
            :checked="guestlistOnly"
          />
        </div>
      </div>

      <template v-if="!guestlistOnly">
        <div class="tabs">
          <tab-tote
            :tabs="tabs"
            :value="currentTable"
            v-on:click="changeCurrentTable(arguments[0])"
          />
        </div>

        <keep-alive>
          <div class="ticket-tiers-tab" v-show="currentTable === 0">
            <div class="selection-wrapper">
              <div class="tier-type-selector selector-wrapper col half">
                <diyobo-input
                  v-if="false"
                  id="ticket-tier-type"
                  type="dropdown"
                  label="Please select your ticket tier type"
                  placeholder="Select Ticket Tier Type"
                  :required="true"
                  v-model="tierType"
                  :dropDownVal="tierType"
                  :options="tierOptions"
                  :error="tierTypeError"
                />
              </div>
            </div>
            <div
              class="tiers"
              v-if="
                tierType == 'General Tier' ||
                  tierType == 'Date' ||
                  tierType == 'Date and Time' ||
                  tierType == 'Time'
              "
            >
              <ticket-tiers
                :bus="bus"
                :type="tierType"
                :editTier="updateTier[0]"
                :id="updateTier[0].id"
                :tierNames="tierNames"
                v-on:addTier="addTier"
                :edit="edit"
                v-on:data="data('addedTiers', ...arguments)"
                v-on:errored="tierErrored"
                v-on:error-check="errorCheck"
                v-on:saveTierInfo="saveTierInfo"
                :eventStartDate="this.eventStartDate ? this.eventStartDate : ''"
                :eventEndDate="this.eventEndDate ? this.eventEndDate : ''"
                :excludedDates="excludedDates"
              />
            </div>

            <div class="added-tier-wrapper">
              <div class="added-tier-title" v-if="tierType">
                <h3>Sort Ticket Tiers</h3>
                <p class="b1">
                  If you would like to re-order your ticket tiers please drag
                  and drop the tiers to suit your needs. How easy is that? 👊
                </p>
                <p class="b1" v-if="addedTiers.length <= 0">
                  Go create some tiers!
                </p>
              </div>
              <added-ticket-tier
                :tier="true"
                :tiers="addedTiers"
                :bus="bus"
                :excludedDates="excludedDates"
                :key="updateWorkaroundPleaseFixLater"
                v-on:removeAddedTier="removeAddedTier"
                v-on:editTier="editTier"
                v-on:trackTicket="trackTicket"
              />
            </div>
          </div>
        </keep-alive>
        <keep-alive>
          <div class="social-discounts-tab" v-show="currentTable === 1">
            <social-discounts
              :allData.sync="socialDiscount"
              :tiersAdded="addedTiers"
            />
          </div>
        </keep-alive>
      </template>
    </div>
  </div>
</template>

<style lang="less" scoped>
.ticket-tiers {
  transition: 0.4s;
  position: relative;
  .creation-path {
    color: #999999;
  }

  .content-heading {
    text-align: center;
    text-transform: capitalize;
  }
  //end creation-path

  .form-inner {
    margin: auto;
    width: 100%;
    max-width: 1100px;

    .checkbox-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .input-wrapper {
        margin-left: 10px;
      }
      // end input-wrapper
    }

    .tier-error {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 50px;
      color: #e60022;

      p {
        margin: 0px;
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: auto;
      margin-top: 2.5em;
      max-width: 325px;

      .progress-counter {
        p {
          margin: 0;

          font-size: 1.1em !important;
          font-weight: 500;
          text-align: center;
          word-spacing: 1.5px;
          color: #999999;
        }
        //end child p
      }
      // end progress-counter
    }

    .added-tier-wrapper {
      //display: flex;
      width: 100%;
    }

    .selection-wrapper {
      width: 100%;
      // padding-bottom: 15px;
    }
    // end selection-wrapper
  }
  // end form-inner

  .tiers {
    // margin-top: 45px;
  }
  //end tiers
}
// end ticket-tiers
</style>

<script>
import Vue from "vue";
import TicketTiers from "../components/TicketTier.vue";
import AddedTicketTier from "./tickets/AddedTicketTiers.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import dateFormat from "dateformat";
import TabTote from "@/components/TabTote.vue";
import SocialDiscounts from "./create/SocialDiscount.vue";
import DiyoboCheckbox from "../../../shared/components/DiyoboCheckbox";

export default {
  components: {
    TicketTiers,
    AddedTicketTier,
    DiyoboInput,
    TabTote,
    SocialDiscounts,
    DiyoboCheckbox
  },
  props: {
    info: Object,
    methodBus: Object,
    eventStartDate: String,
    eventEndDate: String,
    currency: Object,
    excludedDates: Array
  },
  data() {
    return {
      bus: new Vue(),
      inputBus: new Vue(),
      // TODO: make "" when readding dropdown
      tierType: "General Tier",
      tabs: ["Ticket Tiers", "Social Discounts"],
      component: "TicketTiers",
      currentTable: 0,
      socialDiscount: {
        enable: false,
        tier: "",
        discountType: "$ Discount",
        discount: "",
        stackable: false,
        overridePromo: false,
        fb: true,
        tw: true,
        li: true,
        socialShareText: ""
      },
      guestlistOnly: this.info.guestlistOnly,
      tierTypeError: false,
      dateError: false,
      multipleDates: false,
      dateSelected: false,
      disableOptions: false,
      //object being created
      updateTier: [{ id: "initial", id: this.tierCount }],
      tierOptions: [{ id: 1, label: "General Tier", value: "General Tier" }],
      errors: [{ name: "tier type", display: "Tier Type", error: false }],
      addedTiers: [],
      testCount: 0,
      edit: false,
      tierBeingEdited: false,
      trackedTicket: {},
      tierCount: this.info.length,
      dateOptions: [],
      selectedEventDateId: "",
      selectedEventDateToDisplay: "",
      eventDateToDisplay: "Select Event Date",
      didTierError: true,
      currencyOptions: [
        { id: 1, label: "Canada", value: "Canada" },
        {
          id: 2,
          label: "United States",
          value: "United States"
        }
      ],
      currencyType: "",
      updateWorkaroundPleaseFixLater: 0
    };
  },
  watch: {
    socialDiscount() {
      console.log("chchchch");
      // if (!val.enable) {
      //   console.log("change");
      //   this.socialDiscount = {
      //     enable: false,
      //     tier: "",
      //     discountType: "$ Discount",
      //     discount: "",
      //     stackable: false,
      //     fb: true,
      //     tw: true,
      //     li: true,
      //     socialShareText: ""
      //   };
      // }
    },
    tierType: {
      // TODO: remove immediate when re-adding dropdown
      handler: function(val) {
        if (this.addedTiers.length == 0) {
          this.updateTier.shift();
          this.testCount++;
          this.updateTier.push({ id: "initial" });
        } else {
          if (this.tierBeingEdited) {
            this.testCount++;
          } else {
            this.updateTier.shift();
            this.testCount++;
            this.updateTier.push({ id: "addition" });
          }
          console.log(this.updateTier, "tier seller being used");
        }
        this.tierTypeError = false;
      },
      immediate: true
    },
    selectedEventDateToDisplay(val) {
      if (val.includes("event")) {
        let eventDate = this.eventDates.find(ele => ele.eventDateId == val);
        console.log(val, "this is the val");
        let selectedEventDateToDisplay = this.dateOptions.find(
          ele => ele.value == val
        );
        console.log(eventDate, "event date we grabbed");
        let startDate;
        let endDate;
        if (eventDate.start) {
        } else {
          startDate = eventDate.startDate;
          endDate = eventDate.endDate;
          console.log("Problem?");
          this.eventStartDate = eventDate.startDate;
          this.eventEndDate = eventDate.endDate;
          this.eventEndTime = eventDate.endTime;
          this.eventStartTime = eventDate.startTime;
          this.selectedEventDateId = selectedEventDateToDisplay.value;
          this.selectedEventDateToDisplay = selectedEventDateToDisplay.label;
          this.changeTier(eventDate);
        }
        this.tierOptions.splice(0, this.tierOptions.length);
        this.tierOptions.push({
          id: 1,
          label: "General Tier",
          value: "General Tier"
        });
        this.tierOptionsToUse(startDate, endDate);
        document.querySelector(".selection-wrapper").style.justifyContent =
          "space-between";
      }
    },
    previousEventDateId(val) {
      let eventDate = this.eventDates.find(ele => ele.eventDateId == val);
      let startDate;
      let endDate;
      if (eventDate.start.$date) {
      } else {
        startDate = ele.startDate;
        endDate = ele.endDate;
      }
      this.tierOptionsToUse(startDate, endDate);
    },
    addedTiers(addedTier) {
      console.log(addedTier, "this is the added tier");
      if (addedTier.length > 0) {
        let tierType = addedTier.find(ele => ele.type).type;
        this.tierType = tierType;
        this.disableOptions = true;
      } else {
        this.disableOptions = false;
      }
    }
  },
  computed: {
    tierNames() {
      let tierNames = [];
      this.addedTiers.forEach(tier => {
        let nameObj = {
          name: tier.name,
          id: tier.id
        };
        tierNames.push(nameObj);
      });
      return tierNames;
    }
  },

  methods: {
    changeCurrentTable(value) {
      // console.log(value);
      this.component = this.tabs[value];
      this.currentTable = value;
      // Vue.set(this.data, "currentTable", value);
    },
    tierErrored() {
      this.errors[0].error = true;
    },
    addTier(obj, updateObj) {
      if (updateObj == null) {
        // obj.currencyType = this.currencyType;
        if (this.currency.country == "Canada") {
          obj.currencyType = "CAD";
        } else if (this.currency.country == "United States") {
          obj.currencyType = "USD";
        }

        if (this.addedTiers.length != 0) {
          let id = this.addedTiers.length;
          obj.id = id;
        } else {
          obj.id = 0;
        }

        if (obj.addfee == null) {
          obj.addfee = 0;
        }

        this.addedTiers.push(obj);
      } else if (obj == null) {
        for (let x = 0; x < this.addedTiers.length; x++) {
          if (this.addedTiers[x].id == updateObj.id) {
            this.addedTiers[x] = updateObj;
            this.addedTiers.splice(x, 1);
            this.addedTiers.splice(x, 0, updateObj);
            this.edit = false;
            this.tierBeingEdited = false;
            // TODO:
            // i have no clue why the sale dates dont automatically
            // update on the ticket card things, so it's shitty workaround time
            this.updateWorkaroundPleaseFixLater++;
          }
        }

        if (updateObj.addfee == null) {
          updateObj.addfee = 0;
        }
        // this.bus.$emit("updateTier", updateObj);
      }
      this.updateTier.shift();
      this.updateTier.push({ id: "addition" });
      this.testCount++;
      this.tierTypeError = false;
    },
    removeAddedTier(index) {
      let tierToRemove = this.addedTiers[index];
      this.addedTiers.splice(index, 1);
      if (tierToRemove.id == this.updateTier.id) {
        this.edit = false;
        this.tierBeingEdited = false;
        this.updateTier.shift();
        this.updateTier.push({ id: "addition" });
      }
    },
    handleData() {
      if (this.tierType == undefined) {
        this.tierTypeError = true;
        return false;
      } else {
        this.tierTypeError = false;
      }
      this.errors.forEach(e => {
        e.errors = [];
        if (!e.optional) {
          e.error = !this[e.name];
          if (e.error) e.errors.push(`${e.display} is required`);
        }
      });

      this.bus.$emit("ticket-tier-data");

      if (this.errors[0].error) {
        this.errors[0].errors.push("Invalid ticket tiers");
      }

      this.$emit(
        "data",
        "tiersInfo",
        {
          // ticketTiers: this.ticketTiers,
          addedTiers: this.addedTiers,
          name: this.name,
          price: this.price,
          ccfee: this.ccfee,
          dfee: this.dfee,
          afee: this.afee,
          tierDates: this.tierDates
        },
        this.errors.some(e => e.error)
      );
    },
    editTier(date) {
      if (window.scrollY > 2000) {
        window.scrollTo(0, 4200);
      } else window.scrollTo(0, 546);
      this.edit = true;
      this.tierType = date.type;
      this.updateTier.shift();
      this.updateTier.push(date);
      this.tierBeingEdited = true;
      this.testCount++;
    },
    data(key1, key2, value) {
      if (value) {
        //intent
        this[key1][key2] = value;
      } else if (key2) {
        //tier is sending key2 the value
        this[key1].push(key2);
      }
    },
    trackTicket(obj) {
      this.trackedTicket = obj;
    },
    tierOptionsToUse(startDate, endDate) {
      // this.tierOptions = [];
      // this.tierOptions.push({id: 0, label: 'General Tier', value: 'General Tier'})
      let splitedStartingDate = startDate.split("-");
      let splitedEndDate = endDate.split("-");
      if (
        false && // TODO other tier types
        splitedStartingDate[0] == splitedEndDate[0] &&
        splitedStartingDate[1] == splitedEndDate[1] &&
        splitedStartingDate[2] == splitedEndDate[2]
      ) {
        this.tierOptions.push({ id: 2, label: "Time", value: "Time" });
        this.tierOptions.push({
          id: 3,
          label: "Date and Time",
          value: "Date and Time"
        });
      } else if (false) {
        this.tierOptions.push({ id: 2, label: "Date", value: "Date" });
        this.tierOptions.push({
          id: 3,
          label: "Date and Time",
          value: "Date and Time"
        });
      }
    },
    errorCheck(val) {
      this.didTierError = val;
      this.$emit("save-error", val);
    },
    saveTierInfo(obj) {
      this.$emit("data", obj);
    },
    typeCheck() {
      if (this.addedTiers.length == 0) {
        this.tierTypeError = true;
      }
    },
    validateEventDateTiers() {
      let doesEveryElementHaveAtLeastOneTier = [];
      this.eventDates.forEach(eventDate => {
        if (
          this.addedTiers.find(
            tier => eventDate.eventDateId == tier.eventDateId
          )
        )
          doesEveryElementHaveAtLeastOneTier.push(true);
        else doesEveryElementHaveAtLeastOneTier.push(false);
      });
      let isThereATierForEveryDate = doesEveryElementHaveAtLeastOneTier.every(
        ele => ele
      );
      console.log(
        "I finished validating the event dates answer ----->",
        isThereATierForEveryDate
      );
      return isThereATierForEveryDate;
    },
    changeTier(eventDate) {
      console.log(
        eventDate,
        JSON.stringify(this.updateTier.eventDateId),
        "this is the problem"
      );
      if (this.updateTier[0].eventDateId != eventDate.eventDateId) {
        let tier = this.addedTiers.find(
          ele => ele.eventDateId == eventDate.eventDateId
        );
        this.updateTier.shift();
        if (tier != null || tier != undefined || tier)
          this.updateTier.push({ id: "addition" });
        else this.updateTier.push({ id: "initial" });
      }
      this.testCount++;
    }
  },
  mounted() {
    let self = this;
    for (let key in this.info) {
      if (key == "addedTiers")
        this.info[key].forEach(ele => this.addedTiers.push(ele));
    }

    if (this.addedTiers.length > 0) {
      this.updateTier.shift();
      this.updateTier.push({ id: "addition" });
    }

    if (this.info.socialDiscount) {
      this.socialDiscount = this.info.socialDiscount;
    }
    let startDateToUse = this.eventStartDate.split(":");
    let endDateToUse = this.eventEndDate.split(":");
    this.tierOptionsToUse(startDateToUse[0], endDateToUse[0]);
    let inputElement = document.querySelector("#ticket-tier-type");
    if (inputElement) {
      inputElement.addEventListener("click", e => {
        this.tierBeingEdited == false;
      });
    }
    if (this.methodBus) {
      this.methodBus.$on("save-tier-info", args => {
        let objToSave = {
          addedTiers: self.addedTiers,
          updateTier: self.updateTier,
          stateSaved: true,
          socialDiscount: self.socialDiscount,
          guestlistOnly: this.guestlistOnly
        };
        if (
          this.addedTiers.some(
            t => !t.saleDates[0].startDate && !t.saleDates[0].sale_start
          )
        ) {
          this.didTierError = false;
        }
        this.$emit("data", objToSave, !this.didTierError);
      });
      this.methodBus.$on("validate-tier-info", args => {
        let objToSave;
        if (this.addedTiers.length === 0 && !this.guestlistOnly)
          this.didTierError = false;

        this.bus.$emit("validate-tier-info", this.addedTiers.length);
        if (
          this.addedTiers.some(
            t => !t.saleDates[0].startDate && !t.saleDates[0].sale_start
          )
        ) {
          this.didTierError = false;
          this.$bus.$emit("alert", {
            msg: "Please update your ticket tiers before proceeding."
          });
        }
        this.typeCheck();
        objToSave = {
          addedTiers: self.addedTiers,
          updateTier: self.updateTier,
          stateSaved: true,
          socialDiscount: self.socialDiscount,
          guestlistOnly: this.guestlistOnly
        };
        this.$emit("data", objToSave, !this.didTierError);
        if (this.didTierError) {
          this.$emit("next");
        }
      });
    }
  }
};
</script>
