<template>
  <div class="trail" id="trail">
    <div
      v-for="(step, index) in steps"
      class="step"
      :key="index"
      :class="customClassName(step)"
      @click="onClick(step)"
    >
      <div :class="customClassName2(step)">
        <font-awesome-icon v-if="step.state === 'done'" icon="check" />
        <div v-else class="number-inner">
          {{ step.number }}
        </div>
      </div>
      <div :class="customClassName3(step)">{{ step.name }}</div>
    </div>
  </div>
  <!-- :class="[step.link === 'tos' && !canSubmit ? 'disabled' : step.state]" -->
</template>

<script>
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faCheck);

export default {
  name: "trail",
  components: {
    FontAwesomeIcon
  },
  props: {
    steps: Array,
    canSubmit: Boolean,
    isVirtual: Boolean,
    isGuestList: Boolean
  },
  data() {
    return {};
  },

  methods: {
    customeClassName(step) {},
    customClassName(step) {
      let stringBuilder = "";
      if (step.link === "tos" && !this.canSubmit) {
        stringBuilder = "disabled";
      } else {
        stringBuilder = step.state;
      }

      if (
        (step.name === "Venue" && this.isVirtual) ||
        (step.name === "Tickets" && this.isGuestList)
      ) {
        stringBuilder = stringBuilder + " disabled-grey";
      }

      return stringBuilder;
    },

    customClassName2(step) {
      if (
        (step.name === "Tickets" && this.isGuestList) ||
        (step.name === "Venue" && this.isVirtual)
      ) {
        return "number2";
      } else {
        return "number";
      }
    },
    customClassName3(step) {
      if (
        (step.name === "Tickets" && this.isGuestList) ||
        (step.name === "Venue" && this.isVirtual)
      ) {
        return "name2";
      } else {
        return "name";
      }
    },
    onClick(step) {
      if (
        (step && step.name === "Tickets" && this.isGuestList) ||
        (step.name === "Venue" && this.isVirtual)
      ) {
        return;
      } else {
        if (step && step.state !== "disabled") {
          this.$emit("change", step.link);
        }
      }
    }
  },

  mounted() {
    // window.onscroll = function () {
    //   myFunction();
    // };
    // var navbar = document.getElementById("trail");
    // var sticky = navbar.offsetTop;
    // function myFunction() {
    //   if (window.pageYOffset >= 140) {
    //     navbar.classList.add("sticky");
    //   } else {
    //     navbar.classList.remove("sticky");
    //   }
    // }
  }
};
</script>

<style lang="less" scoped>
.trail {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  // &.sticky {
  //   position: fixed;
  //   top: 85px;
  //   width: 100%;
  // }

  .step {
    display: flex;
    margin-top: 10px;
    cursor: pointer;

    .number {
      display: flex;
      width: 40px;
      height: 40px;
      padding: 2px;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-size: 20px;
      font-weight: bold;
      transition: 0.5s;
      color: #fff;
      background: var(--primary-green);

      .number-inner {
        display: flex;
        width: 40px;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(0deg, #282a30, #33363d);
        border-radius: 100%;
      }

      .fa-check {
        width: 40px;
      }
    }
    .number2 {
      display: flex;
      width: 40px;
      height: 40px;
      padding: 2px;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-size: 20px;
      font-weight: bold;
      transition: 0.5s;
      color: #fff;
      background: gray;

      .number-inner {
        display: flex;
        width: 40px;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(0deg, #282a30, #33363d);
        border-radius: 100%;
      }

      .fa-check {
        width: 40px;
      }
    }

    .number2 {
      display: flex;
      width: 40px;
      height: 40px;
      padding: 2px;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-size: 20px;
      font-weight: bold;
      transition: 0.5s;
      color: #fff;
      background: gray;
      .number-inner {
        display: flex;
        width: 40px;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(0deg, #282a30, #33363d);
        border-radius: 100%;
      }
      .fa-check {
        width: 40px;
      }
    }

    .name {
      display: flex;
      align-items: center;
      padding-left: 15px;
      font-weight: bold;
    }

    &.done {
      .number {
        .number-inner {
          background: transparent;
        }
      }

      .name {
        color: @colors[primary-green];
      }
    }

    &.error {
      .number {
        background: @colors[error-red];
      }

      .name {
        color: @colors[error-red];
      }
    }
    .name2 {
      display: flex;
      align-items: center;
      padding-left: 15px;
      font-weight: bold;
    }

    &.done {
      .number {
        .number-inner {
          background: transparent;
        }
      }
      .name2 {
        color: grey;
      }
    }

    &.error {
      .number {
        background: @colors[error-red];
      }

      .name2 {
        color: grey;
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}

@media screen and (max-width: 1015px) {
  .trail {
    flex-direction: row !important;
    width: 100%;
    justify-content: space-evenly;
    column-gap: 10px;
    .path {
      display: none;
    }
    .step {
      flex-wrap: wrap-reverse;
      justify-content: center;
      .number {
        width: 5vw;
        height: 5vw;
        padding: 2px;
        font-size: 3vw;
      }
      .name {
        padding: 0 0px 0 5px;
        font-size: 14px;
        // align-items: flex-start;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .trail {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    margin-bottom: 1rem;
    .step {
      display: flex;
      justify-content: center;
      align-items: space-between;
      .number {
        width: 7vw;
        height: 7vw;
        font-size: 4vw;
      }

      .name {
        font-size: 12px;
        margin: 0.5rem 0;
        text-align: center;
      }
    }
  }
}
</style>
