<template>
  <FormulateForm
    ref="form"
    v-model="formValues"
    @input="onInput"
    autocomplete="false"
  >
    <h2>Venue Location &amp; Information</h2>
    <FormulateInput
      type="checkbox"
      name="secretVenue"
      label="Check this box if your venue is a secret or you are not ready to release the venue information to the public"
      label-class="not-required"
    />

    <FormulateInput
      type="checkbox"
      name="useBrandAddress"
      label="Check this box if your venue address is same as your brand address."
      v-model="useBrandAddress"
      label-class="not-required"
    />
    <div class="row">
      <div class="col half">
        <FormulateInput
          type="text"
          name="venueName"
          label="Venue Name"
          placeholder="Venue Name"
          :validation="!formValues.secretVenue && 'required'"
          :disabled="formValues.secretVenue"
          autocomplete="false"
        />
      </div>
      <!-- <div class="col half">
        <FormulateInput
          type="image"
          name="image"
          label="Venue Logo Upload"
          help="Select a png, jpg or gif to upload."
          validation="mime:image/jpeg,image/png,image/gif"
          clearable
          :disabled="formValues.secretVenue"
        />
      </div> -->
      <div class="col half">
        <FormulateInput
          type="googleAutocomplete"
          name="venueAddress"
          label="Venue Address"
          placeholder="Venue Address"
          :country="countryCode"
          :validation="!formValues.secretVenue && 'required'"
          :disabled="formValues.secretVenue"
          @updated="onPlaceUpdated"
          autocomplete="false"
        />
      </div>
      <div class="col half">
        <FormulateInput
          v-if="formValues.secretVenue"
          type="googleAutocomplete"
          name="cityName"
          label="City"
          placeholder="City"
          mapType="(cities)"
          validation="required"
          :country="countryCode"
          @updated="onSecretPlaceUpdated"
          autocomplete="false"
        />
        <FormulateInput
          v-else
          type="text"
          name="cityName"
          label="City"
          placeholder="City"
          validation="required"
          autocomplete="false"
        />
      </div>
      <div class="col half">
        <FormulateInput
          type="text"
          name="closestCityName"
          label="Closest Major City"
          placeholder="Closest Major City"
          autocomplete="false"
        />
      </div>
      <div class="col half">
        <FormulateInput
          type="text"
          name="regionName"
          validation="required"
          :label="locType"
          :placeholder="locType"
          autocomplete="false"
        />
      </div>
      <!-- <div class="col half">
        <FormulateInput
          type="text"
          name="regionCode"
          validation="required"
          :label="locTypeCode"
          :placeholder="locTypeCode"
          autocomplete="false"
        />
      </div> -->
      <div class="col half">
        <FormulateInput
          type="text"
          name="zipCode"
          :label="postalZipLabel"
          :placeholder="postalZipLabel"
          :validation="[
            !formValues.secretVenue && 'required',
            `max:${postalLength},length`
          ]"
          :disabled="formValues.secretVenue"
          autocomplete="false"
        />
      </div>
      <div class="col half">
        <FormulateInput
          type="tel"
          name="contactNumber"
          label="Contact Number"
          placeholder="Contact Number"
          :disabled="formValues.secretVenue"
          autocomplete="false"
        />
      </div>
    </div>
  </FormulateForm>
</template>

<script>
import StateAbbreviations from "@/helpers/state-abbreviations";
import { fetchCoordinates } from "@/helpers/location";
import { postalFormat } from "@/helpers/input-formats";
import debounce from "debounce";
import { countries } from 'countries-list';

export default {
  name: "Venue",
  props: {
    info: Object,
    errors: Object
  },
  data() {
    return {
      useBrandAddress: false,
      formValues: {
        secretVenue: this.info.venue.secretVenue || false,
        image: this.info.venue.image,
        venueName: this.info.venue.venueName,
        venueAddress: this.info.venue.venueAddress,
        cityName: this.info.venue.cityName,
        regionName: this.info.venue.regionName,
        regionCode: this.info.venue.regionCode,
        zipCode: this.info.venue.zipCode,
        contactNumber: this.info.venue.contactNumber,
        closestCityName: this.info.venue.closestCityName,
        coordinates: {
          latitude: this.info.venue.coordinates.latitude,
          longitude: this.info.venue.coordinates.longitude
        }
      }
    };
  },
  computed: {
    countryCode() {
      const countryEntries = Object.entries(countries);

      for (const [code, info] of countryEntries) {
        if (info.name === this.info.config.country) {
          return [code.toLowerCase()];
        }
      }
      return this.info.config.country === "Canada" ? ["ca"] : ["us"];
    },
    postalLength() {
      return this.info.config.country === "Canada" ? 7 : 5;
    },
    locType() {
      return this.info.config.country === "Canada" ? "Province" : "State";
    },
    locTypeCode() {
      return this.info.config.country === "Canada"
        ? "Province code"
        : "State code";
    },
    postalZipLabel() {
      return this.info.config.country === "Canada" ? "Postal Code" : "ZIP Code";
    }
  },
  watch: {
    useBrandAddress(val) {
      const companyInfo = this.info.brand;
      this.formValues.venueAddress =
        val && companyInfo.companyAddress ? companyInfo.companyAddress : "";
      this.formValues.cityName =
        val && companyInfo.city ? companyInfo.city : "";
      this.formValues.regionName =
        val && companyInfo.regionName ? companyInfo.regionName : "";
      this.formValues.zipCode =
        val && companyInfo.zipCode ? companyInfo.zipCode : "";
      this.formValues.contactNumber =
        val && companyInfo.companyPhone ? companyInfo.companyPhone : "";
      this.formValues.regionCode = false ? "Dasd" : "";
      if (this.useBrandAddress && this.formValues.secretVenue) {
        this.formValues.secretVenue = false;
      }
    },
    formValues: {
      handler(val) {
        if (val.secretVenue && this.useBrandAddress) {
          this.useBrandAddress = false;
          this.formValues.venueAddress = "";
          this.formValues.cityName = "";
          this.formValues.regionName = "";
          this.formValues.zipCode = "";
          this.formValues.contactNumber = "";
          this.formValues.regionCode = "";
        }
      },
      deep: true
    }
  },
  methods: {
    onPlaceUpdated(val) {
      let addressToParse = "yo";
      let countryOfChoice;
      let postalCode = val["postal_code"];
      let regionAbbreviation;
      if (val["administrative_area_level_1"]) {
        regionAbbreviation = val["administrative_area_level_1"];
      } else {
        regionAbbreviation = addressToParse[2];
      }

      for (const state of StateAbbreviations.stateAbbreviations) {
        if (regionAbbreviation === state.short) {
          this.formValues.regionCode = state.short;
          this.formValues.regionName = state.name;
          countryOfChoice = state.country;
          break;
        }
      }

      if (postalCode) {
        this.formValues.zipCode = postalFormat(postalCode, countryOfChoice);
      }

      let city = val["locality"];

      if (city) {
        this.formValues.cityName = city;
      } else {
        this.formValues.cityName = addressToParse[1];
      }

      this.formValues.coordinates = {
        latitude: val.latitude,
        longitude: val.longitude
      };
    },
    onSecretPlaceUpdated(val) {
      this.formValues.cityName = val.locality;
      this.formValues.regionCode = val.administrative_area_level_1;
      this.formValues.regionName = StateAbbreviations.getFullName(
        val.administrative_area_level_1
      );
    },
    onInput: debounce(async function() {
      if (!this.formValues.secretVenue) {
        await this.createCoordinates();
      }
      this.$emit(
        "data",
        "venue",
        this.formValues,
        !this.$refs.form || this.$refs.form.hasErrors ? undefined : false
      );
    }, 300),
    async createCoordinates() {
      const venue = this.formValues;
      if (!venue.secretVenue) {
        const venueAddress =
          venue.venueAddress +
          " " +
          venue.cityName +
          ", " +
          venue.regionName +
          " " +
          this.info.config.country;

        const coordinates = await fetchCoordinates(venueAddress);

        this.formValues.coordinates = {
          latitude: parseFloat(coordinates.lat),
          longitude: parseFloat(coordinates.lng)
        };
      }
    }
  },

  mounted() {
    if (this.errors.venue) {
      this.$refs.form.showErrors();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$emit("data", "venue", this.formValues, this.$refs.form.hasErrors);
    next();
  }
};
</script>
