<template>
  <FormulateForm ref="form" v-model="formValues" autocomplete="false">
    <h2>Planner Ticket Policy</h2>
    <FormulateInput
      type="checkbox"
      name="enable_upgrade_ticket"
      label="Enable ticket tier upgrades"
      autocomplete="false"
    />
    <FormulateInput
      type="checkbox"
      name="enable_exchange_ticket"
      label="Enable ticket exchanges"
      autocomplete="false"
    />
    <FormulateInput
      type="checkbox"
      name="enable_transfer_ticket"
      label="Enable ticket transfers"
      autocomplete="false"
    />
    <FormulateInput
      type="checkbox"
      name="enable_refund_ticket"
      label="Enable ticket refund request"
      autocomplete="false"
    />

    <FormulateInput
      type="checkbox"
      name="enable_refund_ticket_insurance"
      label="Enable ticket refund insurance"
      autocomplete="false"
    />

    <p>
      Please state your ticket policy in regards to refunds, exchanges and
      upgrades.
    </p>
    <FormulateInput type="wysiwyg" name="refundPolicy" autocomplete="false" />
    <div class="divider"></div>
    <h2>Planner Privacy Policy</h2>
    <p>If you have a Privacy Policy please state here.</p>
    <FormulateInput type="wysiwyg" name="privacyPolicy" autocomplete="false" />
    <div class="divider"></div>
    <h2>Planner Terms of Use</h2>
    <p>If you have a Terms of Use please state here.</p>
    <FormulateInput type="wysiwyg" name="termsOfUse" autocomplete="false" />
  </FormulateForm>
</template>

<script>
import debounce from "debounce";
const defaultRefundPolicy = `<p>Once you have received your ticket, please ensure that you purchased the correct tickets. If you ended up selecting the wrong options, contact Worre Studios for an exchange.</p><p><br></p><p>We do not offer refunds on any tickets purchased.</p>`;
const defaultTermsOfUse = `<p>Your ticket to our event is meant to be used by the purchaser and attendees (intended users). Any resold tickets outside of the event parameters is forbidden. Any resold ticket can be denied. However, if you're transferring a ticket to someone that you know and meet the criteria of the event; this is acceptable.</p><p><br></p><p>You may show the ticket on your mobile phone (brightness up to 100%) or print the ticket. Any duplicate tickets put you at risk of not entering the event as the scan can only apply once to each event. While you may scan-in and out of the event, only one of each scan can take place at any given time.</p><p><br></p><p>There are no exceptions to this term of service.</p><p><br></p><p>As a planner, you authorize refunds on your account there will be a small surcharge. This is a charge that we receive on our account from credit card processors. To figure out what that charge would calculate as, take the total amount of refunds you wish to issue and multiple by 0.02. For example, if you want to issue $100,000 in refunds, there would be a surcharge of $2,000.</p><p><br></p><p>Important to note: Worre Studios does not profit from the refund surcharge. This is a direct charge from credit card processors.</p>`;
const defaultPrivacyPolicy = `<p>We are committed to providing our clients, customers, members ("you", "your" or "them") with exceptional service. Providing exceptional service may involve the collection, use and, at times, the disclosure of your Personal Information. Protecting your Personal Information is one of our highest priorities. While we have always respected your privacy and safeguarded all Personal Information, we have strengthened our commitment to this goal. This is to continue to provide exceptional service to you and to comply with all laws regarding the collection, use and disclosure of Personal Information. We will inform you of why and how we collect, use and disclose Personal Information; obtain your consent, as required; and handle Personal Information according to applicable law. Our privacy commitment includes ensuring the accuracy, confidentiality, and security of your Personal Information and allowing you to request access to, and correction of, your personal information.</p>`;

export default {
  name: "PlannerTerms",
  props: {
    info: Object,
    errors: Object
  },
  data() {
    return {
      formValues: {
        refundPolicy: this.info.terms.refundPolicy || defaultRefundPolicy,
        privacyPolicy: this.info.terms.privacyPolicy || defaultPrivacyPolicy,
        termsOfUse: this.info.terms.termsOfUse || defaultTermsOfUse,
        enable_upgrade_ticket: this.info.terms.enable_upgrade_ticket,
        enable_exchange_ticket: this.info.terms.enable_exchange_ticket,
        enable_refund_ticket: this.info.terms.enable_refund_ticket,
        enable_transfer_ticket: this.info.terms.enable_transfer_ticket,
        enable_refund_ticket_insurance: this.info.terms
          .enable_refund_ticket_insurance
      }
    };
  },
  methods: {
    onInput: debounce(function() {
      this.$emit(
        "data",
        "terms",
        this.formValues,
        !this.$refs.form || this.$refs.form.hasErrors ? undefined : false
      );
    }, 300)
  },
  mounted() {
    if (this.errors.terms) {
      this.$refs.form.showErrors();
    }
    // we emit this once so that the last step can be enabled
    this.$emit("data", "terms", this.formValues, this.$refs.form.hasErrors);
  },
  beforeRouteLeave(to, from, next) {
    this.$emit("data", "terms", this.formValues, this.$refs.form.hasErrors);
    next();
  }
};
</script>

<style lang="less" scoped>
p {
  margin-bottom: 16px;
}
</style>
