<template>
  <FormulateForm ref="form" v-model="formValues" @input="onInput">
    <h2>Event Images</h2>
    <div class="row">
      <div class="col full">
        <image-uploader
          type="primary"
          :bus="bus"
          v-on:data="data('images', ...arguments)"
          v-on:remove-image="removeImageDetails"
          :info="imageUploaderObj"
          :noBtn="true"
          :requiredImageSize="requiredImageSize"
        />
      </div>
    </div>
  </FormulateForm>
</template>

<script>
import ImageUploader from "../../components/ImageUploader.vue";
import Vue from "vue";
import debounce from "debounce";

export default {
  name: "Images",
  components: {
    ImageUploader
  },
  props: {
    info: Object,
    errors: Object
  },
  data() {
    return {
      bus: new Vue(),
      images: this.info.images.images,
      imageDetails: this.info.images.imageDetails,
      formValues: {},
      requiredImageSize: {
        width: 1280,
        height: 720
      }
    };
  },
  methods: {
    data(key, value) {
      this.images = value.images;
      this.imageDetails = value.imageDetails;
    },
    handleData() {
      this.bus.$emit("image-upload-data");

      for (let x = 0; x < this.images.small.length; x++) {
        if (this.images.small[x] == null) {
          this.images.small[x] = "";
        }
      }
      this.$emit("data", "images", this.imageUploaderObj, false);

      return true;
    },
    removeImageDetails() {
      this.imageDetails = {
        x: "",
        y: "",
        zoomLevel: "",
        croppedImg: ""
      };
    },
    onInput: debounce(function() {
      this.$emit(
        "data",
        "images",
        this.imageUploaderObj,
        !this.$refs.form || this.$refs.form.hasErrors ? undefined : false
      );
    }, 300)
  },
  computed: {
    imageUploaderObj() {
      let objToSend = {
        images: this.images,
        imageDetails: this.imageDetails
      };
      return objToSend;
    }
  },
  mounted() {
    if (this.errors.images) {
      this.$refs.form.showErrors();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.handleData();
    next();
  }
};
</script>
