<template>
  <div class="beta-notice-container">
    <div class="beta-notice-inline" v-if="inline">
      <div class="notice" @click="onClick">
        <h2>BETA</h2>
        <div>This feature is in testing</div>
        <div class="b2">Click here to report bugs</div>
      </div>
      <report-bug-modal ref="reportBugModal" />
    </div>
    <div class="beta-notice" v-else>
      <div class="notice" @click="onClick">
        <h2>BETA</h2>
        <div class="bottom-text">
          <div>This feature is in testing</div>
          <div class="b2">Click here to report bugs</div>
        </div>
        <div class="bottom-text-mobile">
          <div>This feature is in testing. Click here to report bugs</div>
        </div>
      </div>
    </div>
    <report-bug-modal ref="reportBugModal" />
  </div>
</template>

<script>
import ReportBugModal from "./modals/ReportBugModal.vue";

export default {
  name: "beta-notice",
  components: {
    ReportBugModal,
  },
  props: {
    inline: Boolean,
  },
  methods: {
    onClick() {
      this.$refs.reportBugModal.open();
    },
  },
};
</script>

<style lang="less" scoped>
.beta-notice,
.beta-notice-inline {
  bottom: 40px;
  right: 40px;
  z-index: 50;
  background-color: #e60022;
  background-image: linear-gradient(#e60022, #b40621);
  border-radius: 10px;
  box-shadow: 0 1px 3px 1px #2222229d;
  padding: 10px;
  color: white;

  .b2 {
    color: white;
  }
}

.beta-notice-inline {
  cursor: pointer;
  width: fit-content;
  margin: auto;
  z-index: 1;
  h2 {
    font-size: 20px;
    color: white;
    margin: 0;
  }
}
.beta-notice {
  position: fixed;

  .notice {
    text-align: center;
    cursor: pointer;
    font-weight: bold;

    h2 {
      color: white;
      font-size: 40px;
      margin: 0;
    }

    .bottom-text-mobile {
      display: none;
    }
  }

  @media screen and (max-width: 730px) {
    bottom: 90px;
    right: 0;
    left: 0;
    width: 83%;
    margin: auto;
    padding: 5px 10px;
    backdrop-filter: blur(4px);
    background-color: initial;
    background: none;
    border: 1px solid red;
    .notice {
      display: flex;
      align-items: center;
      h2 {
        font-size: 20px;
        color: #ff5555;
      }
      .bottom-text {
        display: none;
      }
      .bottom-text-mobile {
        color: var(--text);
        display: block;
        font-size: 10px;
        margin-left: 10px;
      }
    }
  }
}
</style>
