<template>
  <div class="product-item-container">
    <span class="index-number">{{ index + 1 }}</span>
    <div class="merch-item">
      <img :src="item.image.image" width="200" height="200" alt="" />
      <h3 v-if="item.title.length <= 15" class="title">{{ item.title }}</h3>
      <h5 v-else-if="item.title.length > 15" class="title">{{ item.title }}</h5>
      <span class="variants">{{ item.variants.length }} variant(s)</span>
      <div class="buttons">
        <button class="edit-button" type="button" @click="$emit('edit', item)">
          Edit
        </button>
        <button
          class="delete-button"
          type="button"
          @click="$emit('delete', item)"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  props: {
    item: Object,
    index: Number
  }
};
</script>

<style lang="less" scoped>
.product-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.index-number {
  background-color: var(--primary-green);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  margin: 1rem;
}
.merch-item {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  padding: 15px;
  margin: 10px;
  border: 1px solid var(--dashboard-border);
  border-radius: 8px;
  cursor: grab;

  img {
    object-fit: cover;
    width: 100%;
    height: auto;
    padding-bottom: 10px;
  }

  .title {
    margin: 10px 0;
    font-size: 15px;
  }

  .variants {
    margin-bottom: 10px;
  }

  .buttons {
    display: flex;
    margin: 0 -10px;

    .edit-button,
    .delete-button {
      display: flex;
      justify-content: center;
      background-color: var(--secondary-green);
      color: white;
      width: 50%;
      font-size: 15px;
      border-radius: 5px;
      border: none;
      height: 30px;
      padding: 5px;
      margin: 0 10px;
      cursor: pointer;
      z-index: 10;
    }
  }
}
</style>
